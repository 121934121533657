<template>
   <Multiselect
      v-model="example5.value"
      v-bind="example5"
    ></Multiselect>
     <!-- <input 
         type="checkbox" :id="options.value" :value="name.value" 
         v-model="checkedNames">
  <label :for="name.value">{{ name.text }}</label> -->
</template>

<script>
// import Multiselect from "@vueform/multiselect";

export default {
  props: ['options'],
components: {
    // Multiselect,
  },
  data() {
    return {
   
        options: [
      {
        label: 'Digital Online',
        value: 'Digital Online'
      },
      {
        label: 'Digital Offline',
        value: 'Digital Offline'
      },
      {
        label: 'Print',
        value: 'Print'
      }
    ],
    checkedNames: [],
    showcheckboxes: false,
    close: false
    }
  },
  methods: {
    clickme() {
      this.showcheckboxes=!this.showcheckboxes;
    },
     autoclose() {
      this.showcheckboxes=false;
    },
  }
}
</script>

<style src="../../assets/css/default.css">
.multiselect {
  align-items: center;
  background: var(--ms-bg, #fff);
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: var(--ms-font-size, 1rem);
  justify-content: flex-end;
  margin: 0 auto;
  /* min-height: calc(
    var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) *
      var(--ms-line-height, 1.375) + var(--ms-py, 0.5rem) * 2
  ); */
  outline: none;
  position: relative;
  width: 100%;
}
</style>