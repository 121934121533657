export default {
  data() {
    return {
      str: [],
      changesInDropDownSelection: []
    }
  },
  methods: {
    MultipledropdownValuesMutation(data) {
      // console.log(data) ;
    // let count = data.join();
    // if(data.length > 5) {
    //  this.changesInDropDownSelection = data.slice(1,6).join(', ') + " ..."
    // } else {
    //   this.changesInDropDownSelection = data.join(', ');
    // }
    this.changesInDropDownSelection =
       data.length > 3
         ? data.slice(1, 6).join(', ')  + " ..."
         : data.join(', ');
        // console.log(this.changesInDropDownSelection)
  },
}
}