<template>
  <NavBar> </NavBar>
<div class="inner-section clearfix">
            <section>
                <div>
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="signupTitle mb-3 clearfix">Checkout Summary </div>
                        </div>
                        <div class="col-12 ">
                          <div class="checkoutTitle">Contact Information</div>
                            <div class="contact-box clearfix">
                                <form @submit.prevent="submitContactInfo" method="post" 
                                id="signup" action="" role="form">
                                    <div class="row clearfix">
                                        <div 
                                        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                        >
                                            <div class="mb-3">
                                                <input type="text" name="firstName" id="firstName" 
                                                class="form-control" placeholder="First Name*" 
                                                 v-model="state.firstName"
                                                /> 
                                                <span v-if="v$.firstName.$error"> 
                                                     {{ v$.firstName.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="lastName" id="lastName" 
                                                class="form-control" placeholder="Last Name*" 
                                                 v-model="state.lastName"
                                                />
                                                 <span v-if="v$.lastName.$error"> 
                                                    {{ v$.lastName.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                 <select  id="organization" 
                                                    name="organization" class="form-select bg-f8f9f9 border-0 mb-3"
                                                    v-model="state.companyType">
                                                        <option :value="userData.company_type" 
                                                        selected>{{userData.company_type}}</option>
                                                         <option value="organization">Organization</option>
                                                    <option value="privatebussiness">Private Business</option>
                                                    <option value="magazine">Magazine</option>
                                                    <option value="newspaper">Newspaper</option>
                                                    <option value="tv">TV</option>
                                                    <option value="radio">Radio</option>
                                                    <option value="books">Books</option>
                                                    <option value="exhibitions">Exhibitions</option>
                                                    <option value="film">Film</option>
                                                    <option value="multimedia">Multimedia</option>
                                                    <option value="educational">Educational</option>
                                                    <option value="research">Research</option>
                                                    <option value="freelance/consultant">Freelance/Consultant</option>
                                                    <option value="others">Others</option>
                                                    </select>
                                                    <span v-if="v$.companyType.$error"> 
                                                     {{ v$.companyType.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="companyName" id="companyName" 
                                                class="form-control" placeholder="Company Name*" 
                                                v-model="state.companyName"
                                                />
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="email" id="email" 
                                                class="form-control" placeholder="Email*" 
                                                  v-model="state.email"
                                                />
                                                 <span v-if="v$.email.$error">
                                                     {{ v$.email.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="phone" id="phone" 
                                                class="form-control" placeholder="Business Phone*" 
                                                  v-model="state.phone"
                                                />
                                                <span v-if="v$.phone.$error"> 
                                                     {{ v$.phone.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                 <select id="country" name="country"
                                                         class="form-select bg-f8f9f9 border-0" 
                                                          @click="callCountries()"
                                                        v-model="state.country" >
                                                            <option v-if="countryflag"
                                                              v-for="item in countryList"
                                                              :key="item.id"
                                                              >
                                                              {{item.country}}
                                                              </option>
                                                            <option v-else
                                                            :value = "state.country" 
                                                            :selected="state.country" 
                                                            >{{state.country}}</option>
                                                        </select>
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="city" id="city" 
                                                class="form-control" placeholder="City*" 
                                                 v-model="state.city"
                                                />
                                                 <span v-if="v$.city.$error"> 
                                                     {{ v$.city.$errors[0].$message}}</span>
                                            </div>
                                              <div class="mb-3">
                                                <input type="text" name="street_address" id="street_address" 
                                                class="form-control" placeholder="street_address*"
                                                 v-model="state.street_address"

                                                />
                                                 <span v-if="v$.state.$error"> 
                                                     {{ v$.street_address.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="State" id="State" 
                                                class="form-control" placeholder="State*"
                                                 v-model="state.state"

                                                />
                                                 <span v-if="v$.state.$error"> 
                                                     {{ v$.state.$errors[0].$message}}</span>
                                            </div>
                                             <div class="mb-3">
                                                <input type="text" name="zipCode" id="zipCode" 
                                                class="form-control" placeholder="Zip Code*"
                                                 v-model="state.zip_code"
                                                />
                                                 <span v-if="v$.zip_code.$error"> 
                                                     {{ v$.zip_code.$errors[0].$message}}</span>
                                            </div>
                                            <div class="col-12 justify-content-center">
                                            <center><input type="submit" name="submit" id="submit" 
                                            value="Continue" /></center>
                                             <span v-if="successmsg"> 
                                                     Its updated </span>
                                            </div>
                                        
                                        </div>
                                         <div 
                                        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                        v-if="updatedrecord">
<table  class="table text-center">
  <caption>Cart Items: {{this.$store.state.quantity}}</caption>
  <thead>
    <tr  >
      <th scope="col">Title</th>
      <th scope="col">Image</th>
      <th scope="col">Price</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in cartdata" :key="item.id" class="align-middle">
      <td style="font-size: 14px; text-transform:capitalize">{{item.title}}</td>
      <td><img  :src="url+item.thumbnails" class="img-fluid mx-auto" style="height: 100px" alt="smallimage"/></td>
      <td>{{cartAmountdata[index].amount}}</td>
    </tr>
  </tbody>
</table>
 <form @submit.prevent="callpayment" method="post" 
                                              id="callpayment" action="" role="form"
                                              >
                                              <div class="col-12 justify-content-center">
                                            <center><input type="submit" name="submit" id="submit" 
                                            value="Pay"  >
                                            
                                            </center>
                                              </div>
                                              <div v-show="paymentDoneFlag" class="mt-3"  >
                                                <div  class="paypal-window" ref="paypal"></div>
                                                </div>
                                               </form>
</div>








                                        
<!-- <div v-if="updatedrecord" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
Cart Items: {{this.$store.state.quantity}}<div class="mb-3" v-for="(item, index) in cartdata" :key="item.id">
<div class="row border border-1  p-2 d-flex justify-content-between ">
        <div class="col-sm-4 values-in-middle p-2 m-2 text-align-center">Title</div>
    <div class="col-sm-4 values-in-middle p-2 m-2 text-align-center">Image</div>
    <div class="col-sm-4 values-in-middle p-2 m-2 text-align-center">Price</div>

    <div class="w-100"></div>

    <div class="col-sm-4 p-p-2 m-2 "><h6>{{item.title}}</h6></div>
    <div class="col-sm-3 img-small p-2 m-2">
			<img  :src="url+item.thumbnails" alt="smallimage"/>
		</div>
		<div class="col-sm-3 p-2 m-2 "><h6>{{cartAmountdata[index].amount}}</h6></div>
                                              </div>
                                               </div>
                                             <form @submit.prevent="callpayment" method="post" 
                                              id="callpayment" action="" role="form"
                                              >
                                              <div class="col-12 justify-content-center">
                                            <center><input type="submit" name="submit" id="submit" 
                                            value="Pay"  >
                                            
                                            </center>
                                              </div>
                                              <div v-show="paymentDoneFlag"  >
                                                <div  class="paypal-window" ref="paypal"></div>
                                                </div>
                                               </form>
                                        </div> -->
                                       
                                    </div>
                                  </form>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
</div>               
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'
let countries = require('../data/countries.json');
import useValidate from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import axios from 'axios'

export default {
   setup() {
        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phone:'',
            companyType:'',
            companyName:'',
            country:'',
            city: '',
            zip_code: '',
            state: '',
            street_address: '',
            error: [],
            errormsg: '',
             

        })
        const rules = computed(() => {
            return {
                firstName: { 
                    required: helpers.withMessage(
                        'First Name must not be empty', required
                        ),
                    },
                lastName: { required: helpers.withMessage(
                        'Last Name must not be empty', required
                        ), },
                email: { required: helpers.withMessage(
                        'Email must not be empty', required
                        ), email},
                phone: { required: helpers.withMessage(
                        'Phone must not be empty', required
                        ), numeric},
                companyType: { required: helpers.withMessage(
                        'Company Type must not be empty', required
                        ), },
                companyName: { required: helpers.withMessage(
                        'Company Name must not be empty', required
                        ), },
                state: { required: helpers.withMessage(
                        ' state must not be empty', required
                        ), },
                street_address: { required: helpers.withMessage(
                        'Street Address must not be empty', required
                        ), },
                country: { required: helpers.withMessage(
                        'Country must not be empty', required
                        ), },
                city: { required: helpers.withMessage(
                        'city must not be empty', required
                        ), },
                 zip_code: { required: helpers.withMessage(
                        'zip code must not be empty', required
                        ), },
            };
        })
        const v$ = useValidate(rules, state)
        return {
            state,
            v$,
        }
    },
components: {
    NavBar,
  }, 
 data() {
  return {
    updatedrecord: false,
    userData: [],
      countryList: countries,
      countryflag: false,
      userid: 0,
      userInfo: {},
      successmsg: false,
      grandTotal: 0,
            orderdata: [],
            order: [],
            completeorders: [],
            url: 'http://flydigitally.in/',
            merchant_id: '',
            create_time: '',
            payment_method: '',
            orderId:'',
            paymentDoneFlag: false,
             cartdata: [],
				 cartAmountdata: [],

  }
 },
 computed: {
   cartitems() {
    this.cartdata = this.$store.state.cartitems.data;
			this.cartAmountdata = this.$store.state.cartitems.amount;
    console.log(this.$store.state.cartitems.data);
      return this.$store.state.cartitems;
 },
  calcgrandtotal() {
    return  this.cartitems.amount.forEach((amt) => {
        if(parseInt(amt.amount) !== null) {
          //console.log(amt.amount);
        this.grandTotal += amt.amount;
        } 
    })
 },
  createOrders() {
        //console.log(this.cartitems.data)
        for(let i=0; i < this.cartitems.data.length; i++) {
            this.completeorders.push({
            order_id:  this.orderId,
            img_attr_id:   this.cartitems.data[i].img_attribtes_id ,
            // merchant_id:   this.merchant_id ,
            user_id:   this.cartitems.data[i].user_id ,
            category_id1:   this.cartitems.data[i].category_id1 ,
            category_id2:   this.cartitems.data[i].category_id2 ,
            category_id3:   this.cartitems.data[i].category_id3 ,
            web_cat_id:   this.cartitems.data[i].web_cat_id ,
            web_subcat_id:   this.cartitems.data[i].web_subcat_id ,
            photographers_id:   this.cartitems.data[i].photographers_id ,
            thumbnails:   this.cartitems.data[i].thumbnails ,
            amount:   this.grandTotal ,
            payment:   this.payment_method,
            print:   this.cartitems.data[i].print ,
            image_size:   this.cartitems.data[i].image_size ,
            electronic_rights:   this.cartitems.data[i].electronic_rights ,
            method_distrbution:   this.cartitems.data[i].method_distrbution ,
            duration:   this.cartitems.data[i].duration ,
            territory_distribution:   this.cartitems.data[i].territory_distribution ,
            original_image: this.cartitems.data[i].original_image,
            created_at:   this.create_time ,
            })
            //console.log(this.completeorders);    
      }
                  this.postData();
  },
 },
 methods:{
  callpayment() {
    this.paymentDoneFlag = true;
              this.paymentcalled = true;
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AcnmMZx7XUUOhRgT93a8t2tbsrkoP7jyKGrUZL0vnyOuZR2VEQrqaGgcJcI-bl3n0DsyEtLJPz6sM_QK&vault=true";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
    },
  
     async postData() {
      for(let i =0; i<this.completeorders.length; i++) {
        await axios.post('http://flydigitally.in/api/order/add', this.completeorders[i]).
       then(function(response) {
        this.axiosResponse= response.data;
        this.paymentDoneFlag = false;
        //console.log(response.data);
       }.bind(this)).
       catch(function(error){
        this.axiosError = error;
        //console.log(error)
       }.bind(this));
      }
             this.$router.push({name: "PaymentCompletion", params: { orderId: this.orderId}});

        },
setLoaded() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: this.grandTotal
                  }
                }
              ]
            });
          },
          style: {
            shape: "rect",
            color: "silver",
            layout: "vertical",
            label: "buynow",
            size: "medium"
          },

          onApprove: async (data, actions) => {
            this.order = await actions.order.capture();
            this.data;
            this.orderdata.push(this.order);
            // console.log(this.orderdata);
            // this.paidFor = true;
            //console.log(this.order);
            // if(this.order.status === 'COMPLETED') {
            //     this.$router.push({ name: "PaymentCompletion" });
            // }
            //console.log(this.order.purchase_units[0]);
            this.orderId = this.order.id;
            this.merchant_id = this.order.purchase_units[0].payee.merchant_id
            this.create_time = this.order.create_time
            this.payment_method = this.order.purchase_units[0].soft_descriptor
            //console.log(this.payment_method)
            this.paymentcalled = false;
            this.loaded = false;
            this.createOrders;
          },
          onError: err => {
            console.log(err);
          }
        })
        .render(this.$refs.paypal);
    },
   callCountries() {
    this.userData.country = this.countryList.country;
    this.countryflag = true;
  },
  async submitContactInfo() {
    this.v$.$validate();
    if(!this.v$.$error) {
        this.postContactData();
    } 
},
async postContactData() {
      this.v$.$validate();
this.updatedrecord = true;
    let config= {
        headers: {
            'Authorization': 'Bearer ' + this.token
        }
    }
    let result = await axios.post('http://flydigitally.in/api/user/update/'+this.userid,  {
         first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            company_type: this.state.companyType,
            company_name: this.state.companyName,
            country: this.state.country,
            city: this.state.city,
            street_address: this.state.street_address,
            state: this.state.state,
            zip_code: this.state.zip_code,
    }, config)
    if(result.data.success === true){
        this.userInfo = result.data;
        this.successmsg = true;
        // console.log(this.userInfo);
        localStorage.setItem('user-info', JSON.stringify(result.data))
                this.userInfo.id = this.userInfo.data.id;
    this.state.firstName = this.userInfo.data.first_name;
        this.state.lastName = this.userInfo.data.last_name;
    this.state.email = this.userInfo.data.email;
    this.state.phone = this.userInfo.data.phone;
    this.state.companyType = this.userInfo.data.company_type;
        this.state.companyName = this.userInfo.data.company_name;
    this.state.country = this.userInfo.data.country;
 this.state.city = this.userInfo.data.city;
    this.state.zip_code = this.userInfo.data.zip_code; 
    this.state.state = this.userInfo.data.state;
    this.state.street_address = this.userInfo.data.street_address;
}
 },
 },
 mounted() {
    this.$store.dispatch("getCartItems");
    this.userData = JSON.parse(localStorage.getItem("user-info"));
    // console.log(this.userData);
    this.userid = this.userData.data.id;
    this.token = this.userData.token;
    this.state.firstName = this.userData.data.first_name;
    this.state.lastName = this.userData.data.last_name;
    this.state.email = this.userData.data.email;
    this.state.phone = this.userData.data.phone;
    this.state.companyType = this.userData.data.company_type;
    this.state.companyName = this.userData.data.company_name;
    this.state.country = this.userData.data.country;
    this.state.city = this.userData.data.city;
    this.state.zip_code = this.userData.data.zip_code; 
    this.state.state = this.userData.data.state;
    this.state.street_address = this.userData.data.street_address;
    this.cartitems;
    this.calcgrandtotal;
    

 }
}
</script>

<style scoped>

*{
  color: #83878c;
}
.checkoutTitle {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 20px;
  color: #83878C;
  display: block;
  margin: 10px 0 15px 0;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  position: relative;
}

span {
    color: red;
    font-family: inherit;
    font-size: 12px;
}

button {
  cursor: pointer;
  padding: 0;
  line-height: inherit;
  color: inherit;
}

.btn {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}

.img-small {
  width: 100px;
}

.image-small {
  width: 100px;
}


.align-items-center {
	align-items: center;
}

.values-in-middle{
	justify-content: center;
	width: 17% !important;
  text-align: center;
}

.table>:not(:first-child){
  border-top: 0;
}

table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    border-bottom-width: 1px;
    border-color: #83878c;
}
</style>