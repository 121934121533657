<template>
<NavBar />

  <section class="bg-e5e7e9 stories-section clearfix">
                <div class="container-fluid">
                    <div class="row  clearfix">
                        <div class="col-12">
                            <div class="gallery-title">Gallery</div>
                        </div>
                    </div>
                    <div class="row mt--40 gx-3 gy-3 clearfix">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery-menu clearfix">
                                <!-- <a href="#"><img src="images/-menu/img-1.jpg" alt="Image goes here" /></a> -->
                    <a href="#"><img src="images/BNB-MAD-PH014.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Wedding</a>
                                        <div class="cat"><a href="#">Events</a></div>
                                    </div>
                                   <div class="sponsor mt-2 clearfix">By Eva Bayle<div class="dt">21/09/21</div></div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery-menu clearfix">
                                <a href="#"><img src="images/gallery/img-2.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Old Lion King</a>
                                        <div class="cat"><a href="#">Wild Animal</a></div>
                                    </div>
                                     <div class="sponsor mt-2 clearfix">By Mike Brandt<div class="dt">21/09/21</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery-menu clearfix">
                                <a href="#"><img src="images/gallery/img-3.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Kings way</a>
                                        <div class="cat"><a href="#">Events</a></div>
                                    </div>
                                     <div class="sponsor mt-2 clearfix">By Prince David<div class="dt">21/09/21</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery-menu clearfix">
                                <a href="#"><img src="images/gallery/img-1.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Wedding</a>
                                        <div class="cat"><a href="#">Events</a></div>
                                    </div>
                                    <div class="sponsor mt-2 clearfix">By Eva Bayle<div class="dt">21/09/21</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery-menu clearfix">
                                <a href="#"><img src="images/gallery/img-2.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Old Lion King</a>
                                        <div class="cat"><a href="#">Wild Animal</a></div>
                                    </div>
                                   <div class="sponsor mt-2 clearfix">By Mike Brandt<div class="dt">21/09/21</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery-menu clearfix">
                                <a href="#"><img src="images/gallery/img-3.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Kings way</a>
                                        <div class="cat"><a href="#">Travel</a></div>
                                    </div>
                                      <div class="sponsor mt-2 clearfix">By Prince David<div class="dt">21/09/21</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
components: {
    NavBar
  },
}
</script>

<style scoped>


.gallery-title{
  margin-bottom: -30px;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.gallery-title:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(10% + 5px);
}

.latest-readmore a{
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c;  
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.latest-readmore a:after{
  content: '';
  position: absolute;
  bottom: -8px;
  left: 13%;
  width: 70%;
  height: 1px;
  background-color: #303030;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.latest-readmore:hover a:after {
  left: 0;
  width: 100%;
}

.gallery-menu {
  width: 100%;
  display: block;
  margin: -7px 0 10px 0;
  padding: 0;
}

.gallery-menu img {
  width: 96%;
  height: 190px;
  display: block;
  object-fit: cover;
}
.gallery-menu span {
  display: block;
  margin: 0 18px 0 0;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(2, 3, 3, 0.09);
}
.gallery-menu .imgtitle a {
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #333;
  text-align: left;
  display: inline-flex;
  line-height: 24px;
  text-transform: uppercase;
}
.imgtitle >a {
  border-bottom: 1px solid #303030;

}

.gallery-menu .imgtitle .cat {
  float: right;
}
.gallery-menu .imgtitle .cat a {
  font-size: 13px;
  color: #303030;
  text-align: left;
  line-height: 18px;
}


.imgtitle .dt {
  float: right;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.gallery-menu .sponsor {
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 13px;
  color: #303030;
  text-align: left;
  display: block;
  line-height: 24px;
}
.gallery-menu .sponsor .dt {
  float: right;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
</style>