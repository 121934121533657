<template>
  <div class="videoslider" v-for="img in newvalue" :key="img.id">
            <!-- <div class="overlay"></div> -->
  <img  class="img-animation" :src="img.image" alt="sliders" />
            <!-- <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop"
            v-for="item in video" :key="item.id"> -->
                <!-- <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4"> -->
                <!-- <source :src="videourl+item.video" type="video/mp4"> -->
            <!-- </video> -->
            <img src="images/home-logo.png" class="img-logo" alt="Image goes here" />            
          </div>


        <div class="sticky sticky--desktop" style="top: 0;"></div>
        <div class="inner-section clearfix">
            <section class="bg-e5e7e9 bnb-section clearfix">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="secTitle secTitle-About">Why BNB Was Created</div>
                            <p class="text-light abt-p-min-height">
                                BNB Arts supports the growth of photography in Afghanistan; its model offers Afghan photographers financial opportunities and exposure on the
                                international stage. With the creation of BNB Arts, the world will now be able to access images  of Afghanistan captured<br> 
                                 through Afghan eyes, from a local and more balanced gender perspective. <br>
                            </p>
                            <center >
                                    <router-link to="/about" class="readmore  mt-3">Read More</router-link>
                                    </center>
                        </div>
                    </div>
                </div>
            </section>

            <section class="services-section">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="secTitle services-offer">Services We Offer</div>
                        </div>
                    </div>
                    <div class="row mt-3  clearfix ">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <figure class="imgHover">
                                <img src="images/img-1.jpg" alt="sample72" />
                                <span class="home-page-span"> Photography</span>
                                <figcaption>
                                    <!-- <h3>Fletch Skinner</h3> -->
                                    <p>
                                        We offer quality and unique photography services bringing to you a local perspective.<br />
                                        <center class="mt-3">
                                        <a href="#" class=" latest-readmore ">Read More</a>
                                        </center>
                                    </p>
                                </figcaption>                            
                            </figure>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <figure class="imgHover">
                                <img src="images/img-2.jpg" alt="sample72" />
                                <span class="home-page-span"> Printing</span>
                                <figcaption>
                                    <!-- <h3>Fletch Skinner</h3> -->
                                    <p>
                                        Get a print or a visual souvenir from Afghanistan and a look at our unique Printing Lab.<br />
                                        <center class="mt-3">
                                        <a href="#" class="latest-readmore">Read More</a>
                                        </center>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <figure class="imgHover">
                                <img src="images/img-3.jpg" alt="sample72" />
                                <span class="home-page-span"> Gallery</span>
                                <figcaption>
                                    <!-- <h3>Fletch Skinner</h3> -->
                                    <p>
                                        Explore our Gallery that aims to “Keep Afghanistan in the Picture” – through Afghan eyes.<br />
                                        <center class="mt-3">
                                        <a href="#" class="latest-readmore ">Read More</a>
                                        </center>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-e5e7e9 stories-section clearfix">
                <div class="container-fluid">
                    <div class="row  clearfix">
                        <div class="col-12">
                            <div class="secTitle latest-stories">Latest Stories</div>
                        </div>
                    </div>
                    <div class="row mt--40 gx-3 gy-3 clearfix">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery clearfix">
                                <!-- <a href="#"><img src="images/gallery/img-1.jpg" alt="Image goes here" /></a> -->
                    <a href="#"><img src="images/BNB-MAD-PH014.jpg" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Wedding</a>
                                        <div class="cat"><a href="#">Events</a></div>
                                    </div>
                                   <div class="sponsor mt-2 clearfix">By Eva Bayle<div class="dt date-format">2/22/2021</div></div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery clearfix">
                                <a href="#"><img src="images/latestStories/BNB-MAD-PH020.JPG" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Old Lion King</a>
                                        <div class="cat"><a href="#">Wild Animal</a></div>
                                    </div>
                                     <div class="sponsor mt-2 clearfix">By Mike Brandt<div class="dt date-format">2/22/2021</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery clearfix">
                                <a href="#"><img src="images/latestStories/BNB-MAD-PH034.JPG" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Kings way</a>
                                        <div class="cat"><a href="#">Events</a></div>
                                    </div>
                                     <div class="sponsor mt-2 clearfix">By Prince David<div class="dt date-format">2/22/2021</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery clearfix">
                                <a href="#"><img src="images/latestStories/BNB-MAD-PH041.JPG" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Wedding</a>
                                        <div class="cat"><a href="#">Events</a></div>
                                    </div>
                                    <div class="sponsor mt-2 clearfix">By Eva Bayle<div class="dt date-format">2/22/2021</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery clearfix">
                                <a href="#"><img src="images/latestStories/BNB-MAD-PH049.JPG" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Old Lion King</a>
                                        <div class="cat"><a href="#">Wild Animal</a></div>
                                    </div>
                                   <div class="sponsor mt-2 clearfix">By Mike Brandt<div class="dt date-format">2/22/2021</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="gallery clearfix">
                                <a href="#"><img src="images/latestStories/BNB-MAD-PH072.JPG" alt="Image goes here" /></a>
                                <span>
                                    <div class="imgtitle clearfix">
                                        <a href="#">Kings way</a>
                                        <div class="cat"><a href="#">Travel</a></div>
                                    </div>
                                      <div class="sponsor mt-2 clearfix">By Prince David<div class="dt date-format">2/22/2021</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-12">
                            <center class=" mt-2">
                                <router-link to="/media" class="readmore mb--4" @click="$scrollToTop">
                                View More</router-link></center>
                        </div>
                    </div>
                </div>
            </section>

            <section class="media-section ">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div class="home-mediabox">
                                <div class="mediatitle">Media</div>
                                <p class="media-p-tag para">
                                    Aligned with its mission, BNB Arts works on different ranges of projects to keep Afghanistan in the Picture through Afghan eyes by bridging the visual gap on Afghanistan. 
                                    Check here to get information about our latest projects and activities.
                                </p>
                                <center  ><router-link to="/media" class="latest-readmore">Read More</router-link></center>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <img src="images/media.jpg" class="img-full media-image" alt="Image goes here" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-e5e7e9 clearfix">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-12">
                            <ul class="links clearfix">
                               <li><router-link to="/about" >About Us</router-link></li>
                                <li><router-link to="/photography" >Photography</router-link></li>
                                <li><router-link to="/printing" >Printing</router-link></li>
                                <li><router-link to="/gallery2" >Gallery</router-link></li>
                                <li><router-link to="/media" >Media</router-link></li>
                                <li><router-link to="/contactus" >Contact Us</router-link></li>
                                 <li> 
                          <Login @close="toggleModal" :modalActive="modalActive">
                          </Login>
                          <a  @click="toggleModal"  >Login</a>
                        </li> 
                            </ul>
                        </div>
                    </div>



                </div>
            </section>
        </div>
</template>

<script>
import Login from './Signup/Login.vue'
import { ref} from 'vue'


export default {
  name: 'HomeView',
   components: {
    Login,
  },
  setup() {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    return {modalActive,toggleModal};
  },
  data() {
      return {
        //   videourl: 'https://bnbarts.com/upload/manage_video/',
          allimages: [
      {id: 1, title: 'diwali', image: '/images/banner/img1.jpg'},
      {id: 2, title: 'glass', image: '/images/banner/img2.jpg'},
      {id: 3, title: 'spring', image: '/images/banner/img3.jpg'},
      {id: 4, title: 'spring2', image: '/images/banner/img4.jpg'},
    ],
    temparray:[],
    newvalue:[],
    tempid: 1,
    easeOut: '',

      }
  },
  methods: {
    nextImage() {
    // if(this.count !== 5){
    //     this.count +=1;
    //     console.log(this.count);
    // } else { this.count = 0;}
    //    this.temparray.forEach((item) => {
    //               this.easeOut = 'opacity 3s ease-in-out';

    //       this.tempid=item.id;
    //       this.easeOut = 'opacity 3s ease-in-out';
    //   })
      if(this.tempid < this.allimages.length ){
      this.tempid +=1;
                this.easeOut = 'opacity 3s ease-in-out';

      //console.log(this.tempid);
      } else { this.tempid =1;}
      this.newvalue = this.allimages.filter(id => id.id === this.tempid )
                this.easeOut = 'opacity 3s ease-in-out';

     // console.log(this.newvalue);
    }
  },
  computed: {
//   video() {
//     //   console.log(this.videourl+this.$store.state.video[0].video);
//     return this.$store.state.video
//   }
},
//   methods: {
//         scrollToTop() {
//     window.scrollTo(0,0);
//   },
mounted(){
    //  this.scrollToTop();
        this.$store.dispatch("getVideo");
        setTimeout(this.nextImage,100)
              setInterval(this.nextImage, 3500) 
     },
}
</script>

<style scoped>

.img-logo {
     position: absolute;
    top: 37px;
    left: 37px; 
    width: auto;
    max-width: 100%;
    height: 40px;
    display: block;
     z-index: 99999;
}

.img-animation {
    width: 100%;
    height: 100vh;
    display: block;
    object-fit: cover !important;
}

.date-format{
    display: flex;
    font-family: Arial;
    font-size: 12px;
    color: #83879c;
}

.media-image {
    object-fit: none;
    float: right;
}
@media (max-width: 590px){
    .img-animation {
        height: auto;
    }
}

.slider [data-animation-in] {
    opacity: 0;
    animation-duration: 1.5s;
    transition: opacity 0.5s ease 0.3s;
	transition:1s;
}

.home-mediabox{
  display: block;
  margin: 0;
  padding: 92px  0;
}

.home-mediabox p {
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c !important;
  line-height: 22px;
  display: block;
  margin: 20px 0;
  padding: 0 15px;
  text-align: center;
}

</style>
