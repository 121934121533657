<template>
  <NavBar> </NavBar>
  <div class="inner-section clearfix">
            <section>
                <div>
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="signupTitle mb-3 clearfix">Order Confirmation</div>
                             <div class="col-12 ">
                            <div class="contact-box clearfix">
                              Payment is successfully Completed. Order Number is: {{orderid}}. This is also visible in dashboard.
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
  </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
  components: {
    NavBar
  },
  data() {
    return {
      orderid: ''
    }
  },
  mounted() {
    this.orderid=this.$route.params.orderId

  }

}
</script>

<style>

</style>