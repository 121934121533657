<template>
  <NavBar />
        <div class="inner-section clearfix" >
            <section>
                <div class="container" >
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="page-heading">Categories</div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                        v-for="data in subCat" :key="data.id">
                            <router-link :to="{name:'GallerySubImages',
                            params: { subcatid: data.id },
                            query: { id: pid, catid: pid},
                            }">
                                <div class="category clearfix" >
                                    <div class="image-holder">
                                        <img :src="url+data.image" 
                                        alt="image goes here" />
                                        <span>{{data.web_subcat_name}}</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12">
                            <center><a href="#" 
                            class="btn btn-outline-black btn -xl">
                            View all Categories</a></center>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
  props: [ 'id'],
  components: {
    NavBar
  },
data() {
  return {
    pid: 0,
    subCat: [{
      "id": 0,
      "name": '',
      "image": ''
    }],
    allSubCat: [],
    url: 'https://bnbarts.com/dev/public/',
    photgrapherid: 0
  }
},
computed: {
  photographersCategories() {
      return this.$store.state.photographersCategories
  },
  photographersSubCategories() {
      return this.$store.state.photographersSubCategories
  },
  foundMatchedSubCategoryData() {
        this.subCat.shift();

    return this.photographersSubCategories.forEach((value) => {
      if(value.web_cat_id === parseInt(this.pid)){
      this.subCat.push(value);
        console.log(this.subCat);
      }
      
    })
   
  }
},
mounted() {
      this.$store.dispatch("getPhotographersData");
    this.$store.dispatch("getPhotographersCategories");
    this.$store.dispatch("getPhotographersSubCategories");
  this.pid = this.$route.params.pid;
  console.log(this.pid);
  this.foundMatchedSubCategoryData;
  const urlParams = new URLSearchParams(window.location.search);
    this.photgrapherid = urlParams.get("id");
    // console.log(this.photgrapherid);
}
}
</script>

<style>

</style>