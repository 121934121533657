<template>
   <Multiselect
      v-model="example5.value"
      v-bind="example5"
    ></Multiselect>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  props: ['options'],
components: {
    Multiselect,
  },
  data() {
    return {
      example5: {
        mode: "tags",
        value: [""],
        closeOnSelect: false,
        options: [
      {
        label: 'Nonprofit NGO',
        value: 'Nonprofit NGO'
      },
      {
        label: 'Annual Report',
        value: 'Annual Report'
      },
      {
        label: 'Magazine',
        value: 'Magazine'
      },
      {
        label: 'Newspaper',
        value: 'Newspaper'
      },
      {
        label: 'TV',
        value: 'TV'
      },
      {
        label: 'Radio',
        value: 'Radio'
      },
      {
        label: 'Book',
        value: 'Book'
      },
      {
        label: 'Exhibitions',
        value: 'Exhibitions'
      },
      {
        label: 'Documentries',
        value: 'Documentries'
      },
      {
        label: 'Multimedia',
        value: 'Multimedia'
      },
      {
        label: 'Educational',
        value: 'Educational'
      },
      {
        label: 'Research',
        value: 'Research'
      },
      {
        label: 'Presentation',
        value: 'Presentation'
      },
      {
        label: 'Other',
        value: 'Other'
      },

    ],
        searchable: true,
        createOption: true,
      },
    }
  },
}
</script>

<style src="../../assets/css/default.css">
.multiselect{
  width: 60% !important;
}
</style>