import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/css/style.css"
import axios from 'axios'
import VueAxios from 'vue-axios'
 import 'bootstrap-vue/dist/bootstrap-vue.css'
// import "bootstrap/dist/js/bootstrap.min.js";

//axios.defaults.baseURL = 'https://bnbarts.com/dev/public/api/';
axios.defaults.baseURL = 'https://bnbarts.digiinteracts.in/api/';



createApp(App).use(store).use(VueAxios,axios).use(router).mount('#app')
