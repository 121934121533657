<template>
  <div id="12" class="col-12">
    <div class="input-next">
      <div class="btn-holder" :id="id1">
        <label class="mt-3" for="firstDropdown">{{ dropdownName }}</label>
        <input
          class="inputSelection"
          :id="id"
          @click="FNDropdownEvent(id, $event)"
          :placeholder="placeholderText"
          :value="DataMutatedSelected"
        />
        <ul v-if="showDropdown" class="multiCheckboxSelection">
          <li v-for="item in tempCatAdded[0]" :key="item.category_id3">
            <input
              @change="checkboxClick(item.category_id3, $event)"
              :id="item.category_id3"
              type="checkbox"
              v-model="checkboxselection"
              :value="item.name3"
            />
            <label class="multiLabel" :for="item.category_id3">
              {{ item.name3 }}</label
            >
          </li>
        </ul>
        <div v-show="showDropdown" class="btn-inline">
          <a @click="NxtBtn" type="submit" class="next-buttonModal">Close</a>
        </div>
      </div>
      <!-- <span v-show="InquirBoolean">For Exhibitions, documentries, Multimedia, Educational, Research and Presentation, send email to abc@domain.com</span> -->
    </div>
  </div>
</template>

<script>
import ImageSelectionText from "../../Mixins/ImageSelectionText";
import axios from "axios";

export default {
  mixins: [ImageSelectionText],
  props: {
    data: {
      type: Array,
    },
    placeholderText: String,
    dropdownName: String,
    id: Number,
    id1: Number,
  },

  data() {
    return {
      showDropdown: false,
      checkboxselection: [],
      DataMutatedSelected: [],
      isCheckboxOpen: false,
      tempCatAdded: [],
      selectedData: [],
      firstDropdownChildData: [],
      secondDropdownChildData: [],
      thirdDropdownChildData: [],
      parentId: 0,
      subParentId: 0,
    };
  },
  methods: {
    FNDropdownEvent(id, event) {
      // console.log(id)
      this.subParentId = id;
      this.parentId = event.target.parentElement.id;
      //console.log(event.target.parentElement)

      this.get3rdChildValues(id);
      this.showDropdown = !this.showDropdown;
    },
    checkboxClick(id, event) {
      // alert(id);
      // alert(this.subParentId)
      // console.log(this.checkboxselection)
      this.showDropdown = true;
      this.MultipledropdownValuesMutation(this.checkboxselection);
      this.DataMutatedSelected = this.changesInDropDownSelection;

      ///this will add value if first variable is 0 and parent id is 10
      if (Number(this.parentId) === 10) {
        if(this.$store.state.firstDropdownChildData.length === 0){
          
        // alert("first dropdown");
        //push in first
        // console.log(this.tempCatAdded);
        this.tempCatAdded[0].forEach((i) => {
          if (i.category_id3 === id) {
            
            this.firstDropdownChildData.push({
              id1: i.category_id1,
              id2: i.category_id2,
              id3: i.category_id3,
            });
          }
        });
        console.log(this.firstDropdownChildData)
        
        // let firstCatNames=[]
        //      firstCatNames.push(this.checkboxselection)
        //  this.$store.commit(
        //   "SET_firstDropdownChildNames",
        //   firstCatNames
        // );
        this.$store.commit(
          "SET_firstDropdownChildData",
          this.firstDropdownChildData
        );
      } 
     
      ///this will add value if first variable is 0 and parent id is 10


      ///this will execute if first variable is not 0
      else {
      //  alert("not 0");
        let duplicateStatus = false;
        let duplicateSubParentStatus = false;
          // alert("checkif its running again or not");
          //match subparent id
          this.$store.state.firstDropdownChildData.forEach((id2loop) => {
              if (id2loop.id2 === Number(this.subParentId)) {
                // alert("2nd id checks passed " + this.subParentId);
                duplicateSubParentStatus = true;
              }
            });

          if(duplicateSubParentStatus){
            // console.log(this.subParentId);
            // alert("subparent id checks passed");
            //if the 3rd id is matched in the store then splice otherwise push
            this.$store.state.firstDropdownChildData.forEach((id3loop) => {
              if (id3loop.id3 === Number(id)) {
              //  alert("3rd id checks passed " + id);
                duplicateStatus = true;
              }
            });
            //checking duplicating status if its true then splice
            if (duplicateStatus) {
              let indexvalue =
                this.$store.state.firstDropdownChildData.findIndex(
                  (i) => i.id3 === id
                );
              if (indexvalue > -1) {
                // alert(indexvalue);

                this.$store.state.firstDropdownChildData.splice(indexvalue, 1);
                // console.log(this.$store.state.firstDropdownChildData)
              }
            } else {

              //its adding first category child data if its duplicate status is FALSE
              // console.log(this.tempCatAdded)
              let words = this.tempCatAdded[0].filter(
                (i) => i.category_id3 === id
              );
              // alert("no duplicate found step");
              // console.log(words);
              // console.log(this.parentId);
              // console.log(this.subParentId);
              // console.log(this.firstDropdownChildData)
              this.firstDropdownChildData.push({
                id1: words[0].category_id1,
                id2: words[0].category_id2,
                id3: words[0].category_id3,
              });
            //    let firstCatNames=[]
            //  firstCatNames.push(this.checkboxselection)
        //  this.$store.commit(...this.$store.state.firstDropdownChildNames,
        //   "SET_firstDropdownChildNames",
        //   firstCatNames
        // );
              this.$store.commit(
                "SET_firstDropdownChildData",
                JSON.parse(JSON.stringify(this.firstDropdownChildData))
              );
            }
          } else {
// alert("new checkbox")
              //this will execute if id3 does not exist in first.
              this.firstDropdownChildData = [
                ...this.$store.state.firstDropdownChildData,
              ];
              this.tempCatAdded[0].forEach((i) => {
                if (i.category_id3 === id) {
                  // alert("annual report " + id);
                  this.firstDropdownChildData.push({
                    id1: i.category_id1,
                    id2: i.category_id2,
                    id3: i.category_id3,
                  });
                }
              });
              //console.log(this.firstDropdownChildData)
              this.$store.commit(
                "SET_firstDropdownChildData",
                JSON.parse(JSON.stringify(this.firstDropdownChildData))
              );
          }
      }


      ///second category conditions
 } else 
 if (Number(this.parentId) === 11) {
        if(this.$store.state.secondDropdownChildData.length === 0){
          
        //alert("first dropdown");
        //push in first
        //console.log(this.tempCatAdded);
        this.tempCatAdded[0].forEach((i) => {
          if (i.category_id3 === id) {
            this.secondDropdownChildData.push({
              id1: i.category_id1,
              id2: i.category_id2,
              id3: i.category_id3,
            });
          }
        });
        this.$store.commit(
          "SET_secondDropdownChildData",
          this.secondDropdownChildData
        );
      } 
     
      ///this will add value if first variable is 0 and parent id is 10


      ///this will execute if first variable is not 0
      else {
        //alert("not 0");
        let duplicateStatus = false;
        let duplicateSubParentStatus = false;
        //  alert("checkif its running again or not");
          //match subparent id
          this.$store.state.secondDropdownChildData.forEach((id2loop) => {
              if (id2loop.id2 === Number(this.subParentId)) {
              //  alert("2nd id checks passed " + this.subParentId);
                duplicateSubParentStatus = true;
              }
            });

          if(duplicateSubParentStatus){
            // console.log(this.subParentId);
            // alert("subparent id checks passed");
            //if the 3rd id is matched in the store then splice otherwise push
            this.$store.state.secondDropdownChildData.forEach((id3loop) => {
              if (id3loop.id3 === Number(id)) {
                // alert("3rd id checks passed " + id);
                duplicateStatus = true;
              }
            });
            //checking duplicating status if its true then splice
            if (duplicateStatus) {
              let indexvalue =
                this.$store.state.secondDropdownChildData.findIndex(
                  (i) => i.id3 === id
                );
              if (indexvalue > -1) {
                // alert(indexvalue);

                this.$store.state.secondDropdownChildData.splice(indexvalue, 1);
                // console.log(this.$store.state.secondDropdownChildData)
              }
            } else {

              //its adding first category child data if its duplicate status is FALSE
              // console.log(this.tempCatAdded)
              let words = this.tempCatAdded[0].filter(
                (i) => i.category_id3 === id
              );
              alert("no duplicate found step");
              // console.log(words);
              // console.log(this.parentId);
              // console.log(this.subParentId);
              // console.log(this.secondDropdownChildData)
              this.secondDropdownChildData.push({
                id1: words[0].category_id1,
                id2: words[0].category_id2,
                id3: words[0].category_id3,
              });
              this.$store.commit(
                "SET_secondDropdownChildData",
                JSON.parse(JSON.stringify(this.secondDropdownChildData))
              );
            }
          } else {
// alert("new checkbox")
              //this will execute if id3 does not exist in first.
              this.secondDropdownChildData = [
                ...this.$store.state.secondDropdownChildData,
              ];
              this.tempCatAdded[0].forEach((i) => {
                if (i.category_id3 === id) {
                  // alert("annual report " + id);
                  this.secondDropdownChildData.push({
                    id1: i.category_id1,
                    id2: i.category_id2,
                    id3: i.category_id3,
                  });
                }
              });
              // console.log(this.secondDropdownChildData)
              this.$store.commit(
                "SET_secondDropdownChildData",
                JSON.parse(JSON.stringify(this.secondDropdownChildData))
              );
          }
      }
 } else 

 ///third cat
 if (Number(this.parentId) === 12) {
        if(this.$store.state.thirdDropdownChildData.length === 0){
          
        //alert("first dropdown");
        //push in first
        //console.log(this.tempCatAdded);
        this.tempCatAdded[0].forEach((i) => {
          if (i.category_id3 === id) {
            this.thirdDropdownChildData.push({
              id1: i.category_id1,
              id2: i.category_id2,
              id3: i.category_id3,
            });
          }
        });
        this.$store.commit(
          "SET_thirdDropdownChildData",
          this.thirdDropdownChildData
        );
      } 
     
      ///this will add value if first variable is 0 and parent id is 10


      ///this will execute if first variable is not 0
      else {
        // alert("not 0");
        let duplicateStatus = false;
        let duplicateSubParentStatus = false;
          // alert("checkif its running again or not");
          //match subparent id
          this.$store.state.thirdDropdownChildData.forEach((id2loop) => {
              if (id2loop.id2 === Number(this.subParentId)) {
                // alert("2nd id checks passed " + this.subParentId);
                duplicateSubParentStatus = true;
              }
            });

          if(duplicateSubParentStatus){
            // console.log(this.subParentId);
            // alert("subparent id checks passed");
            //if the 3rd id is matched in the store then splice otherwise push
            this.$store.state.thirdDropdownChildData.forEach((id3loop) => {
              if (id3loop.id3 === Number(id)) {
                // alert("3rd id checks passed " + id);
                duplicateStatus = true;
              }
            });
            //checking duplicating status if its true then splice
            if (duplicateStatus) {
              let indexvalue =
                this.$store.state.thirdDropdownChildData.findIndex(
                  (i) => i.id3 === id
                );
              if (indexvalue > -1) {
                // alert(indexvalue);

                this.$store.state.thirdDropdownChildData.splice(indexvalue, 1);
                // console.log(this.$store.state.thirdDropdownChildData)
              }
            } else {

              //its adding first category child data if its duplicate status is FALSE
              // console.log(this.tempCatAdded)
              let words = this.tempCatAdded[0].filter(
                (i) => i.category_id3 === id
              );
              // alert("no duplicate found step");
              // console.log(words);
              // console.log(this.parentId);
              // console.log(this.subParentId);
              // console.log(this.thirdDropdownChildData)
              this.thirdDropdownChildData.push({
                id1: words[0].category_id1,
                id2: words[0].category_id2,
                id3: words[0].category_id3,
              });
              this.$store.commit(
                "SET_thirdDropdownChildData",
                JSON.parse(JSON.stringify(this.thirdDropdownChildData))
              );
            }
          } else {
// alert("new checkbox")
              //this will execute if id3 does not exist in first.
              this.thirdDropdownChildData = [
                ...this.$store.state.thirdDropdownChildData,
              ];
              this.tempCatAdded[0].forEach((i) => {
                if (i.category_id3 === id) {
                  // alert("annual report " + id);
                  this.thirdDropdownChildData.push({
                    id1: i.category_id1,
                    id2: i.category_id2,
                    id3: i.category_id3,
                  });
                }
              });
              //console.log(this.thirdDropdownChildData)
              this.$store.commit(
                "SET_thirdDropdownChildData",
                JSON.parse(JSON.stringify(this.thirdDropdownChildData))
              );
          }
      }
 }

     // console.log(this.$store.state.firstDropdownChildNames)
      console.log(this.$store.state.firstDropdownChildData);

       console.log(this.$store.state.secondDropdownChildData);
      console.log(this.$store.state.thirdDropdownChildData);
    },
    get3rdChildValues(id) {
      //console.log(this.$store.state.firstDropdownChildData)
      axios
        .get("https://bnbarts.digiinteracts.in/api/child-category/index/" + id)
        .then((response) => {
          // console.log(response)
          //  let data = response.data.data.filter(i => i.category_id2===id)
          //  console.log(data)
          this.tempCatAdded.push(response.data.data);
          //console.log(this.tempCatAdded);
        });
    },
    NxtBtn() { 
      this.showDropdown = false;
    },
  },
};
</script>

<style>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1200px !important;
  }
}
.btn-holder {
  display: flex;
  flex-direction: row;
  position: relative;
}
.btn-inline {
  display: inline-block;
  width: 13%;
}

.next-buttonModal {
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  /* border: 1px solid #d7dbdf; */
  border-left: 0;
  padding: 3.5px 10px;
  border-radius: 0 0.25rem 0.25rem 0;
}

/* .next-buttonModal::after {
  content: "";
  position: absolute;
  bottom: 4px;
  right: 2%;
  width: 5%;
  height: 1px;
  background-color: #83878c;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
} */

* {
  font-family: "OldStandardTT-Regular";
  font-size: 14px;
}
.multiLabel {
  margin-left: 10px;
  width: 100%;
}
.inputSelection {
  background: rgb(254, 255, 253);
  border: 1px solid #d7dbdf;
  height: 30px;
  width: 87%;
  display: inline-block;
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 0 10px;
}
/* .multiCheckboxSelection {
  display: block;
  border: 1px solid #d7dbdf;
  height: 75px;
  overflow: scroll;
  padding: 0;
  width: 350px;
} */

.multiCheckboxSelection {
  display: block;
  height: auto;
  max-height: 100px;
  overflow: scroll;
  padding: 0;
  /* width: 350px; */
  width: 87%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #d7dbdf;
  z-index: 99;
}
.multiCheckboxSelection > li:hover {
  background-color: #f2f2f2;
}

.multiCheckboxSelection > li {
  padding: 3px 12px;
  display: flex;
}

.usage-type {
  display: block;
  border: 1px solid #83878c;
  padding: 5px;
  margin-top: 5px;
  width: 200px;
  height: 150px;
  overflow: scroll;
}
.usageSelection-row {
  float: left;
  margin: 5px 10px;
}
.duration {
  width: 20%;
}
</style>

