<template>
  <div>
    <div>The page you came here is not found</div>
  </div>
  <router-link to="/">Go to Home Page</router-link>
</template>

<script>
export default {

}
</script>

<style>

</style>