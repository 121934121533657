<template>
  <NavBar> </NavBar>
  <div class="container">
    <div class="row clearfix">
      <div id="no-more-tables">
        <table
          cellspacing="0"
          cellpadding="0"
          class="table cart table-borderless
           table-responsive align-middle text-center table-striped w-50 mx-auto"
        >
          <thead>
            <tr>
              <th>Image</th>
              <th>Image ID</th>
              <!-- <th>Categories</th> -->
              <!-- <th class="numeric ">Price</th> -->
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in cartitems.data" :key="item.id">
              <td v-if="cartitems">
                <img
                  class="cart-img mx-auto"
                  :src="url1 + item.thumbnails"
                  alt=" "
                />
              </td>
              <td v-if="cartitems">{{ item.image_id_name+item.image_id }}</td>
              <!-- <td v-if="cartitems">
                {{ item.category_name1 }} {{ item.category_name2 }}
                {{ item.category_name3 }}
              </td> -->
              <!-- <td class >{{ this.cartitems.amount[index].amount }}</td> -->
              <td v-if="cartitems"
              class="deleteOrder"
               @click="deleteorder(item.id)">
               Delete
              </td>
            </tr>

            <tr v-for="item in guestcartdata" :key="item.guestid">
              <td>
                <img
                  class=" cart-img"
                  :src="url1 + item.thumbails"
                  alt=""
                />
              </td>
              <td>{{ item.id }}</td>
              <td>
                {{ item.firstDropdownValue }} {{ item.secondDropdownValue }}
                {{ item.thirdDropdownValue }}
              </td>
              <td>${{ item.totalprice }}</td>
              <td>
                <i
                  class="fa fa-times"
                 
                  aria-hidden="true"
                ></i>
              </td>
            </tr>
          </tbody>
          <tfoot style="border-top: 1px solid #83878c">
            <tr >
              <td></td>
              <td></td>
              <td>
                Grand Total: ${{ this.$store.state.grandTotal }}</td>
            </tr>
          </tfoot>
        </table>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <Login @close="toggleModal" :modalActive="modalActive"></Login>

          <button
            type="button"
            class="cart-btn-outline-black p-0 mt-2"
            @click="!isLoggedIn ? toggleModal() : checksummary()"
          >
            CheckOut
          </button>
          <!-- <button  @click="toggleModal" type="button" 
class="btn btn-success" >Login</button> -->
        </div>
        <!-- <div @click="callpayment" ref="paypal">checkout</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/DefaultComponents/NavBar.vue";
import Login from "./Signup/Login.vue";
import { ref } from "vue";
import "../assets/css/dialog.css";
import axios from "axios";

export default {
  components: {
    NavBar,
    Login,
  },
  setup() {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    return { modalActive, toggleModal };
  },
  data() {
    return {
      fields: ["Product", "Price", "Quantity", "Total Price"],
      grandtotal: 0,
      thirdcategoryid: [],
      thirdcategorynames: [],
      url1: "http://flydigitally.in/",
      showModel: true,
      isLoggedIn: false,
      orderdata: [],
      guestdata: [],
      order: [],
      userData: [],
      userinfoId: 0,
      calTotalAmount: [],
      cartSummData: [
        { category_id1: 0 },
        { category_id2: 0 },
        { category_id3: 0 },
        { category_name1: 0 },
        { category_name2: 0 },
        { category_name3: 0 },
        { duration: 0 },
        { electronic_rights: 0 },
        { id: 0 },
        { image_size: "" },
        { img_attribtes_id: 0 },
        { method_distribution: 0 },
        { photographers_id: 0 },
        { photographers_name: "" },
        { print: "" },
        { territory_distribution: 0 },
        { thumbnails: "" },
        { title: "" },
        { user_name: 0 },
        { web_cat_id: "" },
        { web_cat_name: "" },
        { web_subcat_id: 0 },
        { web_subcat_name: "" },
        { amount: "" },

      ],
      amountArr: [],
      fulldata: [],
    };
  },
  // watch: {
  //   grandtotal: function(){
  //     this.cartitems.amount.forEach((item) => {
  //       this.grandtotal += item.amount;

  //       console.log(this.grandtotal);
  //     });
  //   }
  // },
  computed: {
    cartitems() {
      this.calTotalAmount = this.$store.state.cartitems.amount;
      //console.log(this.$store.state.cartitems)
      return this.$store.state.cartitems;
    },
    // getgrandtotal() {
    //   this.calTotalAmount.forEach((item) => {
    //     this.grandtotal += item.amount;
    //     this.amountArr.push(item);
    //     console.log(this.grandtotal);
    //   });
    // },

    thirdcategoryapi() {
      // console.log(this.$store.state.thirdcategoriesDetails)
      return this.$store.state.thirdcategoriesDetails;
    },
    async calcgrandtotal() {
      await this.cartitems.amount.forEach((amt) => {
        if(amt.amount !== null) {
          console.log(amt.amount);
          // console.log(this.grandtotal);
        this.grandtotal += parseInt(amt.amount);
        
        //this.$store.commit('SET_GRANDTOTAL',this.grandtotal )
        }
        //console.log(this.grandtotal);
    })
    },
    guestcartdata() {
      return this.$store.state.guestcartitems;
    },
  },
  methods: {
    setLoaded: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: this.grandtotal,
                  },
                },
              ],
              application_context: {
                    shipping_preference: 'NO_SHIPPING'
                }
            });
          },
          onApprove: async (data, actions) => {
            this.order = await actions.order.capture();
            this.data;
            this.orderdata.push(this.order);
            // console.log(this.orderdata);
            // this.paidFor = true;
            // console.log(this.order);
            this.createOrder();
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },
    // callpayment() {

    // const script = document.createElement("script");
    // script.src =
    //   "https://www.paypal.com/sdk/js?client-id=ARNRNvgJTH0oaRUrQUC-p-MgCXzIOl5T6um6YqdW7U9mkwzV-ZkfCtp9c0QH6dRWArJY85Yh3rLCT5Vu";
    // script.addEventListener("load", this.setLoaded);
    // document.body.appendChild(script);
    // },
    //   createOrder() {
    //     this.cartitems.push({
    //         order_id: {value: this.orderdata.id},
    //         img_attr_id: { value: this.img_attr_id },
    //         merchant_id: { value: this.orderdata.merchant_id },
    //         user_id: { value: this.user_id },
    //         category_id1: { value: this.category_id1 },
    //         category_id2: { value: this.category_id2 },
    //         category_id3: { value: this.state.workEmail },
    //         web_cat_id: { value: this.web_cat_id },
    //         web_subcat_id: { value: this.web_subcat_id },
    //         phographers_id: { value: this.phographers_id },
    //         thumbnails: { value: this.thumbnails },
    //         amount: { value: this.amount },
    //         payment: { value: this.payment },
    //         print: { value: this.print },
    //         image_size: { value: this.image_size },
    //         electronic_rights: { value: this.electronic_rights },
    //         method_distrbution: { value: this.method_distrbution },
    //         duration: { value: this.duration },
    //         territory_distribution: { value: this.territory_distribution },
    //         created_at: { value: this.orderdata.create_time },
    //     })
    //     for(let i=0; i<=this.cartitems.length; i++) {
    //   axios.post('https://bnbarts.com/api/order/add', {
    //       order_id: this.orderdata.id,
    //         img_attr_id:  this.img_attr_id ,
    //         merchant_id:  this.orderdata.merchant_id ,
    //         user_id:  this.user_id ,
    //         category_id1:  this.category_id1 ,
    //         category_id2:  this.category_id2 ,
    //         category_id3:  this.state.workEmail ,
    //         web_cat_id:  this.web_cat_id ,
    //         web_subcat_id:  this.web_subcat_id ,
    //         phographers_id:  this.phographers_id ,
    //         thumbnails:  this.thumbnails ,
    //         amount:  this.amount ,
    //         payment:  this.payment ,
    //         print:  this.print ,
    //         image_size:  this.image_size ,
    //         electronic_rights:  this.electronic_rights ,
    //         method_distrbution:  this.method_distrbution ,
    //         duration:  this.duration ,
    //         territory_distribution:  this.territory_distribution ,
    //         created_at:  this.orderdata.create_time ,
    //      }).catch(error => {
    //          this.state.error = error.response.status;
    //          console.log(error.response.data);
    //       })
    //     }

    // },
    checksummary() {
      this.$router.push({ path: "/CustomLicense" });
    },
    deleteorder(id) {
       axios.post('http://flydigitally.in/api/add-to-card/delete/'+id).then((response) => {
        console.log(response.data);
                     this.$store.dispatch("getCartItems");
          this.calcgrandtotal;
          
    })
    }
  },
  mounted() {
    if (localStorage.getItem("user-info")) {
      this.isLoggedIn = true;
      this.userData = JSON.parse(localStorage.getItem("user-info"));
      this.userinfoId = this.userData.data.id;
    } else if (localStorage.getItem("guestcartitems")) {
      // this.guestdata = JSON.parse(localStorage.getItem("guestcartitems"));
      // console.log(this.$store.state.guestcartitems);
    } else {
      this.isLoggedIn = false;
    }
    this.$store.dispatch("getCartItems");
    //this.$store.dispatch("getThirdCategoriesDetail");
    // this.$store.dispatch("getAddGuestCartItems");
    //this.thirdcategoryapi;
    //this.getcartcategoryid3;
    // this.getcartcategory3name;
    this.cartitems;
    // this.getdata;
    this.calcgrandtotal;
    // this.getgrandtotal;

  },
};
</script>

<style scoped>
table, tr, td, th, td{
  color: #83878c;
}

hr{
  display: block;
  width: 100%;
  color: #83878c;
}

.w20{
  width: 20%;
}

.w10{
  width: 10%;
}

.w40{
  width: 40%;
}

.w50{
  width: 50%;
}

.deleteOrder{
  cursor: pointer;
}
.cart {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.45);
  padding: 15px;
}
.cart > thead {
  background-color: #cdaf99;
  border-radius: 8px 8px 0 0 !important;
}
.cart > thead > tr > th {
  
  color: #83878c;

  text-align: center;
  background-color: #dfdfde;
}
.cart > tbody > tr > td {
  color: #83878c;
  text-align: center !important;
  padding: 30px 20px !important;
}
.cart > tfoot > tr > td {
  padding: 30px 20px !important;
}
.grand-total {
  position: absolute;
  right: 160px;
  top: 300px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.cart-img {
  width: 100px;
}

.cart-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}
</style>
