<template>
<NavBar />

            <section class="photography-inner-section">
                           <div class="container-fluid">

                <img  class="img-animation" src="/images/banner/img3.jpg" alt="banner" />
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="photographyTitle">Photography</div>                        
                        </div>
                            <div class="photographer-right-content">
                           <div class="first-para">Our Photography program aims to present a local perspective of Afghanistan to a global landscape and is designed to train, encourage and provide job opportunities for photographers in/from Afghanistan. Moreover, it offers unique and personalized services that meet highest standard. Equipped with necessary knowledge and skills, we will not only create visual stories to media outlets, but also carry out assignments for your organizations, covering your events such as press conferences, and profiling your organization.</div><br>
For more information or request of an offer, <span class="click-here">click here.</span><br>
We offer quality and unique photography services bringing to you a local perspective.
                        </div>
                    </div>
                </div>
            </section>

</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
components: {
    NavBar
  },
  data() {
      return {
          url1: 'http://flydigitally.in/',
      }
  },
  computed: {
  photographers() {
    return this.$store.state.photographers
  }
},
mounted() {
    this.$store.dispatch("getPhotographers");
},
}
</script>

<style scoped>
.first-para{
    font-family: "OldStandardTT-Regular";
  font-size: 16px;
  color: #83878c !important;
  text-align: justify;
    color: #303030;
    margin-bottom: -14px;
}
.img-animation {
    width: 100%;
    height: 33vh;
    display: block;
    object-fit: cover !important;
}
.photography-inner-section{
    display: block;
  padding: 30px;
  margin: 0;
  border: 23px solid #fff;
  border-top: 0;
}

.photographer-right-content {
  margin: 16px;
  padding: 4px;
  width: 98%;
  font-family: "OldStandardTT-Regular";
  font-size: 16px;
  color: #83878c;
  text-align: justify;
  line-height: 30px;
  padding-bottom: 12px !important;
}
.click-here{
 font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c;  
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.click-here:after{
 content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(81% + 11px);
  color: #83878c;
}
</style>