<template>

        <NavBar />
        <div class="inner-section clearfix">            

            <section class="p-0">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-12 col-lg-3">
                            <div class="bg-f8f9f9 box-shadow h-100">
                                <ul class="sidenav clearfix">
                                    <li class="active"
                                    @click="showLicense =!showLicense">
                                    <a>My Licenses</a>
                                    </li>
                                    <li><a href="#">Saved License</a></li>
                                    <li><a href="#">Saved Images</a></li>
                                    <li><a href="#">Help</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-9">
                            <div class="ptb-30">
                                <div class="card box-shadow mb-3 clearfix">
                                    <div v-if="!showLicense" 
                                         class="card-header text-uppercase">Basic Information

                                    <div class="card-body"
                                    v-if="!showLicense" >
                                        <form @submit.prevent="submitForm" method="post" 
                                        action="" id="updateProfile"
                                         role="form">
                                            <div class="row clearfix">
                                                <div class="col-12 col-lg-2">
                                                    <div class="imgChange mb-3">

                                                        <img :src="preview ? preview :url+image" class="img-fluid rounded-circle" 
                                                        alt="Image goes here" />
                                                        <span><a href="#"><i class="fa fa-times"></i></a></span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-10">
                                                    <label class="mt-5">
                                                        Choose Profile Photo
                                                        <input type="file" @change="handleImage" 
                                                        name="userPhoto" id="userPhoto" hidden />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input 
                                                        type="text" id="firstName" name="firstName" 
                                                        class="form-control bg-f8f9f9" 
                                                        placeholder="First Name*" 
                                                        v-model="state.firstName"
                                                        required />
                                                         <span v-if="v$.firstName.$error"> 
                                                     {{ v$.firstName.$errors[0].$message}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input 
                                                        type="text" id="lastName"
                                                         name="lastName" class="form-control bg-f8f9f9" 
                                                         v-model="state.lastName"
                                                         placeholder="Last Name*" required />
                                                          <span v-if="v$.lastName.$error"> 
                                                    {{ v$.lastName.$errors[0].$message}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input type="email" 
                                                        id="email" 
                                                        name="email" class="form-control bg-f8f9f9" 
                                                        placeholder="Email*" 
                                                        v-model.trim="state.email"
                                                        required />
                                                        <span v-if="v$.email.$error">
                                                     {{ v$.email.$errors[0].$message}}</span>
                                                <span v-if="state.errormsg"> {{state.errormsg}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input  type="text" id="phone" 
                                                        name="phone" class="form-control bg-f8f9f9" 
                                                        placeholder="Phone*" required 
                                                         v-model.trim="state.phone"/>
                                                         <span v-if="v$.phone.$error"> 
                                                     {{ v$.phone.$errors[0].$message}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-12">
                                                    <select  id="organization" 
                                                    name="organization" class="form-select bg-f8f9f9 border-0 mb-3"
                                                    v-model="state.companyType">
                                                        <option :value="userInfo.company_type" 
                                                        selected>{{userInfo.company_type}}</option>
                                                         <option value="organization">Organization</option>
                                                    <option value="privatebussiness">Private Business</option>
                                                    <option value="magazine">Magazine</option>
                                                    <option value="newspaper">Newspaper</option>
                                                    <option value="tv">TV</option>
                                                    <option value="radio">Radio</option>
                                                    <option value="books">Books</option>
                                                    <option value="exhibitions">Exhibitions</option>
                                                    <option value="film">Film</option>
                                                    <option value="multimedia">Multimedia</option>
                                                    <option value="educational">Educational</option>
                                                    <option value="research">Research</option>
                                                    <option value="freelance/consultant">Freelance/Consultant</option>
                                                    <option value="others">Others</option>
                                                    </select>
                                                    <span v-if="v$.companyType.$error"> 
                                                     {{ v$.companyType.$errors[0].$message}}</span>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input v-model="state.companyName" type="text" 
                                                        id="companyName" name="companyName" 
                                                        class="form-control bg-f8f9f9"
                                                         placeholder="Company Name" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <select id="country" name="country"
                                                         class="form-select bg-f8f9f9 border-0" 
                                                          @click="callCountries()"
                                                        v-model="state.country" >
                                                            <option v-if="countryflag"
                                                              v-for="item in countryList"
                                                              :key="item.id"
                                                              >
                                                              {{item.country}}
                                                              </option>
                                                            <option v-else
                                                            :value = "state.country" 
                                                            :selected="state.country" 
                                                            >{{state.country}}</option>
                                                             
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input v-model="state.workEmail" type="email"
                                                         id="workEmail" name="workEmail" 
                                                         class="form-control bg-f8f9f9" 
                                                         placeholder="Work Email*" required />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input  v-model="state.workPhone" 
                                                        type="number"
                                                         id="workPhone" name="workPhone" 
                                                         class="form-control bg-f8f9f9" 
                                                         placeholder="Work Phone*" required />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-12 text-center">
                                                    <input type="submit" class="media-btn-outline-black p-0 mt-2" 
                                                    id="submit" name="submit" value="SUBMIT" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    </div>
                                <Orders v-else>My License</Orders>
                                </div>
                                <div v-if="!showLicense" 
                                class="card box-shadow mb-3 clearfix">
                                    <div class="card-header text-uppercase">Manage Password</div>
                                    <div class="card-body">
                                        <form @submit.prevent="updatePassword" method="post" action="" 
                                        id="updatePassword" role="form">
                                            <div class="row clearfix">
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input type="password" id="changePassword" 
                                                        v-model="state.password"
                                                        name="changePssword" class="form-control bg-f8f9f9" 
                                                        placeholder="Change Password*" required />
                                                        <span v-if="v$.password.$error"> {{ v$.password.$errors[0].$message}} </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="mb-3">
                                                        <input type="password" id="confirmPassword" 
                                                        name="confirmPassword" class="form-control bg-f8f9f9" 
                                                        placeholder="Confirm Password*" required />
                                                        <span v-if="v$.confirmPass.$error"> {{ v$.confirmPass.$errors[0].$message}} </span>
                                                    </div>
                                                </div>                                                
                                                <div class="col-12 col-lg-12 text-center">
                                                    <input type="submit" class="media-btn-outline-black p-0 mt-2" id="submit" 
                                                    name="submit" value="Update" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
             <Dialog :show="showDialog"
          :ok="ok"
          title="Updated"
          description="Data is updated." />
        </div> 
</template>

<script>

import NavBar from "../../components/DefaultComponents/NavBar.vue"
let countries = require('../../data/countries.json');
import Login from './Login.vue'
import ImageUpload from "./imageupload2.vue"
import useValidate from '@vuelidate/core'
import { required, numeric, email, helpers, sameAs, minLength } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import axios from 'axios'
import OrdersSaved from '../OrdersSaved.vue'
import Orders from './Orders.vue'

export default {
    setup() {
        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phone:'',
            password: '',
            confirmPass: '',
            companyType:'',
            companyName:'',
            workEmail:'',
            workPhone: '',
            country:'',
            message: '',
            error: [],
            errormsg: '',
            confirm: '',
            err: ''
        })
        const rules = computed(() => {
            return {
                firstName: { 
                    required: helpers.withMessage(
                        'First Name must not be empty', required
                        ),
                    },
                lastName: { required: helpers.withMessage(
                        'Last Name must not be empty', required
                        ), },
                email: { required: helpers.withMessage(
                        'Email must not be empty', required
                        ), email},
                phone: { required: helpers.withMessage(
                        'Phone must not be empty', required
                        ), numeric},
                companyType: { required: helpers.withMessage(
                        'Company Type must not be empty', required
                        ), },
                companyName: { required: helpers.withMessage(
                        'Company Name must not be empty', required
                        ), },
                workEmail: { required: helpers.withMessage(
                        'Work Email must not be empty', required
                        ), },
                workPhone: { required: helpers.withMessage(
                        'Work Phone must not be empty', required
                        ), numeric},
                country: { required: helpers.withMessage(
                        'Country must not be empty', required
                        ), },

                password: { required: helpers.withMessage(
                        'Password must not be empty', required
                        ), },
                 confirmPass: { required: helpers.withMessage(
                        'Confirm password must be matching', required
                        ), sameAsPassword: sameAs(state.password)}
            };
        })
        const v$ = useValidate(rules, state)
        return {
            state,
            v$,
        }
    },
  props: ['id'],
  data() {
    return{
       userInfo:  {
              id: '',
              first_name: '',
               last_name: '',
               phone: '',
               email: '',
               company_type: '',
               company_name: '',
               country: '',
               work_email: '',
               work_phone: '',
               password: '',
               image: '',
       },
       userData: {},
      countryList: countries,
      countryflag: false,
      file: '',
        uploadPercentage: 0,
        preview: null,
        token: '',
        showLicense: false,
        updatedData: [],
            url: 'http://flydigitally.in/',
            convertBase64Image: '',

    }
  },
  components: {
        NavBar,
        Login,
        ImageUpload,
        OrdersSaved,
        Orders
    },
methods: {
     logout() {
     let config= {
        headers: {
            'Authorization': 'Bearer ' + this.token
        }
    }
    axios.post('http://flydigitally.in/api/user/logout/'+this.userInfo.id, 
    config).catch(error => {
             this.state.error = error.response;
         })
             alert("You are logout");

    this.$router.push({path: '/'});
        localStorage.clear();
  },
  callCountries() {
    // console.log(this.countryList);
    this.userInfo.country = this.countryList.country;
    this.countryflag = true;
  },

    handleFileUpload( event ){
		this.file = event.target.files[0];
        console.log(this.file);
},
async submitForm() {
    this.v$.$validate();
    if(this.v$.$error) {
        this.postdata();
        this.showDialog = true;
    } else {
        this.confirmErrMsg = true;
        this.showDialog = false;
    }
},
updatePassword() {
     let config= {
         headers: {
             'Authorization': 'Bearer ' + this.token
         }
     }
     axios.post('http://flydigitally.in/api/user/update/'+this.userInfo.id,  {
          password: this.state.password,
          }, config).catch(error => {
            console.log(error)
              this.state.error = error.response;
          })
},


 handleImage(e){

   var input = e.target;
    const selectedImage = e.target.files[0]; 
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.createBase64Image(selectedImage);
      }
 },
                createBase64Image(fileObject) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        //this.image = e.target.result;
                        // this.postdata(this.image);
                        // this.sendimagePost(this.image)
                        this.convertBase64Image = e.target.result;
                        let config= {
        headers: {
            'Authorization': 'Bearer ' + this.token
        }
    }
    axios.post('http://flydigitally.in/api/user/update/'+this.userInfo.id,  {
        first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            company_type: this.state.companyType,
            company_name: this.state.companyName,
            work_email: this.state.workEmail,
            country: this.state.country,
            work_phone: this.state.workPhone,
         image: this.convertBase64Image,
         }, config).catch(error => {
             this.state.error = error.response;
             if (this.state.success === true) {
            this.state.errormsg = "Data is updated";
          }
         })
                    };
                    reader.readAsDataURL(fileObject);
                },




async postdata() {
    console.log(this.token);
    
    let config= {
        headers: {
            'Authorization': 'Bearer ' + this.token
        }
    }
    let result = await axios.post('http://flydigitally.in/api/user/update/'+this.userInfo.id,  {
         first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            company_type: this.state.companyType,
            company_name: this.state.companyName,
            work_email: this.state.workEmail,
            country: this.state.country,
            work_phone: this.state.workPhone,
         }, config)
               console.log(result);
               if(result.data.success === true){
                this.userInfo = result.data;
                localStorage.setItem('user-info', JSON.stringify(result.data))
                this.userInfo.id = this.userInfo.data.id;
    this.userInfo.firstName = this.userInfo.data.first_name;
        this.lastName = this.userInfo.data.last_name;
    this.email = this.userInfo.data.email;
    this.phone = this.userInfo.data.phone;
    this.companyType = this.userInfo.data.company_type;
        this.companyName = this.userInfo.data.company_name;
    this.country = this.userInfo.data.country;
    this.workEmail = this.userInfo.data.work_email;
    this.workPhone = this.userInfo.data.work_phone; 
    this.$store.dispatch('getProfileImage', this.image);
    this.profileimage;
               }
                
        },
ok() {
      this.showDialog = false;
       location.reload();
    },

},
computed: {
     profileimage() {
        // this.profileimg = this.$store.getters.profileimage;
        // console.log("profilimg computed dash "+ this.$store.state.profileimage);
      return this.$store.state.profileimage;
     },
    getupdateddata() {
 const userdata = JSON.parse(localStorage.getItem("user-updated-info"));
                userdata.data.forEach((item) => {
                    console.log(item);
                })
    }
},

mounted() {

  if(localStorage.getItem('user-info')) {
    this.userData = JSON.parse(localStorage.getItem("user-info"));
    this.userInfo.id = this.userData.data.id;
    this.state.firstName = this.userData.data.first_name;
        this.state.lastName = this.userData.data.last_name;
    this.state.email = this.userData.data.email;
    this.state.phone = this.userData.data.phone;
    this.state.companyType = this.userData.data.company_type;
        this.state.companyName = this.userData.data.company_name;
    this.state.country = this.userData.data.country;
    this.state.workEmail = this.userData.data.work_email;
    this.state.workPhone = this.userData.data.work_phone;
        this.image = this.userData.data.image;
    this.token = this.userData.token;
    this.$store.state.quantity++;
    this.$store.state.profileimage = this.url+this.image;
  }
    this.$store.dispatch("getProfileImage");
},
}
</script>

<style scoped>
h1{
    color: black;
}

h1{
  color: black;
  font-size: 30px;
}
</style>