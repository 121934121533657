<template>
  <NavBar> </NavBar>
 <div class="inner-section clearfix" >
    <section class="all-images-section">
      <div class="container">
        <div class="row clearfix">
          <div class="col-12">
            <h2 class="allimages-heading">Images</h2>
                                <Search />
            <p class="allimages-ptag">
            </p>
          </div>
        </div>
        <div  v-if="searchedImages.length >= 0" class="row clearfix">
          <div
            class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
            v-for="item in searchedImages" :key="item.id">
              <router-link :to="{name: 'ImageDetail', 
              query: {imageid: item.id}}">
              <div class="box cat-box clearfix">
                <img :src="url+item.image" alt="" />
                <div class="cat-box-details">
                <h3>{{item.title}}</h3>
                <h3>ID: {{ item.image_id }}</h3>
                 <h6>{{convertedDate(item.date_time_original)}}</h6> 
                </div>
              </div>
              </router-link>
          </div>
        </div>
        <div class="row clearfix">
        <span v-if="!searchedImages.length != 0">No Images Found. Search Again</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from "../components/DefaultComponents/NavBar.vue";
import Search from '../components/DefaultComponents/Search.vue'


export default {
  components: {
    NavBar,
    Search
  },
  
  data() {
    return {
      
          url: "http://flydigitally.in/",
          defaultSearchTxt: ''

    };
  },
  computed: {
   searchedImages(){
    console.log(this.$store.state.searchedData)
    return this.$store.state.searchedData;
  }
  },
  methods: {
      convertedDate(dt) {
       //let dt='2021:10:04 15:19:17';
        let year = dt.slice(0,4)
      let month = dt.slice(5,7)
      let day = dt.slice(8,10)
       return month + '/' + day + '/' + year; 
      }
  },
  mounted() {
     const urlParams = new URLSearchParams(window.location.search);
      this.defaultSearchTxt = parseInt(urlParams.get("imgSearchTxt"));
    this.searchedImages;
    this.convertedDate;
            document.addEventListener("DOMContentLoaded", function() {
  var lazyloadImages = document.querySelectorAll("img.lazy");    
  var lazyloadThrottleTimeout;
  
  function lazyload () {
    if(lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }    
    
    lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
              img.src = img.dataset.src;
              img.classList.remove('lazy');
            }
        });
        if(lazyloadImages.length == 0) { 
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
    }, 20);
  }
  
  document.addEventListener("scroll", lazyload);
  window.addEventListener("resize", lazyload);
  window.addEventListener("orientationChange", lazyload);
});

   },
};
</script>


<style scoped>
span{
  color: red;
  text-align: center;
}
.box {
  display: block;
    margin: 0;
    padding: 0;
    background-color: #fff;
    margin: 0 auto 15px auto;
    width: 95%;
}
.box img {
  width: 100%;
  height: 355px;
  display: block;
  object-fit: cover;
}

.cat-box {
  width: 100%;
}

.cat-box h4 {
  font-size: 12px;
  color: #83879c;
}

.cat-box h6 {
  display: flex;
  font-family: "Arial";
  font-size: 12px;
  color: #83879c;
}


.cat-box img {
  width: 100%;
  height: 200px !important;
  object-fit: contain !important;
  object-position: left;
}

.cat-box h3 {
  display: inline;
  text-align: left;
  margin: 0 !important;
  padding: 5px 0;
  font-family: "OldStandardTT-Regular", Arial, Helvetica, sans-serif;
  font-size: 13px;
  min-height: auto;
  text-transform: capitalize;
  color: #83879c;
}

.cat-box-details{
  display: block;
  background: #fff;
  margin: 0;
  padding: 5px;
}

.allimages-heading {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: 0 0 15px 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

.allimages-heading:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(10% + 5px);
}
</style>
