<template>
  <NavBar />

  <div class="inner-section clearfix">
            <section>
                <div class="container">
                    <div class="row clearfix">
                        <div class=" col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3"
                         v-for="item in Photographerswebcat" :key="item.web_cat_id">
                            <figure >
                                <router-link 
                                :to="{name:'PhotographersSubCat', 
                                query: { 
                                photographer_id: photographer_id,
                                catid: item.web_cat_id }}">{{item.web_cat_name}}
                                  <img class="categoriesImages"
                                  :src="url+item.web_cat_thumbails"  
                                  alt="Image goes here" /></router-link>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
  </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
  components: {
    NavBar
  },
data() {
  return {
    photographer_id: 0,
    url: 'https://bnbarts.com/dev/public/',
    catid: 0,

  }
},
computed: {
  Photographerswebcat() {
    // console.log(this.$store.state.photographerswebcat.data);
    return this.$store.state.photographerswebcat.data;
  },
},
mounted() {
const urlParams = new URLSearchParams(window.location.search);
    this.photographer_id = urlParams.get("id");
      this.$store.state.photographeridclicked=this.photographer_id;
// console.log("statvalue"+this.$store.state.photographeridclicked)
    // console.log(this.photographer_id);
  this.$store.dispatch("getPhotographerswebcat");
},

}
</script>

<style scoped>
.categoriesImages {
  height: 200px !important;

}

</style>