<template>
  <div class="container">
    <div class="row clearfix">
      <div id="no-more-tables">
        <table
          cellspacing="0"
          cellpadding="0"
          class="table cart table-borderless table-responsive align-middle text-center table-striped"
        >
          <thead>
            <tr >
              <th >Photographer</th>
              <th >Category</th>
              <th >Sub Category</th>
              <th >DownLoad Image</th>

              <th class="numeric">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getuserorders" :key="item.id">
              <td >{{item.photographers_name}}</td>
              <td >{{item.category_name1}}</td>
              <td >{{item.category_name2}}</td>
              <td @click="downloadWithAxios(item.original_image)" >
                <img class="downloadimg" src="download.png"/> 
                </td> 
              <td >{{item.amount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
            url1: 'https://bnbarts.com/dev/public/',

    };
  },
  methods: {
    // forceFileDownload(response, title) {
    //   console.log(title)
    //   const url = window.URL.createObjectURL(new Blob([response.data]))
    //   const link = document.createElement('a')
    //   link.href = url
    //   link.setAttribute('download', title)
    //   document.body.appendChild(link)
    //   link.click()
    // },
    downloadWithAxios(path) {
      axios({
        method: 'GET',
        url: this.url1+path,
        responseType: 'blob',
      }).then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href=fileUrl
          fileLink.setAttribute('download', 'image.jpg')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
    },
  },
  computed: {
    getuserorders() {
      console.log(this.$store.state.ordersData);
      return this.$store.state.ordersData;
    },
  },
  mounted() {
    this.$store.dispatch("getOrders");
    this.getuserorders;
  },
};
</script>

<style>
.downloadimg {
  width: 35px;
  position: absolute;
  right: 200px;
  top: 42px;
}

</style>
