<template>
  <NavBar />
     <Login v-show="clickToLogin" @close="toggleModal" :modalActive="modalActive">
                          </Login>
  <div class="inner-section clearfix">
    <section class="img-detail-section">
      <div class="container-fluid">

        <div class="row clearfix" v-for="(i,index) in imgdata"
            :key="index">
          <div
            class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 col-xxl-7"
            
          >
            <div class="img-box"
            >
            <img
              :src="url + i.image"
              class="img-detail"
              alt="Image goes here"
            />
              <div class="d-flex mt-2">
            <button class=" btn-border btn-sm prev-button" @click="prevImageShow"> {{prev}} </button>
<!-- <button class=" btn-border btn-sm ms-auto next-button"  @click="nextImageShow">   {{next}}</button> -->
<button class=" btn-border btn-sm  next-button"  @click="nextImageShow">   {{next}}</button>
        <center><a href="" 
        class="media-btn-outline-black p-0 mt-2"
              data-bs-toggle="modal"
              data-bs-target="#purchaseCart"
        >Selection</a></center>

              </div>
              </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
            <!-- <div class="img-Title  ">{{ i.title }}

            </div> -->
             <!-- <a
              href="#"
              class="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#purchaseCart"
            >
              <i class="fa fa-download"></i> Get Price</a 
            >-->
            <p class="content">
              {{i.caption}}
            </p>
            <p class="user-info">
              Use Information: This content is intended for
              editorial use only. For other uses, additional clearances must be
              requested.
            </p>
            <ul class="details clearfix">
              <li v-if="i.image_id">
                <span class="title">ID:</span
                ><span class="info">{{ i.image_id_name+i.image_id }}</span>
              </li>
               <li v-if="i.head_line">
                <span class="title">Head Line:</span
                ><span class="info">{{ i.head_line }}</span>
              </li>
              <li v-if="i.image_date">
                <span class="title">Creation Date:</span
                ><span class="info">{{ i.image_date }}</span>
              </li>
              <li v-if="i.photographerName">
                <span class="title">Submission Date:</span
                ><span class="info">{{ i.creation_Date }}</span>
              </li>
              <li v-if="i.photographerName">
                <span class="title">Photographer:</span
                ><span class="info">{{ i.photographerName }}</span>
              </li>
               <li v-if="i.date_time_original">
                <span class="title">Date:</span
                ><span class="info"> {{convertedDate(i.date_time_original) }}</span>
              </li>
              <!-- <li v-if="i.date_time_original">
                <span class="title">Date:</span
                ><span class="info"
                >{{ i.date_time_original }}</span>
              </li> -->
              <li v-if="i.subject">
                <span class="title">Subject:</span
                ><span class="info">{{ i.subject }}</span>
              </li>
              <li v-if="i.document_title">
                <span class="title">Document Title:</span
                ><span class="info">{{ i.document_title }}</span>
              </li>
               <li v-if="i.copyright">
                <span class="title">Credit:</span
                ><span class="info">{{ i.copyright }}</span>
              </li>
               <li v-if="i.country">
                <span class="title">Country:</span
                ><span class="info">{{ i.country }}</span>
              </li>
              <li v-if="i.state">
                <span class="title">Province/State:</span
                ><span class="info">{{ i.state }}</span>
              </li>
               <li v-if="i.city">
                <span class="title">City:</span
                ><span class="info">{{ i.city }}</span>
              </li>
              
              <li v-if="i.category">
                <span class="title">Category:</span
                ><span class="info">{{ i.category }}</span>
              </li>
              <li v-if="i.sub_categories">
                <span class="title">Sub Categories:</span
                ><span class="info">{{ i.sub_categories }}</span>
              </li>
              <!-- <li v-if="i.author_title">
                <span class="title">Author Title:</span
                ><span class="info">{{ i.author_title }}</span>
              </li> -->
             
              <li v-if="i.photo_source">
                <span class="title">Photo Source:</span
                ><span class="info">{{ i.photo_source }}</span>
              </li>
              <li v-if="i.source">
                <span class="title">Source:</span
                ><span class="info"> {{ i.source }}</span>
              </li>
              <!-- <li v-if="i.caption_writer">
                <span class="title">Caption Writer:</span
                ><span class="info">{{ i.caption }}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div
    class="modal fade"
    id="purchaseCart"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="paypal-window" ref="paypal"></div>
          <div class="row mb-3 clearfix">
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5"
              v-for="i in getRandomSelectedImage"
              :key="i.id"
            >
              <div class="topheading">
                <!-- <img :src="url + i.image" alt="Image goes here" /> -->
                <p>{{ image_id }}<br />Editorial</p>
              </div>
            </div>
            <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 col-xxl-7">
              <small>Need Exclusivity?</small>
              <small
                ><router-link to="/contactus">Contact Us</router-link> or, Email
                <a href="mailto:contact@bnbarts.com">info@bnbarts.com</a></small
              >
            </div> -->
          </div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="my-license"
                data-bs-toggle="tab"
                data-bs-target="#myLicense"
                type="button"
                role="tab"
                aria-controls="myLicense"
                aria-selected="true"
                
              >
                Licenses
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="custom-license"
                data-bs-toggle="tab"
                data-bs-target="#customLicense"
                type="button"
                role="tab"
                aria-controls="custom-license"
                aria-selected="false"
              >
                Custom License
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="myLicense"
              role="tabpanel"
              aria-labelledby="my-license"
            >
             
              <small v-if="isSavedLicenseExist"
              class="text-start"
                >You have no saved licenses. Access licenses in Custom licenses Tab.</small>
                <div
                  class="form-check"
                  v-for="item in getSavedLicense"
                  :key="item.id"
                >
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    @click="showLicenseAmount(item.id)"
                    @change="getidselected"
                    :v-model="item.id"
                  />
                 
                  <label class="form-check-label defaultClr" for="flexRadioDefault1">
                    {{ item.category_name1 + " " + item.category_name2 }}
                  </label>
                  
                   </div>
                    <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                  <a class="saved-license-delete" @click="editSavedLicense(item.id)">Edit</a>
                  </div>
                  <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                  <a class="saved-license-delete" @click="deleteSavedLicense(item.id)">Delete</a>
                  </div>
              <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                  <p class="saved-license-amount">${{item.amount}}</p>
                  </div>
                
                </div>
                </div>
                </div>
              
            
            </div>
            <div
              class="tab-pane fade"
              id="customLicense"
              role="tabpanel"
              aria-labelledby="custom-license"
            >
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="customLicense"
                  role="tabpanel"
                  aria-labelledby="custom-license"
                >
                  <div class="row clearfix">
                    <div class="col-3 col-md-4">
                      <div class="form-floating">
                        <select
                          class="form-select"
                          id="firstDropdown"
                          v-model="firstDropdownValue"
                          @change="firstCatID($event)"
                        >
                          <option  value="" selected>
                            Select one...
                          </option>
                          <option
                           v-for="data in getCategoryOne"
                            :key="data.category_id"
                            :value="data.category_name1"
                          >{{ data.category_name1 }}
                          </option>
                        </select>
                        <label for="firstDropdown">Category of Use?</label>
                      </div>
                    </div>
                    <div class="col-3 col-md-4">
                      <div class="form-floating">
                        <div v-if="showSecondDropdown" class="form-floating">
                          <select
                            class="form-select"
                            @change="getSecondValue($event)"
                            v-model="secondDropdownValue"
                            
                          >
                            <option value="" selected>
                              Select one...
                            </option>
                            <option
                              v-for="item in getCategoryTwo"
                              :key="item.id"
                              :value="item.category_name2"
                            >
                              {{ item.category_name2 }}
                            </option>
                          </select>
                          <label for="floatingSelect"
                            >How will the image(s) be used?</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-md-4">
                      <div class="form-floating">
                        <div v-if="showThirdDropdown" class="form-floating">
                          <select
                            class="form-select"
                            @click="checkThird()"
                            @change="getThirdValue($event)"
                            v-model="thirdDropdownValue"
                          >
                            <option value="" selected>
                              Select one...
                            </option>
                            <option
                              v-for="info in getCategoryThree"
                              :key="info.category_id3"
                              :value="info.category_name3"
                            >
                              {{ info.category_name3 }}
                            </option>
                          </select>
                          <label for="floatingSelect"
                            >Where will the image appear?</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div v-if="showPrintRunBox" class="col-3 col-md-3">
                  <div class="form-floating">
                    <select
                      class="form-select short-form mt-2 mb-2"
                      @change="calcPrintPrice"
                      v-model="printrunvalue"
                    >
                      <option value="" selected>Select One...</option>
                      <option v-for="p in print" :value="p" :key="p">
                        {{ p }}
                      </option>
                    </select>
                    <label for="floatingSelect">Print Run:</label>
                  </div>
                </div>
                <div v-if="showImagePriceBox" class="col-3 col-md-3">
                  <div class="form-floating">
                    <select
                      class="form-select short-form mt-2 mb-2"
                      @change="calcImgSizePrice"
                      v-model="imgsizevalue"
                    >
                      <option value="" selected>Select One...</option>
                      <option v-for="p in imagesize" :value="p" :key="p">
                        {{ p }}
                      </option>
                    </select>
                    <label for="floatingSelect">Image Size:</label>
                  </div>
                </div>
                <div v-if="showElectRightBox" class="col-3 col-md-3">
                  <div class="form-floating">
                    <select
                      class="form-select short-form mt-2 mb-2"
                      @change="calcElecPrice"
                      v-model="electrightsvalue"
                    >
                      <option value="" selected>Select One...</option>
                      <option v-for="p in electronicRights" :value="p" :key="p">
                        {{ p }}
                      </option>
                    </select>
                    <label for="floatingSelect">Electronic Rights:</label>
                  </div>
                </div>
                <div v-if="showDurationBox" class="col-3 col-md-3">
                  <div class="form-floating">
                    <select
                      class="form-select short-form mt-2 mb-2"
                      @change="calcDurationPrice"
                      v-model="durationvalue"
                    >
                      <option value="" selected>Select One...</option>
                      <option v-for="p in duration" :value="p" :key="p">
                        {{ p }}
                      </option>
                    </select>
                    <label for="floatingSelect">Duration of Us:</label>
                  </div>
                </div>
                <div v-if="showDistributionBox" class="col-3 col-md-4">
                  <div class="form-floating">
                    <select
                      class="form-select short-form mt-2 mb-2"
                      @change="calcDistributionPrice"
                      v-model="distributionvalue"
                    >
                      <option value="" selected>Select One...</option>
                      <option
                        v-for="p in methodDistribution"
                        :value="p"
                        :key="p"
                        class="methoddistribution"
                      >
                        {{ p }}
                      </option>
                    </select>
                    <label for="floatingSelect">Methods of Distribution:</label>
                  </div>
                </div>
              </div>
            </div>
              <div class="justify-content-end mt-3 mb-3 clearfix">
                <div class="row g-3 align-items-end float-end">
                  <div class="col-auto">
                    <label for="date" class="col-form-label"
                      >What is your start date?
                    </label>
                  </div>
                  <div class="col-auto defaultClr">
                    <input
                      type="date"
                      id="date"
                      name="date"
                      class="form-control defaultClr"
                      aria-describedby="date"
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
          </div>
          <div class="imagePrice mt-3 clearfix">${{ totalprice }}</div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="save-btn-outline-black"
            @click="savedALicense"
            
          >
            Save License
          </button>
          <!-- <button
            type="button"
            class="checkout-btn-outline-black"
            @click="callpayment"
          >
            Checkout Now
          </button> -->
          <button type="button" class="addtocart-btn-outline-black" data-bs-dismiss="modal" @click="addtocart">
            Add to cart
          </button>
        </div>
      </div>
    </div>
   

  </div>
   
</template>

<script>
import NavBar from "../components/DefaultComponents/NavBar.vue";
import axios from "axios";
import Login from './Signup/Login.vue'
import { ref} from 'vue'


export default {
  props: ["false"],
  components: {
    NavBar,
    Login
  },
  setup() {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    return {modalActive,toggleModal};
  },
  data() {
    return {
      initialImgId: 0,
      clickToLogin: false,
      imgdata:[],
      imgid: 0,
      // url: "https://bnbarts.com/dev/public/",
      url: "http://flydigitally.in/",
      firstDropdownValue: "",
      thirdDropdownValue: "",
      secondDropdownValue: "",
      catOneID: 0,
      catTwoID: 0,
      catThreeID: [],
      categoryOne: [],
      categoryTwo: [{ cat2name: "" }],
      categoryThree: [{ cat3name: "" }],
      filteredCategoryTwoData: [],
      filteredCategoryThreeData: [],
      showSecondDropdown: false,
      showThirdDropdown: false,
      print: [],
      printPrice: [],
      imagesize: [],
      duration: [],
      electronicRights: [],
      imagesizePrice: [],
      electronicRightsPrice: [],
      durationPrices: [],
      methodDistribution: [],
      methodDistributionPrice: [],
      printingprice: 0,
      printArr: [],
      printrunvalue: "",
      durationvalue: "",
      distributionvalue: "",
      distributionprice: 0,
      electrightsvalue: "",
      imgsizevalue: "",
      showotherdropdown: false,
      priceArr: [],
      totalprice: 0,
      imgsizeprice: 0,
      electprice: 0,
      durationprice: 0,
      isLoading: false,
      guestcartcount: 0,
      loaded: false,
      isSavedLicenseExist: false,
      image_date: null,
      description: "printing",
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dateOriginal: "",
      image_id: "",
      photographerName: "",
      file_size: "",
      mime_type: "",
      software: "",
      event: "",
      subject: "",
      location: "",
      source: "",
      make: "",
      model: "",
      orientation: "",
      resolution: "",
      img_made_software: "",
      exposureTime: "",
      shutter_speed_value: "",
      aperture_value: "",
      exposure_bias_value: "",
      max_aperture_value: "",
      metering_mode: "",
      flash: "",
      focal_length: "",
      sub_sec_time: "",
      sub_sec_time_original: "",
      sub_sec_time_digitized: "",
      color_space: "",
      img_width: "",
      img_length: "",
      focal_plane_x_resolution: "",
      focal_plane_resolution_unit: "",
      sensing_method: "",
      custom_rendered: "",
      exposure_mode: "",
      white_balance: "",
      focal_lengthIn_35mmFilm: "",
      scene_capture_type: "",
      gain_control: "",
      contrast: "",
      saturation: "",
      sharpness: "",
      subject_distance_range: "",
      undefined_tag_0xA431: "",
      undefined_tag_0xA432: "",
      undefined_tag_0xA434: "",
      document_title: "",
      urgency: "",
      category: "",
      sub_categories: "",
      keywords: "",
      release_date: "",
      release_time: "",
      special_instructions: "",
      author_byline: "",
      author_title: "",
      city: "",
      head_line: "",
      photo_source: "",
      copyright: "",
      caption_writer: "",
      light_source: "",
      exif_image_width: "",
      exif_image_length: "",
      creation_Date: "",
      iso_speed_ratings: "",
      exposure_program: "",
      f_number: "",
      exposure_time: "",
      otr: "",
      showImagePriceBox: false,
      showDistributionBox: false,
      showDurationBox: false,
      showElectRightBox: false,
      showPrintRunBox: false,
      photographer_id: 0,
      imageattributeid: 0,
      categoryid1: 0,
      categoryid2: 0,
      categoryid3: 0,
      webcatid: 0,
      websubcatid: 0,
      thumbails: "",
      userData: [],
      userinfoId: 0,
      imageattrid: 0,
      title: "",
      licenseAmount: 0,
      savedlicenseIDSelected: 0,
      guestcartitems: [],
      imgCat1: 0,
      imgCat2: 0,
      imgCat3: null,
      Filteredcategory1Names: [],
      imgManageTable_id: 0,
      selectedImageData: [],
      web_subcat_name: '',
      catName: '',
      web_subcat_id: 0,
      prev: '<',
      next: '>',
      nextVal: '',
      prevVal: '',
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.imageattrid = parseInt(urlParams.get("imageid"));
                this.initialImgId = this.imageattrid;
    this.subcatid = urlParams.get("subcatid");
    this.catid = urlParams.get("catid");
    //console.log(this.catid);
    //console.log(this.subcatid);

    //localStorage.setItem('imageId', this.imageattrid);
    //console.log("img att: "+this.imageattrid);
    this.web_subcat_name = urlParams.get("subcatName");
    this.catName = urlParams.get("catName");
    this.getImage;
    this.getImageDetail();
    this.$store.state.photographersSubCategories.forEach((item) => {
      if(item.web_subcat_name === this.web_subcat_name) {
        this.web_subcat_id = item.web_subcat_id;
      };
    })
    this.$store.state.gallerydata.forEach((info) => {
      if(info.web_cat_name === this.catName) {
        this.web_cat_id = info.web_cat_id;
              // console.log(this.web_cat_id)
      };
    })
    this.$store.state.photographersdata.forEach((photographer_id) => {
      if(photographer_id.id === this.imageattrid ){
        this.photographer_id = photographer_id.photographers_id;
      }
    })

    
    //this.$store.dispatch("getRandomSelectedImage");
    // this.$store.dispatch("getCategoriesDetail");
    // this.$store.dispatch("getSecondCategoriesDetail");
    // this.$store.dispatch("getThirdCategoriesDetail");
     this.$store.dispatch("getSavedLicense");
    // this.$store.dispatch("getCartItems");
    // this.$store.dispatch("getPhotographersData");
          // this.$store.dispatch("getPhotographersData");
           this.$store.dispatch("getCategoryOne");
          this.$store.dispatch("getCategoryTwo");
          this.$store.dispatch("getCategoryThree");
// this.cartitems;
// this.getRandomSelectedImage;
//     this.getCategoriesDetail;
     this.getImageExif;
//     this.getimage_date;
//     //this.getSavedLicense;
//     // this.getcartcount;
//     this.getcartnumbers;
//     this.imageattributedata;
//     this.category1DropdownData;
     this.getCategoryOne;
  },

  computed: {
   
  photographersdata() {
     //console.log(this.$store.state.photographersdata.data[0].id);
    return this.$store.state.photographersdata.data[0].id;
  },
    getCategoryOne() {
      //console.log(this.$store.state.ImgCategoryOne);
      return this.$store.state.ImgCategoryOne;
    },
    // getCategoryTwo() {
    //   console.log(this.$store.state.ImgCategoryTwo)
    //   return this.$store.state.ImgCategoryTwo;
    // },
    getCategoryThree() {
      return this.$store.state.ImgCategoryThree;
    },
    imageattributedata() {
       //console.log(this.$store.state.photographersdata);
      return this.$store.state.photographersdata
  },
  // selectedImageData() {
  //   this.imageattributedata.forEach((item) => {
  //     if(item.id === parseInt(this.imageattrid)) {
  //     // this.imgCat1 = item.category_id1;
  //     // this.imgCat2 = item.category_id2;
  //     // this.imgCat3 = item.category_id3;
  //     }
  //       // console.log(item.category_id1,item.category_id2, item.category_id3);
  //   })
  // },
    
    cartitems() {
      // this.cartcount = this.$store.state.cartitems.length;
      // console.log(this.cartcount);
      return this.$store.state.cartitems;
    },
    getcartnumbers() {
      // this.cartcount = this.$store.state.cartitems.length;
      return this.$store.state.quantity;
    },
    getSavedLicense() {

      this.$store.state.savedlicenses.length !== 0 ? 
        this.isSavedLicenseExist = false :
        this.isSavedLicenseExist = true
    //this.$store.dispatch("getSavedLicense");

      return this.$store.state.savedlicenses;
    },
    // getSavedLicenseAmount() {
    //   this.$store.state.savedlicenses.forEach((item) => {
    //     if (item.id === this.savedlicenseIDSelected)
    //       this.licenseAmount = parseInt(item.amount);
    //     // console.log(item.id);
    //   });
    // },
    photographersdata() {
      // console.log(this.$store.state.photographersdata);
      return this.$store.state.photographersdata;
    },
    getImageExif() {
      this.photographersdata.forEach((item) => {
        if (item.id === parseInt(this.imageattrid)) {
          this.dateOriginal = item.date_time_original;
          this.photographerName = item.name;
          this.copyright = item.copyright;
          this.exif_image_length = item.exif_image_length;
          this.exif_image_width = item.exif_image_width;
          this.make = item.make;
          this.model = item.model;
          this.source = item.source;
          this.location = item.country + " " + item.state;
          this.event = item.sub_location;
          this.caption_writer = item.caption_writer;
          this.subject = item.subject;
          this.photo_source = item.photo_source;
          this.head_line = item.head_line;
          this.author_title = item.author_title;
          this.creation_Date = item.creation_date;
          this.keywords = item.keywords;
          this.category = item.category;
          this.urgency = item.urgency;
          this.document_title = item.document_title;
          this.undefined_tag_0xA431 = item.undefined_tag_0xA431;
          this.undefined_tag_0xA432 = item.undefined_tag_0xA432;
          this.undefined_tag_0xA434 = item.undefined_tag_0xA434;
          this.subject_distance_range = item.subject_distance_range;
          this.sharpness = item.sharpness;
          this.saturation = item.saturation;
          this.contrast = item.contrast;
          this.gain_control = item.gain_control;
          this.scene_capture_type = item.scene_capture_type;
          this.focal_lengthIn_35mmFilm = item.focal_lengthIn_35mmFilm;
          this.white_balance = item.white_balance;
          this.exposure_mode = item.exposure_mode;
          this.custom_rendered = item.custom_rendered;
          this.sensing_method = item.sensing_method;
          this.focal_plane_resolution_unit = item.focal_plane_resolution_unit;
          this.focal_plane_x_resolution = item.focal_plane_x_resolution;
          this.exif_image_width = item.exif_image_width;
          this.exif_image_length = item.exif_image_length;
          this.color_space = item.color_space;
          this.sub_sec_time_digitized = item.sub_sec_time_digitized;
          this.sub_sec_time_original = item.sub_sec_time_original;
          this.sub_sec_time = item.sub_sec_time;
          this.focal_length = item.focal_length;
          this.flash = item.flash;
          this.light_source = item.light_source;
          this.metering_mode = item.metering_mode;
          this.max_aperture_value = item.max_aperture_value;
          this.exposure_bias_value = item.exposure_bias_value;
          this.aperture_value = item.aperture_value;
          this.shutter_speed_value = item.shutter_speed_value;
          this.iso_speed_ratings = item.iso_speed_ratings;
          this.exposure_program = item.exposure_program;
          this.f_number = item.f_number;
          this.exposure_time = item.exposure_time;
          this.orientation = item.orientation;
          this.x_resolution = item.x_resolution;
          this.file_size = item.file_size;
          this.mime_type = item.mime_type;
          this.image_id = item.image_id;
          this.photographer_id = item.photographers_id;
          this.imageattributeid = item.id;
          this.categoryid1 = item.category_id1;
          this.categoryid2 = item.category_id2;
          this.categoryid3 = item.category_id3;
          this.webcatid = item.web_cat_id;
          this.websubcatid = item.web_subcat_id;
          this.thumbails = item.thumbnails;
          this.image_path = item.image;
          this.title = item.document_title;
          // console.log(this.categoryid1);
          // console.log(typeof(this.dateOriginal));
        }
      });
    },
    getImage() {
      return this.photographersdata.filter(
        (id) => id.id === parseInt(this.imageattrid)
      );
    },
    getcounting() {
 return this.$store.state.randomselectedimage;
    },
    getRandomSelectedImage() {
    //   this.nextVal = this.$store.state.randomselectedimage.next;
    //         this.preVal = this.$store.state.randomselectedimage.previous;
    // console.log(this.nextVal)
    //     console.log(this.preVal)
     // console.log(this.$store.state.randomselectedimage.data)
      // this.thumbails = this.$store.state.randomselectedimage[0].image;
      return this.$store.state.randomselectedimage.data;
    },
    getCategoriesDetail() {
      return this.$store.state.categoryDetails;
    },
    getSecondCategoriesDetail() {
      //console.log(this.$store.state.secondcategoryDetails);
      return this.$store.state.secondcategoryDetails;
    },
    getThirdCategoriesDetail() {
      return this.$store.state.thirdcategoriesDetails;
    },
    getImageDetails() {
                this.showPrintRunBox = true;
         axios.post("http://flydigitally.in/api/image_data_show/"+this.imgManageTable_id).then((res) => {
              this.selectedImageData = res.data.data;
              //console.log(JSON.parse(this.selectedImageData.print));
              this.print = JSON.parse(this.selectedImageData.print);
          this.printPrice = JSON.parse(this.selectedImageData.print_price);
          this.imagesize = JSON.parse(this.selectedImageData.image_size);
          this.electronicRights = JSON.parse(this.selectedImageData.electronic_rights);
          this.imagesizePrice = JSON.parse(this.selectedImageData.image_size_price);
          this.electronicRightsPrice = JSON.parse(
            this.selectedImageData.electronic_rights_price
          );
          this.durationPrices = JSON.parse(this.selectedImageData.duration_price);
          this.duration = JSON.parse(this.selectedImageData.duration);
          this.methodDistribution = JSON.parse(this.selectedImageData.method_distribution);
          this.methodDistributionPrice = JSON.parse(
            this.selectedImageData.method_distribution_price
          );
          this.calcPrintPrice;
          //console.log(this.print);
            })
    },
    price() {
      this.totalprice =
        this.printingprice +
        this.imgsizeprice +
        this.electprice +
        this.durationprice +
        this.distributionprice +
        this.licenseAmount;
      return this.totalprice;
    },
    getimage_date() {
      // console.log(this.dateOriginal);
      if (this.dateOriginal) {
        let name = this.month[this.dateOriginal.slice(5, 7)];
        let day = this.dateOriginal.slice(8, 10);
        let year = this.dateOriginal.slice(0, 4);
        let hour = this.dateOriginal.slice(11, 13);
        let minutes = this.dateOriginal.slice(14, 16);
        let seconds = this.dateOriginal.slice(17, 19);
        let ampm = hour <= 12 ? "PM" : "AM";
        // function addZero(i) {
        //   if (i < 10) {i = "0" + i}
        //   return i;
        this.image_date =
          name +
          " " +
          day +
          ", " +
          year +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds +
          " " +
          ampm;
      }
      // console.log(this.image_date);
    },
    savedlicense() {
      // this.cartcount = this.$store.state.savedlicenses;
      return this.$store.state.savedlicenses;
    },
    getcartcount() {
        return this.$store.dispatch("getCartCount");
    },
  
  category1DropdownData() {
      this.isLoading = true;
      this.filteredCategoryTwoData = [];
      this.filteredCategoryThreeData = [];
      this.showSecondDropdown = false;
      this.showThirdDropdown = false;
      this.selectedImageData;
      this.getCategoriesDetail.forEach((value) => {
        // console.log(value.category_id)
        //         console.log(typeof(value.category_id))
        // console.log(this.imgCat1)
        // console.log(typeof(this.imgCat1))
this.Filteredcategory1Names.push(value.name);
  // console.log(this.Filteredcategory1Names);
  //       if(value.category_id === this.imgCat1) {
  //         this.Filteredcategory1Names.push(value.name);
  // console.log(this.Filteredcategory1Names);
  //       }
      });
    },
    toggleAllDropDownEvaluation() {
      if(this.imagesize  !== null){
      this.showImagePriceBox=true;
      } else if (this.electronicRights !== null){
         this.showDurationBox = true;
      } else if (this.duration !== null) {
        this.showDistributionBox = true;
      }
    }
  },
  methods: {
    async getImageDetail() {
      if(!this.imageattrid){
        this.imageattrid = this.initialImgId;
      }
       await axios.post('http://flydigitally.in/api/gallery/single_view/'+this.imageattrid+'/'+ this.catid+'/'+this.subcatid)
    .then(response => {
      this.imgdata = response.data.data
      this.nextVal = response.data.next;
            this.prevVal = response.data.previous;
            //console.log(response.data)
    });
    },
    async deleteSavedLicense(id) {
     await axios.post('http://flydigitally.in/api/my-licenses/update/'+id).catch((error) => {
        this.state.error = error.response.status;
      })
      this.$store.dispatch("getSavedLicense");
    },
     async editSavedLicense(id) {
     await axios.post('http://flydigitally.in/api/my-licenses/delete/'+id).catch((error) => {
        this.state.error = error.response.status;
      })
      this.$store.dispatch("getSavedLicense");
    },
     resetAllDropdown() {
       this.showSecondDropdown=false;
        this.showThirdDropdown=false;
        this.showPrintRunBox = false;
        this.showImagePriceBox=false;
        this.showElectRightBox = false;
        this.showDurationBox = false;
        this.showDistributionBox = false;
    },
     convertedDate(dt) {
       //let dt='2021:10:04 15:19:17';
        let year = dt.slice(0,4)
      let month = dt.slice(5,7)
      let day = dt.slice(8,10)
       return month + '/' + day + '/' + year; 
    },
    showLicenseAmount(id) {
      this.savedlicenseIDSelected = id;
     // this.getSavedLicenseAmount;
      this.price;
      // alert(this.licenseAmount);
    },
    addtocart() {
      if (localStorage.getItem("user-info")) {
        this.userData = JSON.parse(localStorage.getItem("user-info"));
        this.userinfoId = this.userData.data.id;
   

       this.$store.state.quantity++;
        axios.post("http://flydigitally.in/api/add-to-card/add", {
          user_id: this.userinfoId,
          img_attribtes_id: this.imageattrid,
          category_id1: this.imgCat1,
          category_id2: this.imgCat2,
          category_id3: this.imgCat3,
          web_cat_id: this.web_cat_id,
          web_subcat_id: this.web_subcat_id,
          photographers_id: this.photographer_id,
          thumbnails: this.thumbails,
          print: this.printrunvalue,
          image_size: this.imgsizevalue,
          electronic_rights: this.electrightsvalue,
          duration: this.durationvalue,
          method_distribution: this.distributionvalue,
        })
        .catch((error) => {
          this.state.error = error.response.status;
           console.log(error.response.data);
          if (this.state.error === 422) {
            this.state.errormsg = "There is some problem";
          }
        });

         }
         else {
            alert("Click to Login or Register")
            toggleModal();
            this.clickToLogin = true;
          }
     // } else {               

         //this.guestcartcount += 1;
        //this.$store.state.quantity++;
        // alert(this.guestcartcount); 
        // guestid++;
        // this.guestcartitems.push( this.guestcartcount);
        // this.guestcartitems.push(this.imageattributeid);
        // this.guestcartitems.push({
        // "guestid" : Math.ceil(Math.random()*50),
        // "guestcartcount" : this.$store.state.quantity,
        // "firstDropdownValue" : this.firstDropdownValue,
        // "secondDropdownValue" : this.secondDropdownValue,
        // "thirdDropdownValue" : this.thirdDropdownValue,
        // "catOneID" : this.catOneID,
        // "catTwoID" : this.catTwoID,
        // "catThreeID" : this.catThreeID,
        // "webcatid" : this.webcatid,
        // "websubcatid" : this.websubcatid,
        // "photographer_id" : this.photographer_id,
        // "thumbails" : this.thumbails,
        // "imageattributeid" : this.imageattributeid,
        // "totalprice" : this.totalprice,
        // "printrunvalue" : this.printrunvalue,
        // "printingprice" : this.printingprice,
        // "imgsizevalue" : this.imgsizevalue,
        // "imgsizeprice" : this.imgsizeprice,
        // "electrightsvalue" : this.electrightsvalue,
        // "electprice" : this.electprice,
        // "durationvalue" : this.durationvalue,
        // "durationprice" : this.durationprice,
        // "distributionvalue" : this.distributionvalue,
        // "distributionprice" : this.distributionprice,
        // "title" : this.title})
        //   localStorage.setItem('guestcartitems', JSON.stringify(this.guestcartitems ))      }
        //   // this.$store.state.guestcartitems.push(this.guestcartitems);
        //   // console.log(this.guestcartitems);
        //   this.$store.commit('SET_GUESTITEMS', 
        //   ({
        // "guestid" : Math.ceil(Math.random()*50),
        // "guestcartcount" : this.$store.state.quantity,
        // "firstDropdownValue" : this.firstDropdownValue,
        // "secondDropdownValue" : this.secondDropdownValue,
        // "thirdDropdownValue" : this.thirdDropdownValue,
        // "catOneID" : this.catOneID,
        // "catTwoID" : this.catTwoID,
        // "catThreeID" : this.catThreeID,
        // "webcatid" : this.webcatid,
        // "websubcatid" : this.websubcatid,
        // "photographer_id" : this.photographer_id,
        // "thumbails" : this.thumbails,
        // "imageattributeid" : this.imageattributeid,
        // "totalprice" : this.totalprice,
        // "printrunvalue" : this.printrunvalue,
        // "printingprice" : this.printingprice,
        // "imgsizevalue" : this.imgsizevalue,
        // "imgsizeprice" : this.imgsizeprice,
        // "electrightsvalue" : this.electrightsvalue,
        // "electprice" : this.electprice,
        // "durationvalue" : this.durationvalue,
        // "durationprice" : this.durationprice,
        // "distributionvalue" : this.distributionvalue,
        // "distributionprice" : this.distributionprice,
        // "title" : this.title})
           //);
      
    },
    savedALicense() {
      // console.log(this.categoryid1);
      //         console.log(this.categoryid3);
      // console.log(this.categoryid3);
      if (localStorage.getItem("user-info")) {
        this.userData = JSON.parse(localStorage.getItem("user-info"));
        this.userinfoId = this.userData.data.id;
      }
      axios
        .post("http://flydigitally.in/api/my-licenses/add", {
          user_id: this.userinfoId,
          img_attr_id: this.imageattrid,
          amount: this.totalprice,
          category_id1: this.imgCat1,
          category_id2: this.imgCat2,
          category_id3: this.imgCat3,
          web_cat_id: this.web_cat_id,
          web_subcat_id: this.web_subcat_id,
          photographers_id: this.photographer_id,
          thumbnails: this.thumbails,
        })
        .catch((error) => {
          this.state.error = error.response.status;
          //  console.log(error.response.data);
          if (this.state.error === 422) {
            this.state.errormsg = "There is some problem";
          }
          this.$store.dispatch("getSavedLicense");
        });
    },
    nextImageShow() {
      this.imageattrid = this.nextVal;
      console.log(this.nextVal)
      this.$router.replace({path:'ImageDetail', 
      query: {imageid: this.nextVal,
        catName: this.catName,
       subcatName: this.web_subcat_name}
       });
       this.getImageDetail();
    // this.$store.commit("SET_RANDOMSELECTEDIMAGE");

             //this.getRandomSelectedImage;

      // console.log("nxt bt: "+ this.imageattrid);
      // if(this.getRandomSelectedImage[0].id <= 6){
      // this.imageattrid = this.imageattrid+1;
      //       console.log("nxt bt: "+ this.imageattrid);

      //       // console.log(this.$store.state.randomselectedimage[0].id);
      // this.$router.replace({path:'ImageDetail', query: {imageid: this.imageattrid,
      //  catName: this.catName,
      // subcatName: this.web_subcat_name}
      // });
      //       this.$store.state.img_attribtes_id = this.imageattrid;
      // localStorage.setItem('imageId', this.imageattrid);
      //     this.$store.dispatch("getRandomSelectedImage");
      //     this.getRandomSelectedImage;
      //           // console.log(this.getRandomSelectedImage[0].id );
      // } else {
      // this.imageattrid = 2;
      //       console.log(thiZs.$store.state.randomselectedimage[0].id);
      // this.$router.replace({path:'ImageDetail', query: {imageid: this.imageattrid,
      //  catName: this.catName,
      // subcatName: this.web_subcat_name}
      // });
      //       this.$store.state.img_attribtes_id = this.imageattrid;
      // localStorage.setItem('imageId', this.imageattrid);
      //     this.$store.dispatch("getRandomSelectedImage");
      //     this.getRandomSelectedImage;
      // }
    },
    prevImageShow() {
      this.imageattrid = this.prevVal;
      this.$router.replace({path:'ImageDetail', 
      query: {imageid: this.prevVal,
        catName: this.catName,
       subcatName: this.web_subcat_name}
       });
       this.getImageDetail();
      // if(this.imageattrid <= 2){
      //   this.imageattrid =5}
      //   else {
      //  this.imageattrid = this.imageattrid-1;
      //   }
      // this.$router.replace({path:'ImageDetail', query: {imageid: this.imageattrid,
      //  catName: this.catName,
      // subcatName: this.web_subcat_name }});
      //       this.$store.state.img_attribtes_id = this.imageattrid;
      // localStorage.setItem('imageId', this.imageattrid);
      //     this.$store.dispatch("getRandomSelectedImage");
      //     this.getRandomSelectedImage;
    },
    callcartsummary() {
      this.$router.push({ name: "CartSummary" });
    },
    firstCatID($event) {
      this.firstDropdownValue = $event.target.value;
      //console.log(this.getCategoryOne)
      this.getCategoryOne.forEach((info) => {
        if (info.category_name1 === this.firstDropdownValue) {
          this.resetAllDropdown();
           this.imgCat1 = info.category_id;
           //console.log(this.imageattrid)
          axios.post("http://flydigitally.in/api/img_dynamic/cat_two/"+this.imgCat1+'/'+this.imageattrid).then((response) => {
          if(response.data) {
            this.showSecondDropdown=true;
            this.getCategoryTwo = response.data;
            //console.log(this.getCategoryTwo);
          } 
      })
        }
      })
    },
   
    // category1DropdownSelected()
    // {
    //   this.filteredCategoryTwoData = [];
    //   this.filteredCategoryThreeData = [];
    //    this.showSecondDropdown = false;
    //   this.showThirdDropdown = false;
    //    this.selectedImageData;
    //     this.checkSecond();

    // },
    // checkSecond() {
    //   this.filteredCategoryTwoData = [];
    //   this.getSecondCategoriesDetail.forEach((info) => {
    //     if (info.category_id === this.imgCat1) {
    //         this.filteredCategoryTwoData.push(info.name2);
    //       // this.imgCat1 = info.category_id1;
    //       // this.showSecondDropdown = true;
    //       // this.showThirdDropdown = false;
    //       // this.filteredCategoryTwoData.push(info.name2);
    //       // console.log(this.imgCat1);
    //     }
    //   });
    // },
    getSecondValue($event) {
      this.secondDropdownValue = $event.target.value;
      this.getCategoryTwo.forEach((info) => {
        if (info.category_name2 === this.secondDropdownValue) {
           this.showThirdDropdown=false;
        this.showPrintRunBox = false;
        this.showImagePriceBox=false;
        this.showElectRightBox = false;
        this.showDurationBox = false;
        this.showDistributionBox = false;
          this.imgCat2 = info.category_id2;
          axios.post("http://flydigitally.in/api/img_dynamic/cat_three/"+this.imgCat1+'/'+this.imgCat2+'/'+ this.imageattrid).then((response) => {
            //console.log(response.data);
          if(response.data.length !== 0) {
            this.showThirdDropdown=true;
            this.getCategoryThree = response.data;
          } else {
            this.imgManageTable_id = info.id;
            this.getImageDetails;
          }
      })
        }
      })
    },
    checkThird() {
      this.filteredCategoryThreeData = [];
      this.getThirdCategoriesDetail.forEach((item) => {
        if (
          item.category_id1 === this.imgCat1 &&
          item.category_id2 === this.catTwoID
        ) {
          this.showThirdDropdown = true;
          this.filteredCategoryThreeData.push(item.name3);
        }
      });
    },
    getThirdValue($event) {
      alert(1)
      this.thirdDropdownValue = $event.target.value;
      this.getCategoryThree.forEach((item) => {
        if (item.category_name3 === this.thirdDropdownValue) {
        this.showPrintRunBox = false;
        this.showImagePriceBox=false;
        this.showElectRightBox = false;
        this.showDurationBox = false;
        this.showDistributionBox = false;
          this.catThreeID = item.category_id3;
          this.imgManageTable_id = item.id;
          this.getImageDetails;
          //this.calcPrintPrice;
          // if (this.print[0] !== null) {
          //   this.showPrintRunBox = true;
          // }
          // if (this.imagesize[0] !== null) {
          //   this.showImagePriceBox = true;
          // }
          // if (this.electronicRights[0] !== null) {
          //   this.showElectRightBox = true;
          // }
          // if (this.duration[0] !== null) {
          //   this.showDurationBox = true;
          // }
          // if (this.methodDistribution[0] !== null) {
          //   this.showDistributionBox = true;
          // }
        }
      });
    },
    calcPrintPrice() {
      // console.log(this.printrunvalue);
      //         console.log(this.imgsizevalue);
      // console.log(this.electrightsvalue);
      // console.log(this.durationvalue);
      this.printingprice = 0;
      this.print.forEach((value, key) => {
        if (this.printrunvalue === value)
          this.printingprice = parseInt(this.printPrice[key]);
        this.price;
              this.toggleAllDropDownEvaluation;
      });
      
    },
    calcImgSizePrice() {
      this.imgsizeprice = 0;
      this.imagesize.forEach((value, key) => {
        if (this.imgsizevalue === value) {
          this.imgsizeprice = parseInt(this.imagesizePrice[key]);
          // console.log(typeof(parseInt(this.imagesizePrice[key])));
          // console.log(this.imagesizePrice[key]);
          // console.log(this.printArr);
          this.price;
        }
      });
      this.showElectRightBox = true;
    },
    calcElecPrice() {
      this.electprice = 0;
      this.electronicRights.forEach((value, key) => {
        if ( this.electrightsvalue === value)
          this.electprice = parseInt(this.electronicRightsPrice[key]);
        // console.log(this.electprice);
        this.price;
      });
    },
    calcDurationPrice() {
      this.durationprice = 0;
      this.duration.forEach((value, key) => {
        if (this.durationvalue === value)
          this.durationprice = parseInt(this.durationPrices[key]);
        this.price;
      });
    },
    calcDistributionPrice() {
      // console.log(this.printrunvalue);
      //         console.log(this.imgsizevalue);
      // console.log(this.electrightsvalue);
      // console.log(this.distributionvalue);
      this.distributionprice = 0;
      // console.log(this.distributionvalue);
      this.methodDistribution.forEach((value, key) => {
        if (this.distributionvalue === value)
          this.distributionprice = parseInt(this.methodDistributionPrice[key]);
        this.price;
      });
    },
   
  },

};
</script>

<style scoped>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px !important;
  }
}

a{
    color:  #83878c !important;

}

.defaultClr{
  border-color: #83878c !important;
  color:  #83878c !important;
}

.defaultClr:focus{
  border-color: #83878c !important;
  color:  #83878c !important;
}

.text-start{
color: #83878c;
}

.form-check-input:checked {
    background-color: #83878c !important;
    border-color: #83878c !important; 
}
a{
    color: #83878c;

}

.save-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  right: 650px;
}

.save-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 1%;
    width: 96%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.addtocart-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}

.addtocart-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 1%;
    width: 96%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.checkout-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}

.media-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  right: -580px;
}
.media-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}
.media-btn-outline-black:hover:after, .media-btn-outline-black:focus:after {
    left: 0%;
    width: 100%;
    box-shadow: none;
}
.media-btn-outline-black:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .media-btn-outline-black, .media-btn-outline-black:focus {
  box-shadow: none;
}
.img-detail{
  width: 100%;
  height: auto;
  display: block;
}

.short-form {
  width: 100% !important;
}

.cart-icon {
  position: fixed;
  right: 13px;
  top: 20px;
  z-index: 99999;
  /* background-color: white !important;
    border: 0px !important; */
}

.cart-count {
  position: fixed;
  top: 11px;
  right: 29px;
  color: #83878c;
}

.cart-count:hover {
  color: white !important;
}
.cart {
  color: #83878c;
}
.paypal-window {
  z-index: 99999;
}

.methoddistribution {
  width: 30px;
}

.saved-license-delete {
  float: right;
  cursor: pointer;

}
.saved-license-amount {
  float: right;
    color: #83878c;

}

.next-btn{
  float: right;
  width: 30%;
}

.img-box{
  width: 97%;
  margin: 0;
  padding: 0;
}



.btn-border {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 14px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  outline: none;
}
/* .btn-border:after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 19%;
    width: 65%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
} */
.btn-border:hover:after, .btn-border:focus:after {
    left: 15%;
    width: 70%;
    box-shadow: none;
}
.btn-border:hover {
  color: #303030;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .btn-border, .btn-border:focus {
  box-shadow: none;
}
.btn-check:checked + .btn-border, .btn-check:active + .btn-border, .btn-border:active, .btn-border.active, .btn-border.dropdown-toggle.show {
  color: #303030;
  background-color: transparent;
  border-color: transparent;
}
.btn-check:checked + .btn-border:focus, .btn-check:active + .btn-border:focus, .btn-border:active:focus, .btn-border.active:focus, .btn-border.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-border:disabled, .btn-border.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.img-Title{
   font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-align: left;

  position: relative;
  border-bottom: 1px solid #83878c;
}

/* .img-Title:after {
  content: '';
  position: absolute;
  left: 0%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  width: calc(42% + 15px);
} */

.prev-button{
margin-left: 15px;
}

.next-button{
margin-right: 15px;
}
.img-detail-section{
  display: block;
  padding: 0;
  margin: 25px 0 0 0 ;
  background-color: #fff;
  z-index: 999;
}
</style>
