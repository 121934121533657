<template>
<NavBar> </NavBar>

 <div class="inner-section clearfix">
            <section class="section-media">
                <div class="container">
                    <div class="row  clearfix " >
                        <div class="col-12">
                            <div class="mediahead clearfix">Media</div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4" 
                        v-for="post in limitPostThree" :key="post.id"
                            >
                                 <div class="box clearfix">
                                  <a href="images/BNBLaunchMediaRelease.pdf" target="_blank"
                                  class=" bgcolor">
                                    <img :src="url1+post.thumbnail"  alt="" />
                                    <h3>{{post.title}}</h3>
                                    <center><a href="" class="btn btn-outline-black p-0 mb-3">ReadMore</a></center>
                                    <!-- <span class="readmore-span">ReadMore</span> -->
                                  </a>
                                   <!-- <router-link :to="{name: 'mediadetail',  params: {id: post.id}}" 
                                   class=" bgcolor "
                                    @click="shareData">
                                    <img class=" imgHover media-img" :src="url1+post.thumbnail"  alt="" />
                                    <h3>{{post.title}}</h3>
                                    <span class="readmore-span">ReadMore</span></router-link>   -->
                                 </div>
                        </div>      

                    </div>
        </div>
        <center><a href="" class="media-btn-outline-black p-0 mt-2">View More</a></center>
        <!-- <center class=" media-readmore mt--3" data-v-291f29b0="">
          <a href="/media" class="btn btn-outline-black p-0 mt-3" > View More</a></center> -->
            </section>
             </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'


export default {
components: {
    NavBar,

  }, 
  data() {
      return {
          list: [], 
          url1: 'https://bnbarts.digiinteracts.in/',
         sendList: [
           { id: ''},
         { title: ''},
         { description: ''},
         { image: ''}
         ],
         limitPostThree: [],
         id: null,
       
      }
  },
  
computed: {
  posts() {
    console.log(this.$store.state.posts)
    return this.$store.state.posts
  },
  filterPosts() {
    for(let i=0; i<3; i++)
    {
      this.limitPostThree.push(this.posts[i]);
    }

  },

},
mounted() {
    this.$store.dispatch("getPosts");
    this.filterPosts;
},
methods: {
//   shareData(id) {
//       //  this.sendList = [ id, title, image, description]
//       this.id = id;
//        this.$router.push({name: "mediadetail", params: { data: this.id}});
// },

}
}


</script>

<style scoped>



.media-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}
.media-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}
.media-btn-outline-black:hover:after, .media-btn-outline-black:focus:after {
    left: 0%;
    width: 100%;
    box-shadow: none;
}
.media-btn-outline-black:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .media-btn-outline-black, .media-btn-outline-black:focus {
  box-shadow: none;
}

.gx-6{
  --bs-gutter-x:3.5rem;
}
/* .media-img{
  height: 265px !important;

} */
.media-readmore{
  margin-bottom: -4px !important;
}

.media-readmore a {
  display: inline-block;
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c ;  
  text-decoration: none;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 1px;
}
.media-readmore a:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 10%;
  width: 80%;
  height: 1px;
  background-color: #303030 !important;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.media-readmore:hover a:after {
  left: 0;
  width: 100%;
}

.bgcolor {
  background-color: #FFFFFF !important;
}
/* .mediaboxes {
  margin-top: 5px;
  margin-bottom: 5px;
} */
/* .media-box{
  padding: 0 0 34px 0;
  background: #ffffff;
  margin: 23px 0 0 0 !important;
} */

/* h3 {
  font-family: "OldStandardTT-Regular";
  font-size: 16px;
  color: #83878c !important;
  text-align: center !important;
  display: block;
  padding: 0;
  margin: 25px 0 25px 0 !important;
  text-transform: uppercase;
} */

.mediahead:after{
    content: '';
  position: absolute;
  left: 50%;
  bottom: 3px;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(8% + 5px);
}

h3{
    text-transform: none !important;
}

.readmore-span{
  cursor: pointer;
   font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c;  
  text-decoration: none;
  position: relative;
  left: 168px;
  
}

.readmore-span:after {
  content: '';
  position: absolute;
  left: 20%;
  bottom: 0;
  width: 60%;
  height: 1px;
  bottom: -8px;
  background-color: #303030;
  transition: all ease-in-out 0.3s;
}
.readmore-span:focus:after, .readmore-span:hover:after {
  width: 70%;
  left: 15%;
}


</style>