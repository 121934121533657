import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Contactus from '../views/Contactus.vue'
import AboutView from '../views/AboutView.vue'
import Media from '../views/Media.vue'
// import Photographers from '../views/Photographers.vue'
import Photography from '../views/Photography.vue'
import Printing from '../views/Printing.vue'
import Gallery from '../views/Gallery.vue'
import Sales from '../views/ContactusForms/Sales.vue'
import PitchPhotoStory from '../views/ContactusForms/PitchPhotoStory.vue'
import Enquiry from '../views/ContactusForms/Enquiry.vue'
import Signup from '../views/Signup/Signup.vue'
import Login from '../views/Signup/Login.vue'
import MediaDetail from '../views/MediaDetail.vue'
// import LoginDialog from '../components/DefaultComponents/LoginDialog.vue'
import NotFound from '../views/NotFound.vue'
import Dashboard from '../views/Signup/Dashboard.vue'
// import Imageupload from "../views/Signup/imageupload.vue"
import VerifyEmail from "../views/Signup/VerifyEmail.vue"
import Photographers from '../views/Photographers.vue'
import GalleryImages from '../views/GalleryImages.vue'
import GallerySubImages from '../views/GallerySubImages.vue'
import ImageDetail from '../views/ImageDetail.vue'
import CartSummary from '../views/CartSummary.vue'
import Gallery2 from '../views/Gallery2.vue'
import GalleryRandomSubCat from '../views/GalleryRandomSubCat.vue'
import AllImages from '../views/AllImages.vue'
import PhotographersCat from '../views/PhotographersCat.vue'
import PhotographersSubCat from '../views/PhotographersSubCat.vue'
import PhotographerImages from '../views/PhotographerImages.vue'
import Checkout from '../views/Checkout.vue'
import PaymentCompletion from '../views/PaymentCompletion.vue'
// import Test from '../views/Test.vue'
import ShowImages from '../views/ShowImages.vue'
// import CustomSelection from '../views/CustomSelection'
import CustomSelection2 from '../views/customSelection2'
import CustomLicense from '../views/CustomLicense';
import ImageSelection from '../views/ImageSelection';


function user(to, from, next) {
  if(localStorage.getItem("user-info")) {
    next({ path: '/dashboard'});
  } else next();
}
function dashboard(to, from, next) {
  if(localStorage.getItem('user-info')) {
    next();
  } else next({ path: "/signup"} )
}

const routes = [
  {
    path: '/',
    component: HomeView,
    // beforeEnter: user,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView, 
  },
  {
    path: '/media',
    component: Media,
    name: 'media', 
  },
  { path: '/mediadetail/:id', 
    component: MediaDetail, 
    name: 'mediadetail', 
  },
  {
    path: '/photography',
    component: Photography, 
  },
  {
    path: '/photographers',
    component: Photographers, 
  },
  {
    path: '/printing',
    component: Printing, 
  },
  {
    path: '/gallery/',
    component: Gallery, 
    name: 'Gallery',
    
  },
  {
    path: '/gallery2',
    component: Gallery2, 
    name: 'Gallery2',
    
  },
  {
    path: '/showImages',
    component: ShowImages, 
    name: 'ShowImages',
    
  },
  {
    path: '/allimages',
    component: AllImages, 
    name: 'AllImages',
    
  },
  {
    path: '/galleryImages/:pid',
    component: GalleryImages,
    name: 'GalleryImages',
   
  },
  {
    path: '/gallerySubImages/:subcatid',
    component: GallerySubImages,
    name: 'GallerySubImages',
    props: true
  },
  {
    path: '/imageDetail',
    component: ImageDetail,
    name: 'ImageDetail',
    props: true
  },
  {
  path: '/GalleryRandomSubCat',
  component: GalleryRandomSubCat,
  name: 'GalleryRandomSubCat',
  props: true
},
{
  path: '/photographerwebcat',
  component: PhotographersCat,
  name: 'PhotographersCat',
  props: true
},
{
  path: '/photographersubcat',
  component: PhotographersSubCat,
  name: 'PhotographersSubCat',
  props: true
},
{
  path: '/photographermages',
  component: PhotographerImages,
  name: 'PhotographerImages',
  props: true
},
  {
    path: '/contactus',
    component: Contactus, 
  },
  {
    path: '/checkout',
    component: Checkout, 
    name: Checkout
  },
  {
    path: '/CustomSelection2',
    component: CustomSelection2, 
    name: CustomSelection2
  },
  {
    path: '/CustomLicense',
    component: CustomLicense, 
    name: CustomLicense
  },
  {
    path: '/ImageSelection',
    component: ImageSelection, 
    name: ImageSelection
  },
  // { path: '/test', component: Test,  },

  { path: '/sales', component: Sales,  },
  { path: '/pitchphotostory', component: PitchPhotoStory,  },
  { path: '/enquiry', component: Enquiry,  },
  { path: '/login', component: Login, name: 'Login'  },
  { path: '/signup', component: Signup,  },
  { path: '/verifyemail', component: VerifyEmail, name: 'verifyemail' },
  { path: '/CartSummary', component: CartSummary, name: 'CartSummary'},
  { path: '/dashboard', component: Dashboard, name: 'dashboard', 
      beforeEnter: dashboard,  
},

{ path: '/paymentconfirmation/:orderId', component: PaymentCompletion, name: 'PaymentCompletion'},

  
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },

]



const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior() {
    window.scrollTo(0,0);
  }
});


export default router
