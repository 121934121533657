<template>
<NavBar />

 <div class="inner-section clearfix" >
            <section class="gallery-section">
                <div class="container" >
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="galleryTitle">Gallery</div>
                        </div>
                    </div>
                    <Search/>

                    <div class="row clearfix">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                        v-for="imgesData in allimagesdata"  :key="imgesData.id" >
                        <div  >
                            <div class="category clearfix" >
                                    <div class="image-holder" @click="judgementCall(imgesData.web_cat_id,imgesData.web_cat_name)" >
                                        <img  :src="url+imgesData.thumbnail" alt="image goes here" />
                                        <span>{{imgesData.web_cat_name}}</span>
                                    </div>
                                </div>
                                </div>
                             <!-- <router-link @click="judgementCall" :to="{name: 'GalleryRandomSubCat',
                             query: { catid: data.web_cat_id,
                             catName: data.web_cat_name}}"
                            >
                                <div class="category clearfix" >
                                    <div class="image-holder">
                                        <img  :src="url+data.image" alt="image goes here" />
                                        <span>{{data.web_cat_name}}</span>
                                    </div>
                                </div>
                                  </router-link> -->
                        </div>
                        <div class="col-12">
                            <center ><a href="#" 
                            class="btn btn-outline-black view-all -xl">View all</a></center>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'
import Search from '../components/DefaultComponents/Search.vue'
import axios from 'axios';

export default {
components: {
    NavBar,
    Search
  },
  data() {
      return {
        // url: 'https://bnbarts.com/dev/public/',
        url: 'https://bnbarts.digiinteracts.in/public/',
        catid: 0,
        status: null,
        catName: '',
      }
  },
  computed: {
      allimagesdata() {
            // console.log(this.$store.state.gallerydata)
      return this.$store.state.gallerydata;
  },  
//   getPhotographersSubCategories() {
//     console.log(this.$store.state.photographersSubCategories)
//     return this.$store.state.photographersSubCategories;
//   }
  },
   methods: {
   async judgementCall(id, catName) {
        this.catid = id;
        this.catName = catName;
        //console.log(this.catid);
        //this.$store.dispatch("getPhotographersSubCategories");
        await axios.post("https://bnbarts.digiinteracts.in/api/gallery/web_sub_cat_one/"+this.catid).then(response => this.status = response.data);
        this.status === 1 ? this.$router.push({ name: 'AllImages', query: { catIDforImage: this.catid}}) : 
        this.$router.push({ name: 'GalleryRandomSubCat', query: { catid: this.catid, catName: catName}})

    }
  } ,
    mounted() {
            this.$store.dispatch("getGalleryData");
            this.allimagesdata;
    },
    
}
</script>

<style>

.gallery-section{
     display: block;
  padding: 7px 0;
  margin: 0;
  background-color: #fff;
  z-index: 999;
}
.image-holder img{
    max-height: 225px;
    object-fit: cover;
    cursor: pointer;
}

.web-cat-name{
    color: #83878C 
}

.galleryTitle {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878C;
  display: block;
  margin: 0;
  padding: 4px 0 28px 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.galleryTitle:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 29px;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(11% + 7px);
}

.view-all{
    margin: 10px auto 15px auto;
}

.search-box{
    justify-content: center;
}
</style>