<template>
<NavBar />
            <div class="inner-section">
            <section class="bg-f8f9f9 printing-section  clearfix">
                <div class="container-fluid">
                    <div class="row mb-3 clearfix">
                        <div class="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-7 col-xxl-7 mobile">
                            <img src="images/printing/printing-1.jpg" class="img-full box-shadow" alt="Image goes here" />
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6 col-xl-6 col-xxl-6">
                            <div class="mediabox digital-printing-space">
                                <div class="digital-printing">Digital Printing</div>
                                <p class="printing-box">Our Printing program offers unique online prints at our archive – such as greeting and 
                                  post cards, calendars, annual magazines, and books – from Afghanistan to audiences in the country 
                                  and worldwide who are willing to support Afghan photography and feel attached to the country in one way or another. Click here to see our archive.
</p>
                                <!-- <center><a href="/" class="gallery-btn-outline-black">Gallery</a></center> -->
                            </div>
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6 desktop">
                            <img src="images/printing/printing-1.jpg" class="img-print-right box-shadow" alt="Image goes here" />
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6">
                            <img src="images/printing/printing-2.jpg" class="img-print-left box-shadow" alt="Image goes here" />
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6 col-xl-6 col-xxl-6">
                            <div class="mediabox large-format-space">
                                <div class="large-format">Large Format</div>
                                <p class="printing-box">Our soon-to-be-launched Printing Lab in Afghanistan, furthermore,
                                   provides a professional and promising printing platform that offers full-fledged and 
                                   high-quality archiving level photos for you from framing to installation – depending on your application and preference. 
                    For more information or request of an offer, click here.
Get a print or a visual souvenir from Afghanistan and a look at our unique Printing Lab.
</p>
                                <!-- <center><a href="/" class="quot-btn-outline-black">For Quotation</a></center> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>

</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
components: {
    NavBar
  },
  data() {
      return {
          url1: 'https://bnbarts.com/public/',
      }
  },

}
</script>

<style scoped>

.view-height{
  height: 100vh;
}
.digital-printing-space{
  padding: 20px 0;
}
.large-format-space{
  padding: 13px 0;
}
p.printing-box  {
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c !important;
  line-height: 24px;
  display: block;
  margin: 20px 0;
  padding: 0 45px;
  text-align: justify;
}

.printing-section{
  padding: 35px 0 
}
.printingTitle {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878C;
  display: block;
  margin: 0;
  padding: 30px 0 5px 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.printingTitle:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 10px;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(13% + 5px);
}
.first-para{
    font-family: "OldStandardTT-Regular";
  font-size: 16px;
  color: #83878c !important;
  text-align: justify;
    color: #303030;
    margin-bottom: -14px;
}
.img-animation {
    width: 100%;
    height: 33vh;
    display: block;
    object-fit: cover !important;
}
.printing-inner-section{
    display: block;
  padding: 30px;
  margin: 0;
  border: 23px solid #fff;
  border-top: 0;
}

.printing-right-content {
  margin: 16px;
  padding: 4px;
  width: 98%;
  font-family: "OldStandardTT-Regular";
  font-size: 16px;
  color: #83878c;
  text-align: justify;
  line-height: 30px;
  padding-bottom: 12px !important;
}
.click-here{
 font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c;  
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.click-here:after{
 content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(81% + 11px);
  color: #83878c;
}

.gallery-btn-outline-black{
position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}


.gallery-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 12%;
    width: 76%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}
.gallery-btn-outline-black:hover:after, .gallery-btn-outline-black:focus:after {
    left: 0%;
    width: 100%;
    box-shadow: none;
}
.gallery-btn-outline-black:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .gallery-btn-outline-black, .gallery-btn-outline-black:focus {
  box-shadow: none;
}

.printing-box {
  display: block;
  margin: 0;
  padding: 0 15px ;
}

.quot-btn-outline-black{
position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}


.quot-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 10%;
    width: 80%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}
.quot-btn-outline-black:hover:after, .quot-btn-outline-black:focus:after {
    left: 0%;
    width: 100%;
    box-shadow: none;
}
.quot-btn-outline-black:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .quot-btn-outline-black, .quot-btn-outline-black:focus {
  box-shadow: none;
}
</style>