<template>
          <SearchedImages :title='title'></SearchedImages>

  <div  @click="toggleSearch" class="search-icon">
    <i class="fa fa-search"></i>
  </div>
  <div v-show="searchBox" class="container">
    <div class="row clearfix">
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-6">
        <div
          class="input-group border-1 mb-4 flex-display"
          :class="!showDateBox"
        >
          <span
            class="input-group-text border-0 bg-transparent"
            id="basic-addon1"
          >
            <i class="fa fa-search"></i
          ></span>

          <input
            type="text"
            class="form-control"
            aria-label="user-text"
            aria-describedby="button-addon2"
            v-model="defaultSearchText"
          />
          <!-- <span
            v-if="showDateBox"
            class="input-group-text p-0 d-flex"
            id="basic-addon1"
          >
            <div class="d-flex">
              <span class="input-group-text border-0 bg-transparent">
                From:</span
              ><input
                type="date"
                class="form-control"
                placeholder=""
                aria-label="user-text"
                aria-describedby="button-addon2"
              />
              <span class="input-group-text border-0 bg-transparent"> To:</span
              ><input
                type="date"
                class="form-control"
                placeholder=""
                aria-label="user-text"
                aria-describedby="button-addon2"
              />
            </div>
          </span> -->

          <span class="input-group-text advanced-search ps-3 pe-3 ">
            <a
              class="border-0 bg-transparent"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              @click="advancedSearch"
              >Advanced Search
            </a>
          </span>

          

          <!-- <span class="input-group-text p-0">
     <select class="form-select border-0 bg-transparent " aria-label="By Events"
      @change="getSearchOptionData">
  <option selected value="Events">By Events</option>
  <option  value="Date">By Date</option>
  <option value="Location">By Location</option>
  <option value="Photographer Name">By Photographer Name</option>
</select>
     </span> -->
          <button
            class="btn btn-theme me-0 border-radius-0 search-btn"
            type="button"
            id="button-addon2"
            @click="searchData"
          >
            Search
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-3"></div>
    </div>
  </div>
  <div
          
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <!--search form -->
                   <button
                    type="button"
                    class="btn-close float-end "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                      <div>
                        <div class="row clearfix">
                          <div class="col-12">
                            <h5 class="text-center mb-4 ad-search-title">Advanced Search</h5>
                            <div class="contact-box clearfix">
                              <form
                                method="post"
                                id="search"
                                action=""
                                role="form"
                              >
                                <div class="row clearfix">
                                  <div
                                    class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                  >
                                    <div class="mb-3">
                                      <input
                                        type="text"
                                        name="keywords"
                                        id="keywords"
                                        class="form-control"
                                        placeholder="Keywords"
                                        v-model="keywords"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                  >
                                    <div class="mb-3">
                                      <input
                                        type="text"
                                        name="photographerName"
                                        id="photographerName"
                                        class="form-control"
                                        placeholder="Photographer"
                                         v-model="photographerName"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                                  >
                                    <div class="mb-3">
                                      <div class="input-group">
                                      <input
                                        id="City"
                                        type="text"
                                         class="form-control"
                                        placeholder="City"
                                        name="City"
                                         v-model="city"
                                      />
                                      <span class="input-group-text bg-transparent border-0"></span>
                                       <input
                                        id="Province"
                                         class="form-control"
                                        type="text"
                                        placeholder="Province"
                                        name="Province"
                                         v-model="province"
                                      />
                                      </div>

                                     
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                  >
                                    <div class="mb-3">
                                      <select
                                        class="form-control"
                                        name="country"
                                        id="country"
                                        v-model="country" 
                                      >
                                        <option value="">
                                          - Select Country -
                                        </option>
                                        <option
                                        v-for="item in countryList"
                                                    :key="item.id">
                                                     {{item.country}}
                                                     </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                  >
                                    <div class="mb-3">
                                      <select
                                        class="form-control border-0 "
                                        v-model="categories"
                                      >
                                        <option  value="" >
                                          Select Categories
                                        </option>
                                        <option value="Portrait" >Magazine</option>
                                        <option value="Location">
                                          Newspaper
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                   <div
                                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                                  >
                                    <div class="mb-3">
                                       <div class="d-flex">
              <span class="input-group-text border-0 bg-transparent">
                From:</span
              ><input
                type="date"
                class="form-control"
                placeholder=""
                aria-label="user-text"
                aria-describedby="button-addon2"
                 v-model="fromDate"
              />
              <span class="input-group-text border-0 bg-transparent"> To:</span
              ><input
                type="date"
                class="form-control"
                placeholder=""
                aria-label="user-text"
                aria-describedby="button-addon2"
                 v-model="toDate"
              />
            </div>
                          <p v-show="noTxtEnteredFlag" class="noTxtEntered" >Enter something to search</p>

                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="modal-footer">
                   <div class="col-12">
                    <div class="float-end mb-2">
                   <input
                    type="submit"
                    data-bs-dismiss="modal"
                    value="Cancel"
                    class="me-3 text-center"
                  />&nbsp;&nbsp;
                   <input type="submit" @click="clearfields" name="clear" id="clear" 
                                            value="Clear" class="me-3 " />&nbsp;&nbsp;
                                            <input  @click="searchData" class="" type="submit" 
                                            data-bs-dismiss="modal"
                                            name="search" id="search" 
                                            value="Search" />
                                            </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

</template>

<script>
let countries = require('../../data/countries.json');
import axios from 'axios';
import SearchedImages from '../../views/SearchedImages.vue' 

export default {
  component: {
    SearchedImages
  },
  provide() {
    return {
      title: 'naisha'
    }
  },
  data() {
    return {
      searchBox: false,
      openLocationInputBox: false,
      showDateBox: false,
      eventName: "",
      defaultText: "Enter the ",
      defaultEventName: "Enter the Events",
      keywords: '',
      countryList: countries,
      photographerName: '',
      city: '',
      province: '',
      country: '',
      categories: '',
      defaultSearchText: '',
      searchedData: [],
      fromDate: '',
      toDate: '',
      fromDateConverted: '',
      toDateConverted: '',
      noTxtEnteredFlag: false,
    };
  },
  mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      this.defaultSearchTxt = parseInt(urlParams.get("imgSearchTxt"));
      

  },
  methods: {
    clearfields() {
      this.keywords='',
      this.city= '',
      this.province= '',
      this.photographerName = ''
    },  
    advancedSearch() {
      this.noTxtEnteredFlag=false;
    },
    toggleSearch() {
      this.searchBox = !this.searchBox;
    },
    // getSearchOptionData(event) {
    //   if (event.target.value === "Date") {
    //     // this.showDateBox = true;
    //     this.modalFlag= true;
    //   } else {
    //     // this.showDateBox = false;
    //     this.modalFlag= false;
    //     this.eventName = event.target.value;
    //   }
    // },
    async searchData() {
      //console.log(this.fromDate)
       if(this.fromDate){
      this.fromDateConverted =  this.conversionDate(this.fromDate);
      } else if(this.toDate) {
        this.toDateConverted = this.conversionDate(this.toDate);
      }
      this.keywords = this.keywords ? this.keywords : '';
      this.photographerName = this.photographerName ? this.photographerName : ''; 
      this.city = this.city ? this.city : ''
      this.province = this.province ? this.province : ''
      this.country = this.country ? this.country : ''
      this.categories=  this.categories ? this.categories : ''
      this.fromDateConverted = this.fromDateConverted ? this.fromDateConverted : ''
      this.toDateConverted = this.toDateConverted ? this.toDateConverted : ''
      
      if(this.defaultSearchText || this.defaultSearchTxt){
        let result= [];
        await axios.post('http://flydigitally.in/api/search/'+this.defaultSearchText+'/'+'0/0/0/0/0/0/0').
        then(response => (result = response))
        if(result.status === false){
          alert("not")
        } else {
          this.searchedData = result.data;
          this.$store.commit('SET_SEARCHDATA',this.searchedData )
        }
         
         //console.log(this.searchedData);
         this.$store.state.searchedDataImages = this.searchedData;
         this.showImages();
         //console.log(this.$store.state.searchedDataImages);

      }
      else if ( this.keywords || this.photographerName || this.city || this.province || this.categories || this.fromDateConverted || this.toDateConverted)
      {
              await axios.post('http://flydigitally.in/api/search/'+this.keywords+'/'+this.photographerName+'/'+this.city+'/'+this.province+'/'+this.country+'/'+this.categories+'/'+this.fromDateConverted+'/'+this.toDateConverted).
              then(response => (this.searchedData = response.data));
                // console.log(this.searchedData);
                 this.$store.state.searchedDataImages = this.searchedData;
         this.showImages();
      } else {
                this.modalFlag= true;
          this.noTxtEnteredFlag=true;
      }
            


    },
    conversionDate(dt){
 const date = new Date(dt);
      const timestampInMs = date.getTime();
      return Math.floor(date.getTime() / 1000);

    },
    showImages() {
      
//this.showDateBox=true;
      return this.$router.push({ path: "/showImages", query: {imgSearchTxt: this.defaultSearchText} });

    }
  },

}; 
</script>

<style scoped>
.noTxtEntered{
  color: red;
font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 14px;
}

input[type="submit"] {

    line-height: 26px !important;
    border: none;
}
.btn-close{
color: #83878c !important;
}
.btn-close:hover, .btn-close:focus{
color: #83878c !important;
opacity: 1;
}

.btn {padding: 0 .75rem;}

.box-shadow-none{
  box-shadow: none;
}
span {
  color: #83878c;
}
.search-btn {
  color: #fff;
  background-color: #83878c !important;
  border: none !important;
}
.search-icon {
  position: fixed;
  top: 12%;
  right: 10px;
  padding: 12px;
  background-color: #83878c;
  color: #fff;
  text-align: center;
  display: block;
  cursor: pointer;
  z-index: 999999;
}

.w-60 {
  width: 60% !important;
}

.w-10 {
  width: 10% !important;
}

.flex-display {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.ad-search-title {
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 18px;
  color: #83878c;
  text-align: center;
  display: block;
  margin: -3px 0 15px 0;
  text-transform: uppercase;
  position: relative;
}


.ad-search-title:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -3px;
  display: block;
  border-bottom: 1px solid #83878c;
  transform: translateX(-50%);
  width: calc(28% + 19px);
}



.advanced-search a {
  font-size: 14px;
  color: #83878c;
  text-decoration: none;
  cursor: pointer;
}

h5{
  color: #83878c;
}
.border-radius-0{
  border-radius: 0;
}
@media (min-width: 576px) {
.modal-dialog {
    max-width: 700px;
}
}
</style>
