<template>
  <NavBar />
  <div class="inner-section clearfix">
    <section class="all-images-section">
      <div class="container">
        <div class="row clearfix">
          <div class="col-12">
            <h2 class="allimages-heading">Education Interrupted </h2>
                                <Search />
            <p class="allimages-ptag">
             When the Taliban regained power in Afghanistan on August 15, 2021, they devastated the country’s school system—one that had yet to recover fully from previous conflicts. With the changes Taliban made to the education system completing an education remains a distant dream for many – especially for girls and children in rural areas. A recent UNICEF report estimates that 3.7 million children are unable to attend school in Afghanistan, and sixty percent of this number are girls.
The Taliban’s heavy restrictions on education, especially for girls, combined with the severe economic situation and political tensions has seriously worsened the situation for children in Afghanistan. Children may be kept from school over fear for their safety, or they may need to work to help feed the family. 

The lack of teachers (particularly female) or proper equipment and facilities, and a lack of updated curriculum further hinder education in Afghanistan. 

            </p>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-12">
            <div class="allImages-heading">{{ web_subcat_name }}</div>
          </div>
        </div>
        <div class="row clearfix">
          <div
            class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
            v-for="data in allrandomimages"
            :key="data.img_attribtes_id"
          >
            <router-link
              :to="{
                name: 'ImageDetail',
                query: {
                  imageid: data.img_attribtes_id,
                  catName: catName,
                  subcatName: subcatName,
                  subcatid: subcatid,
                  catid: this.catid
                },
              }"
            >
              <div class="box cat-box clearfix">

                <img :src="url + data.thumbnails" alt="" />

                <div class="cat-box-details">
                <h6 class="title-h6">{{data.title}}</h6>
                <h6 >ID: {{ data.image_id_name}}{{data.image_id }}</h6>
                 <!-- <h6 class="imgid-h6">{{convertedDate(data.date_time_original)}}</h6> -->
                <!-- <span class="photographer_name ms-auto">By Eva Bayle</span -->
                </div>
              </div>
              <!-- <div class="category clearfix" >
                                    <div class="image-holder">
                                        <img :src="url+data.thumbnails" 
                                        alt="image goes here" />
                                        <span>{{data.title}}</span>
                                    </div>
                                </div> -->
            </router-link>
          </div>
          <!-- <div class="col-12">
                            <center><a href="#" 
                            class="btn btn-outline-black btn -xl">
                            View all Categories</a></center>
                        </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from "../components/DefaultComponents/NavBar.vue";
import Search from '../components/DefaultComponents/Search.vue'

export default {
  props: ["id"],
  components: {
    NavBar,
    Search
  },
  data() {
    return {
      allimages: [],
      // url: "https://bnbarts.com/dev/public/",
      url: 'https://bnbarts.digiinteracts.in/public/',
      subcatid: 0,
      web_subcat_name: "",
      catName: "",
      subcatid: '',
    };
  },
  computed: {
    allrandomimages() {
       console.log(this.$store.state.allrandomimages);

      // console.log(this.$store.state.noSubCatallrandomimages);
      return this.$store.state.allrandomimages.length === 0 ? 
      this.$store.state.nosubcatallrandomimages:
      this.$store.state.allrandomimages;
    },
  
    },
    // allimagesdata() {
    //   return this.photographersdata.forEach((value) => {
    //     if(value.web_subcat_id === parseInt(this.subcatid)){
    //     this.allimages.push(value);
    //       console.log(this.allimages);
    //     }
    //   })
    // }
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.catid = urlParams.get("catid");
    this.subcatName = urlParams.get("subcatName");
    this.catName = urlParams.get("catName");
    this.subcatid = urlParams.get("subcatid");
console.log(this.subcatid);
//this.allimagesdata;
        this.$store.dispatch("allrandomimages");
        this.$store.dispatch("noSubCatallrandomimages");
        //this.convertedDate;
  },
  methods: {
      convertedDate(dt) {
        console.log(dt)
       //let dt='2021:10:04 15:19:17';
        let year = dt.slice(0,4)
      let month = dt.slice(5,7)
      let day = dt.slice(8,10)
       return month + '/' + day + '/' + year; 
    }
  
  }
};
</script>

<style scoped>
.title-h6{
  margin-top: 10px;
}
h6{
  line-height: 1.3;
}
.all-images-section {
  
  display: block;
  padding: 10px 0;
  margin: 0;
  background-color: #fff;
  z-index: 999;
}

.allimages-ptag {
    font-family: "OldStandardTT-Regular";
  margin: -5px 17px 30px 17px;
  text-align: left;
  color: #83878c;

}

.allimages-heading {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: 0 0 15px 0;
  padding: 0;
  text-align: center;
  position: relative;
}

.allimages-heading:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(23% + 5px);
}

.cat-box {
  width: 100%;
}

.cat-box img {
  width: 100%;
  height: 200px !important;
  object-fit: contain !important;
  object-position: bottom left;
}

.cat-box h3 {
  display: inline;
  text-align: left;
  margin: 0 !important;
  padding: 5px 0;
  font-family: "OldStandardTT-Regular", Arial, Helvetica, sans-serif;
  font-size: 13px;
  min-height: auto;
  text-transform: capitalize;
  color: #83879c;
}

.cat-box h4 {
  font-size: 12px;
  color: #83879c;
}

.cat-box h6 {
  display: flex;
  font-family: "Arial";
  font-size: 12px;
  color: #83879c;
}

.photographer_name {
  font-family: "OldStandardTT-Regular";
  color: #83878c;
  font-size: 14px;;
}

.cat-box-details{
  display: block;
  background: #fff;
  margin: 0;
  padding: 5px;
}
</style>
