<template>
   <NavBar> </NavBar>
    <div class="customSelection-box">
       <div class="col-12">
  <div class="customSelectionTitle mb-5 clearfix">Custom Selection</div>
    </div>
<div class="usageTypeContainer">
  
<div class="title"> Usage Period</div>
    
<div class="oneTime">
  <input class="form-check-input " @click="onetimeclicked" v-model="oneTimeUsage" 
  type="radio" name="usage" id="usage" value="usage">
  <label class="form-check-label labelSpace" for="onetime">One time Use</label>
  </div>
   <div class="multipleTimes">
  <input class="form-check-input" @click="multipleUsage" type="radio" name="usage" 
  id="usage" value="usage">
  <label class="form-check-label labelSpace" for="multiple">Multiple times usage</label>
  </div>
  <div v-show="showMultiUsageOptions" class="col-12 col-lg-4" >

      <select @change="checkMultiUsageStatus($event)" class="form-select" id="autoSizingSelect" v-model="multieUsageSelection">
    <option value=""> Choose duration</option>
      <option value="6 to 12 Months">6 to 12 Months</option>
      <option value="1 to 2 Years">1 to 2 Years</option>
      <option value="2 to 3 Years">2 to 3 Years</option>
      <option value="3 to 5 Year">3 to 5 Years</option>
      <option value="5 to 10 Years">5 to 10 Years</option>
    </select>
    </div>
</div>
<!-- <div class="listing">

       
            <div v-if="locations.length>0" class="usageShowing"> 
         Usage selected: <span >{{selectedUsageType.toString()}}</span>
          </div>
          </div> -->
         
    <div v-show="multieUsageSelection" class="SizeLocationUsageType-container " >
      <div class="size-box">
        <div v-show="multieUsageSelection" > 
         Sizes selected: <span >{{sizeSelected.toString()}}</span>
          </div>
      <div  >
        <div class="title">Sizes:</div>
        </div>
        
        <div v-show="multieUsageSelection"
         class="sizesDropdown">
        <DropdownCheckbox :options="sizes" @checked="storeSizes" ></DropdownCheckbox>
        </div>
        </div>

         <div class="locationShowing" >
             <div v-if="sizeSelected.length>0" > 
         Location selected: <span >{{locations.toString()}}</span>
          </div>
 <div v-if="sizeSelected.length>0" class="location-box" >
        <div class="title">Locations:</div>
        </div>
        <div v-if="sizeSelected.length>0" class="locationDropdown">
        <DropdownCheckbox :options="locationNames" @checked="storeLocations"></DropdownCheckbox>
        </div>

         </div>
     
        
        <div class="usageTypeShowing" v-if="locations.length>0" >
        <div class="usageType">Usage Type:</div>
        
        <div v-if="locations.length>0" class="usageTypeDropdown" >
        <DropdownCheckbox :options="usageType" @checked="storeUsageType">
        </DropdownCheckbox>
        </div>
        </div>
      
      </div>
    </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'
import DropdownCheckbox from '../components/DefaultComponents/DropdownCheckbox.vue'
export default {
  components: {
    NavBar,
    DropdownCheckbox
  },
  data() {
     return {
      showMultiUsageOptions: false,
      sizeSelected: [],
      multieUsageSelection: '',
      oneTimeUsage:'',
      locations: [],
    selected: [],
    selectedUsageType: [],
    usageType: [
      {
        text: 'Digital Online',
        value: 'Digital Online'
      },
      {
        text: 'Digital Offline',
        value: 'Digital Offline'
      },
      {
        text: 'Print',
        value: 'Print'
      }
    ],
    sizes: [{text: 'Low Res'},{text: 'Medium Res'}, {text:'High Res'}, {text:'Original'}],
    locationNames: [{text: 'Afghan Local'}, {text: 'International'}],
    ngo: false,
    ngoOptions: '',
    digitalOnline: [
      {
        text: 'Nonprofit NGO'
      },
      {
        text: 'Annual Report'
      },
      {
        text: 'Magazine'
      },
      {
        text: 'Newspaper'
      },
      {
        text: 'TV'
      },
      {
        text: 'Radio'
      },
      {
        text: 'Book'
      },
      {
        text: 'Exhibitions'
      },
      {
        text: 'documentriesO'
      },
      {
        text: 'Multimedia'
      },
      {
        text: 'Educational'
      },
      {
        text: 'Research'
      },
      {
        text: 'Presentation'
      },
    ],
    annual: false,
    magzine: false,
    newspaper: false,
    tv: false,
    radio: false,
    books: false,
    annualOptions: '',
magzineOptions: '',
newspaperOptions: '',
tvOptions: '',
radioOptions: '',
bookOptions: '',
singleSize: '',

  }
  },
  methods: {
     multipleUsage() {
      this.showMultiUsageOptions = true;
    },
    onetimeclicked() {
      this.showMultiUsageOptions = false;

    },
    checkMultiUsageStatus($event){
      this.multieUsageSelection = $event.target.value;
    },
    storeSizes(val, show){
     this.sizeSelected = val;
    this.$emit('checked', !show)
    },
    storeLocations(val){
      this.locations= val;
    },
    storeUsageType(val) {
      this.selectedUsageType= val;
    }
  }
}
</script>

<style scoped>
*{
  color: #83878c;
}
.size-box{
  width:30%; 
  float:left; 
  border:1px solid transparent;
}

.usageTypeShowing{
 width:30%; 
 float:left; 
 border:1px solid transparent;
}
.locationShowing{
 width:30%; 
 float:left; 
 border:1px solid transparent;
}

.listing{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  font-size: 12px;
}

span{
  background-color: #fff;
}

.customSelectionTitle{
font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.usageType{
  padding-right:20px;
}
.customSelection-box{
  background-color: #f8f9f9;
  display: block;
  margin: 0;
  padding: 30px;
  box-shadow: 1px 0 6px rgba(0, 0, 0, 0.03);
}

.usageTypeContainer{
  border: 1px solid rgb(227, 222, 222);
  display: grid;
  padding: 20px;
  grid-template-columns: 20% 20% 20% 20%;

}

.labelSpace{
padding-left: 20px;
}

.SizeLocationUsageType-container{
  padding: 25px 20px;
border: 1px solid rgb(227, 222, 222);
width: 100%;
      overflow: auto; 

}

.usageTypeDropdown{
  padding-left: 22px;
}
</style>