<template>
<NavBar> </NavBar>

  <div class="inner-section clearfix" >
    <section class="all-images-section">
      <div class="container">
        <div class="row clearfix">
          <div class="col-12">
            <h2 class="allimages-heading">API Images</h2>
                                <Search />
            <p class="allimages-ptag">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              debitis enim omnis animi porro expedita. Pariatur nesciunt
              explicabo cum officiis nemo, magni distinctio, reiciendis quae
              saepe aliquam fugit corporis maiores? Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Ipsa debitis enim omnis animi porro
              expedita. Pariatur nesciunt explicabo cum officiis nemo, magni
              distinctio, reiciendis quae saepe aliquam fugit corporis maiores?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              debitis enim omnis animi porro expedita. Pariatur nesciunt
              explicabo cum officiis nemo, magni distinctio, reiciendis quae
              saepe aliquam fugit corporis maiores?
            </p>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-12">
            <div class="allImages-heading"></div>
          </div>
        </div>
        <div class="row clearfix">
          <div
            class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
          >
            <router-link
              
            >
              <div class="box cat-box clearfix">
                <img  alt="" />
                <div class="cat-box-details">
                <h3></h3>

                <h6>

                </h6>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
  components: {
    NavBar
  },
data() {
  return {
          url: "http://flydigitally.in/",

  }
},
computed: {
  searchedImages(){
    console.log(this.$store.state.searchedDataImages);
    return this.$store.state.searchedDataImages;
  }

},
mounted() {
  //  const urlParams = new URLSearchParams(window.location.search);
  //   this.imagesData = urlParams.get("data");
  alert(1);
this.searchedImages;
}
}
</script>

<style>

</style>