<template>
<NavBar />

  <div class="inner-section clearfix">
            <section>
                <div class="container">
                    <div class="row clearfix">
                        <div class=" col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3"
                         v-for="item in photographers" :key="item.id">
                            <figure>
                                 <!-- @click="shareData"<router-link :to="{name: 'Gallery',  params: {id: item.id}}"  -->
                                    <router-link :to="{name: 'PhotographersCat',  query: {id: item.id}}" 
                                       clrouter-linkss="btn btn-outline-black p-0">
                                <a href="#">{{item.name}}<img :src="url1+item.image"  alt="Image goes here" /></a>
                                <figcaption >
                                     <p v-html="item.description.slice(0,60)"></p> 
                                    More
                                   
                                </figcaption>
                                 </router-link> 
                            </figure>
                        </div>
                        <!-- <div class="col-12">
                            <center><a href="#" class="btn btn-outline-black btn -xl">View all Categories</a></center>
                        </div> -->
                    </div>
                </div>
            </section>
  </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
components: {
    NavBar
  },
  data() {
      return {
          url1: 'https://bnbarts.com/dev/public/',
          id: 0,
          allcatId: [],
          allphotographerdata: [],
          alldata: [],
          filterCatid: [],
          sendData: [],
           sendList: {
               id: 0,
               cid: 0,
           },
            url: 'https://bnbarts.com/dev/public/'


      }
  },
  mounted() {
          
// axios.post("https://bnbarts.com/api/photo_management/view").then((resp) => {
//         this.alldata = resp.data;  
//         // console.log(this.alldata)      ;
//       }); 
    //   axios.post("https://bnbarts.com/api/image_attributes/view").then((resp) => {
    //     this.allphotographerdata = resp.data;
    //     this.foundMatchedCategoryData;
    //   }); 
    this.$store.dispatch("getPhotographers");
    this.$store.dispatch("getPhotographersData");
    // this.$store.dispatch("getPhotographersData");
},
  computed: {
      foundMatchedCategoryData() {
            // console.log(this.allphotographerdata);
        //   this.allphotographerdata.map(function(value, key) {allcatId.push(value);});
          return  this.allphotographerdata.forEach((value,index) => {
            //   console.log(value.web_cat_id)
              if(value.photographers_id === 3){
                    this.photographerdata.push(value.web_cat_id); 
                    // this.photographerdata.id.push(value.id);
              }
            //  console.log(this.photographerdata);
             });
      },
  photographers() {
    return this.$store.state.photographers
  },
  photographersdata() {
            // console.log(this.$store.state.photographerdata)
      return this.$store.state.photographersdata
  },
   sendData() {
      return this.photographersdata.forEach((value, index) => {
          if(value.photographerdata.photographers_id === this.id) {
              this.sendData.push(value.web_cat_id);
          }
      });
  }
//   photographersData() {
//     //   console.log(this.$store.state.photographersData);
//       return this.$store.state.photographersdata
//   },
//   alldata() {
//       return  this.photographersData.filter(data => data.photographers_id === 3);
//     }
},
methods: {
    shareData(id) {
        this.id = id;
        // console.log(id);
        // console.log(this.id);
        this.sendData;
    //     this.id = id;
    //             // console.log("share event"+  this.photographerdata);
    //             // this.id = id;
    //             console.log(this.sendList);
    //    this.$router.push({name: "Gallery", params:  {gallery: this.id }});
    //    this.$router.push({name: "PhotographersData", 
    //    params:  {PhotographersData: this.id }});
},
}       

}
</script>

<style>

</style>