<template>
    <div class="modal-wrapper" @click="close">
  <transition name="modal-animation" >
    <div v-show="modalActive" class="modal"  >
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner"   @click.stop="">
          <!--Modal content -->
          <div class="login clearfix login-box" style="background-color: #fff;">
           <img src="/images/bnb-logo.png" alt="Image goes here" />
<button type="button" class="btn-close" data-bs-dismiss="modal"
 aria-label="Close" @click="close"></button>   
       <div class="login-box clearfix">
          <div class="login-title">Login</div>
        <form @submit.prevent="submitForm" method="post"
         action="" id="loginForm" role="form">
                    <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" 
                    v-model.trim="email"
                    placeholder="name@example.com"
                    required>
                    <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating mb-3">
                <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
                                    v-model.trim="password" required>
                <label for="floatingPassword">Password</label>
            </div>
          <div class="checkbox mb-3">
            <label for="rememberme">
                <input type="checkbox" id="rememberme" name="rememberme" />
                Remember me
                </label>
            </div>
            <span>{{errorMsg}}</span>
             <center>
         <input type="submit" name="submit" id="submit" value="LOGIN" class="btn btn-outline-black btn-lg" />
        </center>
        </form>
</div>
<p>Don't have an Account? <router-link to="/signup">Sign Up</router-link></p>
      <!-- <p>Don't have an Account? 
          <button  @click="showModel = !showModel"> 
          <router-link 
       to="/signup">Sign Up</router-link> </button>
       </p> -->
</div>
          <!-- <slot /> -->
        </div>
      </transition>
      </div>
  </transition>
        </div>
</template>

<script>

import axios from 'axios';

export default {
props: [ "modalActive"],
setup(props, {emit}) {
    const close = () => {
      emit('close');
    };
    return {close};
},
data() {
        return {
            showModel: true,
            email: '',
            password: '',
            errMsg: '',
            userLoggedIn: false,
            hideModal: "false",
            userData: [
              {id: ''},
              {first_name: ''},
              { last_name: ''},
              { phone: ''},
              { email: ''},
              { company_type: ''},
              { company_name: ''},
              { country: ''},
              { work_email: ''},
              { work_phone: ''}
            ],
            errorMsg: '',
        }
    },
 methods: {
        scrollToTop() {
    window.scrollTo(0,0);
  },
  async submitForm() {
  //   try {
  //     const res = await fetch("https://bnbarts.com/public/api/user/login");
  //     if (!res.ok) {
  //       const message = `An error has occured: ${res.status} - ${res.statusText}`;
  //       throw new Error(message);
  //     }
  //     const data = await res.json();
  //     const result = {
  //       status: res.status + "-" + res.statusText,
  //       headers: {
  //         "Content-Type": res.headers.get("Content-Type"),
  //         "Content-Length": res.headers.get("Content-Length"),
  //       },
  //       length: res.headers.get("Content-Length"),
  //       data: data,
  //     };
  //     this.getResult = this.fortmatResponse(result);
  //   } catch (err) {
  //     this.getResult = err.messsage;
  //   }
  // },


      let result = await axios.post('http://flydigitally.in/api/user/login', {
            email: this.email,
          password: this.password
      })
      console.log(result.data);
              if(result.data.success === true)
              {
                localStorage.setItem('user-info', JSON.stringify(result.data))
                // this.userData = result.data.data;
                this.$router.push({name: 'dashboard'}); 
                console.log(result.data.message);
                // this.userData = window.localStorage.getItem("user-info");
                } else {     
                  this.errorMsg = result.data.message;
                  // this.$router.push({name: 'verifyemail'});
                  return;
        }
              }
        },
        getdata()
{
  // console.log(window.localStorage.getItem("user-info").split(","));
  this.userData = JSON.parse(window.localStorage.getItem("user-info"));
  console.log(this.userData.data.first_name);
 }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/fontawesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/solid.min.css");



.login-box {
  background-color: #fff;
  .cross {
    position: absolute;
    right: 20px;
    top: 30px;
  }
}
.modal-animation-enter-active, 
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-leave-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;

}

.modal-animation-inner-leave-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;

}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100vw;
  position: fixed;
  left: 0;
  background-color: rgba(0,0,0,0.7);

p, label, select, input {
    color: #83878c !important;
}
  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 36%;
    top: 40px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0, 0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
    // background-color: #fff;
  margin: 0px 15px;
    i {
      
      font-size: 20px;
      color: black;
      cursor: pointer;

      &:hover {
        color: crimson;
      }
    }
        button {
       position: absolute;
  top: 15px;
  right: 15px;
      }
  }

  @media (max-width: 590px){
      .modal-inner {
        width:100%;
      }
  }

}
</style>
