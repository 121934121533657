<template>
<NavBar />

   <div class="inner-section clearfix">
            <section class="pitch-story-section">
                <div>
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="pitchphotoTitle mb-3 clearfix">Pitch a Photo Story</div>
                        </div>
                        <div class="col-12">
                            <div class="contact-box clearfix">
                                <p style="text-align: justify;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                <form @submit.prevent="submitForm" method="post" action="" id="pitchstory" role="form">
                                    <div class="row clearfix">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="text" name="firstName" id="firstName" class="form-control" placeholder="First Name*" 
                                                v-model.trim="state.firstName" />
                                                <span v-if="v$.firstName.$error"> {{ v$.firstName.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="text" name="lastName" id="lastName" class="form-control" placeholder="Last Name*" 
                                                 v-model.trim="state.lastName" />
                                            <span v-if="v$.lastName.$error"> {{ v$.lastName.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="email" name="email" id="email" class="form-control" placeholder="Email*" 
                                                v-model.trim="state.email" />
                                            <span v-if="v$.email.$error"> {{ v$.email.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="phone" name="phone" id="phone" class="form-control" placeholder="Phone*" 
                                                 v-model.trim="state.phone" />
                                            <span v-if="v$.phone.$error"> {{ v$.phone.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <select name="category" id="category" class="form-select"
                                                v-model.trim="state.singleImg" >
                                                    <option value="">- For Single Images* -</option>
                                                    <option value="portrait">Portrait</option>
                                                    <option value="landscape">Landscape</option>
                                                    <option value="dailyLife">Daily Life</option>
                                                    <option value="climateChange">Climate Change</option>
                                                    <option value="socialIssues">Social Issues</option>
                                                    <option value="historicalPlaces">Historical Places</option>
                                                    <option value="events">Events</option>
                                                    <option value="celebrations">Celebrations</option>
                                                    <option value="sports">Sports</option>
                                                    <option value="education">Education</option>
                                                    <option value="awv">AWV</option>
                                                    <option value="family/photos">Family/Photos</option>
                                                    <option value="otherscategory">Others</option>
                                                </select>
                                                 <span v-if="v$.singleImg.$error"> {{ v$.singleImg.$errors[0].$message}}</span>
                                            </div>
                                            <div id="otherscategory" class="otherscategory mb-3" style="display: none;">
                                                <input type="text" name="categoryOthers" id="categoryOthers" class="form-control" placeholder="Mention here*"  />
                                            </div>                                            
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <select name="stories" id="stories" class="form-select"
                                                v-model.trim="state.stories" >
                                                    <option value="">- For Stories* -</option>
                                                    <option value="portrait">Portrait</option>
                                                    <option value="landscape">Landscape</option>
                                                    <option value="dailyLife">Daily Life</option>
                                                    <option value="climateChange">Climate Change</option>
                                                    <option value="socialIssues">Social Issues</option>
                                                    <option value="historicalPlaces">Historical Places</option>
                                                    <option value="events">Events</option>
                                                    <option value="celebrations">Celebrations</option>
                                                    <option value="sports">Sports</option>
                                                    <option value="education">Education</option>
                                                    <option value="awv">AWV</option>
                                                    <option value="family/photos">Family/Photos</option>
                                                    <option value="othersstories">Others</option>
                                                </select>
                                                 <span v-if="v$.stories.$error"> {{ v$.stories.$errors[0].$message}}</span>
                                            </div>
                                            <div id="othersstories" class="othersstories mb-3" style="display: none;">
                                                <input type="text" name="storiesOthers" id="storiesOthers" class="form-control" placeholder="Mention here*"  />
                                            </div>                                            
                                        </div>                                        
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <textarea class="form-control" name="photoInformation" id="photoInformation" placeholder="Information about Photo/Photo's(must be within 250 words)" 
                                                v-model.trim="state.info" 
                                                rows="4"></textarea>
                                                <span v-if="v$.info.$error"> {{ v$.info.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <input type="file" name="photos" id="photos" class="form-control" 
                                                 @change="handleImage"
                                                /> 
                                                <!-- <button @click="upload" >Upload</button> 
                                                @change="onFileChange($event)"
                                                -->
                                                <small class="float-start">Attach images must be in jpgs or pdf files and not greater than 50MB in size.</small>
                                                 <span v-if="showErrorImg"> {{emptyImgMsg}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <div class="mb-3">
                                                <div class="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="declaration" id="terms"
                                                          /> 
                                                        Terms & Privacy Policy
                                                    </label>
                                                </div>
                                                    <span v-if="termsErrMsg" >Select to confirm</span>
                                                <div class="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="declaration" id="privacy" 
                                                          /> 
                                                        By submitting this you agree with our Terms of usage and Privacy Policy
                                                    </label>
                                                </div>
                                                    <span v-if="privacyErrMsg" >Select to confirm</span>
                                                <div class="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="declaration" id="privacy" 
                                                          /> 
                                                        By submitting this your confirm that you have the permission from people in the photos for their photos to be publish and use both in prints and online
                                                    </label>
                                                </div>
                                                    <span v-if="confirmErrMsg" >Select to confirm</span>
                                            </div>
                                        </div>
                                        <div class="col-12 justify-content-center">
                                            <center><input type="submit" name="submit" id="submit" value="SUBMIT" /></center>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                
            </section>
            <Dialog :show="showDialog"
          :ok="ok"
          title="Sent"
          description="Your Query Is Sent" />
   </div>

</template>

<script>
import "../../assets/css/dialog.css"
import NavBar from "../../components/DefaultComponents/NavBar.vue"
import Dialog from "../../components/DefaultComponents/Dialog.vue"
import useValidate from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import axios from 'axios';

export default {
    setup() {
        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            singleImg: '',
            stories: '',
            info: ''
        })
        const rules = computed(() => {
            return {
                firstName: { 
                    required: helpers.withMessage(
                        'First Name must not be empty', required
                        ),
                    },
                lastName: { required: helpers.withMessage(
                        'Last Name must not be empty', required
                        ), },
                email: { required: helpers.withMessage(
                        'Email must not be empty', required
                        ), email},
                phone: { required: helpers.withMessage(
                        'Phone must not be empty', required
                        ), numeric},
                singleImg: { required: helpers.withMessage(
                        'Single Image field must not be empty', required
                        ), },
                stories: { required: helpers.withMessage(
                        'Stories field must not be empty', required
                        ), },
                info: { required: helpers.withMessage(
                        'Info field must not be empty', required
                        ), },
                 terms: { required: helpers.withMessage(
                        'Terms and Conditions is not clicked', required
                        ), },
            }
        })
        const v$ = useValidate(rules, state)
        return {
            state, 
            v$,
        }
    },
    components: {
        NavBar,
        Dialog
    },
    data() {
        return {
             selectedImgFile: null,
              showDialog: false,
            showErrorImg: false,
            emptyImgMsg: 'Image is not selected.',
            sizeOverLimit: 'Image is more than 50MB',
            uploadData: {
                image: '',
            },
            image: '',
            remoteUrl: '',
             privacyErrMsg: false,
            termsErrMsg: false,
            confirmErrMsg: false,
    }
 },
 methods: {
     submitForm() {

          this.v$.$validate();
           if(this.selectedImgFile === null )
       {
            this.showErrorImg = true;
       } else { return ;}     
            if(!this.v$.$error) {
             this.postdata();
            this.showDialog = true;

            } else {
                 this.privacyErrMsg=true;
            this.termsErrMsg=true;
            this.confirmErrMsg = true;
        //    this.showFailedDialog = true;
       }
       if(this.selectedImgFile === null )
       {
            this.showErrorImg = true;
       } else { return ;}     
     },
    //  onFileChange(event) {
    //      this.selectedImgFile = event.target.files[0];
    //      let sizeImg = this.selectedImgFile.size;
        //  console.log(this.selectedImgFile);

        //  if( sizeImg >50000000)
        //  {
        //      alert( 'Image size is more than 50 mb.');
        //  } else {
 

    //image base 64
                 handleImage(e){
                    const selectedImage = e.target.files[0];
                    this.createBase64Image(selectedImage);
                    console.log(selectedImage.size);
                },
                createBase64Image(fileObject) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                        this.postdata(this.image);
                    };
                    reader.readAsDataURL(fileObject);
                },
                // uploadImage() {
                //     const { image } = this;
                //     axios.post('https://bnbarts.com/api/contact/pitch_photo_story/add',
                //     { image })
                //     .then((response) => {
                //         this.remoteUrl = response.data.url;
                //     })
                //     .catch((err) => {
                //         return new Error(err.message);
                //     })
                //     },
      postdata() {
          const { image } = this;
        let data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            single_image: this.state.singleImg,
            stories: this.state.stories,
            message: this.state.info,
            image: image ,
        };
         axios.post('http://flydigitally.in/api/contact/pitch_photo_story/add',
        data
        ).then(function(){console.log('SUCCESS!!'); })
        .catch(function(){console.log('FAILURE!!');});
        },

        ok() {
            this.showDialog = false;
        },
    },
 }


</script>

<style scoped>
span {
    color: red;
    font-family: inherit;
    font-size: 12px;
    display: block;

}

button {
  cursor: pointer;
  padding: 0;
  line-height: inherit;
  color: inherit;
  display: block;
}

.btn {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
p, label, small, select, input {
    color: #83878c !important;
}

#result {
    font-size: 20px;
    font-family: inherit;
}

#result p{
    box-sizing: border-box;
    color: black;
    font-size: 20px;
}

.pitch-story-section{
    display: block;
    padding: 20px 0 0 0 ;
    margin: 0;
    background-color: #fff;
    z-index: 999;
}
</style>