<template>
  <NavBar />
  <b-card title="Card title" sub-title="Card subtitle">
    <b-card-text>
      Please verify your email before login.
    </b-card-text>
  </b-card>
</template>

<script>
import NavBar from "../../components/DefaultComponents/NavBar.vue"

export default {
components: {
  NavBar
}
}
</script>

<style>

</style>