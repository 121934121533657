<template>
<nav class="navbar navbar-light bg-light sticky-top mobile">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"
        ><img src="/images/f-logo.png" alt="Image goes here" /></a>     
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <a class="navbar-brand" href="/"
            ><img src="/images/f-logo.png" alt="Image goes here" /></a>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">About Us</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/photography" class="nav-link"
              >Photography</router-link
            >
          </li>
          <!-- <li class="nav-item"><router-link to="/photographers"    class="nav-link ">Photographers</router-link></li> -->
          <li class="nav-item">
            <router-link to="/Printing" class="nav-link">Printing</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/gallery2" class="nav-link">Gallery</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/media" class="nav-link">media</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contactus" class="nav-link"
              >Contact Us</router-link
            >
          </li>
          <!-- <li class="nav-item"><router-link to="/signup"   @click="$scrollToTop" class="nav-link ">SignUp</router-link></li>   -->
          <li class="nav-item">
            <Login @close="toggleModal" :modalActive="modalActive"></Login>
            <a v-show="!isLoggedIn" @click="toggleModal" class="nav-link"
              >Login</a
            >
          </li>
          <!-- <Login v-on:hideLogin="logoutShow($event)"/> -->
          <!-- <li   class="nav-item">
            <button v-show="isLoggedIn"  class="nav-link ">Logout</button>
          </li>  -->
          <li class="nav-item dropdown " v-show="isLoggedIn">
            <a
              class="nav-link dropdown-toggle"
              @click="callShow"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="getProfileImage ? url + getProfileImage : defaultimg"
                class="img-fluid rounded-circle me-1 d-inline-block"
                style="height: 20px"
                alt="Images goes here"
              />
              {{ name }}
            </a>
            <ul
              class="dropdown-menu"
              :class="{ show: callDropdownFlag }"
              aria-labelledby="navbarDropdown"
            >
              <li><a class="dropdown-item" href="/dashboard">Dashboard</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item logout" @click="logout">LogOut</a>
              </li>
            </ul>
          </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <nav class="navbar navbar-expand-lg navbar-light sticky-top desktop" ref="main">
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img src="/images/f-logo.png" alt="Image goes here" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                    @click="toggleClass">
                    <span class="navbar-toggler-icon"
                    ></span>
                </button>
                <div  class="collapse navbar-collapse" :class="hide" id="navbarNav"
                  >
                    <ul class="navbar-nav auto"  >
                        <li class="nav-item">
                                 <router-link to="/" class="nav-link ">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/about"  class="nav-link ">About Us</router-link>
                        </li> 
                        <li class="nav-item">
                                <router-link to="/photography"    class="nav-link ">Photography</router-link>
                        </li>
                         <!-- <li class="nav-item">
                                <router-link to="/photographers"    class="nav-link ">Photographers</router-link>
                        </li> -->
                        <li class="nav-item">
                                 <router-link to="/Printing"  class="nav-link ">Printing</router-link>
                        </li>
                        <li class="nav-item">
                                <router-link to="/gallery2" class="nav-link ">Gallery</router-link>
                        </li>
                        <li class="nav-item">
                                <router-link to="/media"   class="nav-link ">media</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/contactus"    class="nav-link ">Contact Us</router-link>
                        </li>
                        <!-- <li class="nav-item">
                                <router-link to="/signup"   @click="$scrollToTop" class="nav-link ">SignUp</router-link>
                        </li>   -->
                        <li  class="nav-item"> 
                          <Login @close="toggleModal" :modalActive="modalActive">
                          </Login>
                          <a v-show="!isLoggedIn" @click="toggleModal" 
                          class="nav-link " >Login</a>
                        </li> 
                        <!-- <Login v-on:hideLogin="logoutShow($event)"/> -->
                        
                        <!-- <li   class="nav-item">
                              <button v-show="isLoggedIn"  class="nav-link ">Logout</button>
                        </li>  -->
                      
                         <!-- <li class="nav-item dropdown"  v-show="isLoggedIn">
                            <a class="nav-link  " @click="callShow"
                             href="#" id="navbarDropdown" 
                            role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img :src="getProfileImage ? url+getProfileImage : defaultimg" 
                              class="img-fluid rounded-circle me-1 d-inline-block" 
                              style="height: 20px;" alt="Images goes here" /> {{name}}
                            </a>
                            <ul class="dropdown-menu" :class="{show: callDropdownFlag }" 
                                aria-labelledby="navbarDropdown">
                              <li><a class="dropdown-item" href="/dashboard">Dashboard</a></li>
                              <li><hr class="dropdown-divider"></li>
                              <li><a class="dropdown-item logout"  @click="logout">LogOut</a></li>
                            </ul>
                        </li> -->
                       <li>
                       </li>
                        <router-link v-show="count" :to="{ name: 'CartSummary' }" 
                        class="btn btn-outline-black cart-icon border-0 cart-box " 
                        @click="callcartsummary">
                       <div class="cart-count">{{count}}</div>
                      Selection
                        </router-link>
                    </ul>
                    <form>
                        <div v-show="isLoggedIn" class="dropdown">
                            <button class="media-btn-outline-black p-0 mt-2 dropdown-toggle" type="button" id="dropdownMenuButton1" 
                            data-bs-toggle="dropdown" aria-expanded="false"
                            @click="callShow"
                            style="color: #83878c;"
                            >
                            <img :src="getProfileImage ? url+getProfileImage : defaultimg" 
                              class="img-fluid rounded-circle me-1 d-inline-block" 
                              style="height: 20px;" alt="Images goes here" /> 
                               {{name}}
                            </button>
                            <ul class="dropdown-menu" :class="{show: callDropdownFlag }" 
                                aria-labelledby="navbarDropdown"
                                >
                              <li><a class="dropdown-item" href="/dashboard">Dashboard</a></li>
                              <li><hr class="dropdown-divider"></li>
                              <li><a class="dropdown-item logout"  @click="logout">LogOut</a></li>
                            </ul>
                        </div>
                    </form>
                    <router-link v-if="showCart" :to="{ name: 'CartSummary' }" >
                    <span class=" cart-count  btn-lg btn-block">CART   
                     
                     <span class="badge bg-secondary ms-2">
                    {{ count  }} 
              </span>
            </span>
            </router-link>
                </div>
            </div>
        </nav>
</template>

<script>

import Login from '../../views/Signup/Login.vue'
import { ref} from 'vue'



export default {
  components: {
    Login,
  },
   setup() {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    return {modalActive,toggleModal};
  },
    data() {
        return {
            showModel: true,
            email: '',
            password: '',
            errMsg: '',
            isLoggedIn: 'false',
            name: '',
            userData: [],
            callDropdownFlag: false,
            getName: '',
            cartcount: 0,
            url: 'http://flydigitally.in/',
            defaultimg: '/images/user.jpg',
            showCart: false,
            toggleResponsive: true,
        }
    },
    methods: {
        scrollToTop() {
    window.scrollTo(0,0);
  },
 
  logout() {
          localStorage.clear();
          this.userLoggedIn = false;
          this.$store.state.quantity =0;
          this.$store.state.guestcartitems =0;
          localStorage.removeItem("vuex.guestcartitems");
      this.$router.push({path: '/contactus'});
  },   
  callShow() {
      this.callDropdownFlag = !this.callDropdownFlag;
    },   
  },
  computed: {
    toggleClass() {
      return hide ? 'collapse navbar-collapse hide' : 'collapse navbar-collapse';
    },
      getProfileImage() {
        // this.profileimg = this.$store.state.profileimage;
        //console.log("navbar " +this.url+ this.$store.state.profileimage);
      return this.$store.state.profileimage;
     },
    count() {
       console.log("cart:"+this.$store.state.quantity);
      // console.log("count is bad")
                //  this.quantity = this.$store.state.quantity;
return this.$store.state.quantity;
//this.$store.getters.cartLength;
      // return this.$store.state.quantity;
    },

   isLoggedIn() {
      // console.log(this.isLoggedIn);
      if(localStorage.getItem("user-info")) {
      this.userData = JSON.parse(window.localStorage.getItem("user-info"));
        this.name = this.userData.data.first_name;
      } else {
        console.log("local storage not found");
      }
    
    // console.log(this.userData.data.first_name);
    return JSON.parse(window.localStorage.getItem("user-info"));
  },
  // cartitems() {
  //   // console.log(this.$store.state.cartitems);
  //     return this.$store.state.cartitems;
  //   },  
    // quantity() {
    //   // console.log("navbar quantity"+ this.$store.state.quantity);
    //   return this.$store.state.quantity;
    // }   
},
mounted() {
  // this.$store.dispatch("getCartItems");
  this.$store.dispatch("getProfileImage");
      // this.$store.dispatch("getCartCount");
  // this.$store.dispatch("getCartCount");
this.cartitems;
  this.count;
  this.quantity;
  this.profileimage;
  this.getProfileImage;
  
},
beforeMount() {
  this.profileimg = this.$store.state.profileimage;
  // console.log("navbar"+ this.profileimg);
  // console.log("navbar"+ this.$store.state.profileimage);
}

}
</script>

<style lang="scss" scoped>
a{
  color: #83878c;
}
.home {
  background-color: rgba(0,176,234,0.5);
  display: flex;
  justify-content: center;
  align-content: center;

  .modal-content {
    display: flex;
    flex-direction: column;

    h1, p {
      margin-bottom: 16px;
    }
    h1 {
        font-size: 32px;
    }
    p {
      font-size: 18px;
    }

  }
}
.error-msg {
    color: red;
    font-family: inherit;
    font-size: 12px;
}

.cart-count{
    display: inline-block;
    font-family: "GoodWeb-Bold",Arial Narrow Bold,Arial Narrow,Trebuchet,Arial,sans-serif!important;
    font-size: 14px;
    text-align: center;

}
// .cart-box {
//     position: fixed;
//     top: 30px;
//     right: 10px;
//     background-color: grey;
//     background: transparent;
// }

.cart {
    color: #616161;
    background-color: white !important;
}

.logout{
  cursor: pointer;
}

.auto {
  margin: 0px auto;
}




</style>
