<template>

<NavBar />
<div class="inner-section clearfix">
  <section >
                <div class="container">
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="aboutTitle mt-3 mb-3">Bahara & Bahman (BNB) Trading</div>                        
                        </div>
                        <div class="col-12">
                            <div class="right-content">
                                Established in 2019 as a private venture to support the growth of photography in Afghanistan,
the BNB Arts model offers Afghan photographers financial opportunities and exposure on the international stage. 

Afghanistan has, too often, been presented from a foreign and male perspective. With the creation of BNB Arts, the world will now be able to access images of Afghanistan captured through Afghan eyes, from a local and more balanced gender perspective.

BNB Arts currently offers high-quality photography services, at reasonable rates, to local and international individuals and businesses. Our services include a stock photo gallery, event coverage, and photography assignments. For more information go to Services We Offer.  

For those seeking quality photography, check out the BNB gallery today to find images of Afghanistan—captured through the eyes of an Afghan photographer. 

Contact us today for more information about how BNB Arts can help you. 

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-f8f9f9 about-page-section clearfix" id="down">
                <div class="container">
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="about-bnbMedia mb-5">BNB in Media</div>                        
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="card clearfix">
                                <img src="http://bnbarts.com/dev/images/media-img.jpg" class="img-fluid w-100" alt="Image goes here" />
                                <div class="card-body">
                                    <h5>Photography in Light</h5>
                                    <p>Duis mollis, est non commodo luctus nis nisi erat porttitor ligula, eget dent lacinia on odio sem nec elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="card clearfix">
                                <img src="http://bnbarts.com/dev/images/media-img.jpg" class="img-fluid w-100" alt="Image goes here" />
                                <div class="card-body">
                                    <h5>Photography in Light</h5>
                                    <p>Duis mollis, est non commodo luctus nis nisi erat porttitor ligula, eget dent lacinia on odio sem nec
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="card clearfix">
                                <img src="http://bnbarts.com/dev/images/media-img.jpg" class="img-fluid w-100" alt="Image goes here" />
                                <div class="card-body">
                                    <h5>Photography in Light</h5>
                                    <p>Duis mollis, est non commodo luctus nis nisi erat porttitor ligula, eget dent lacinia on odio sem nec
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12  ">
                            <center><router-link to="/media" class="about-view-more abt-btn-spacing ">View All</router-link></center>
                        </div>
                    </div>
                </div>
            </section>
</div>

</template>

<script>
// import Footer from '../components/DefaultComponents/Footer.vue'
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
  components: {
    NavBar
  }
}
</script>

<style scoped>


.aboutTitle:after{
     content: '';
  position: absolute;
  left: 50%;
  /* width: 52%; */
  height: 1px;
  background-color: #83878C !important;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.aboutPage-readmore a {
  display: inline-block;
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878C !important;  
  text-decoration: none;
  position: relative;
  cursor: pointer;
  margin-bottom: 38px;
  padding-top: 50px;
}
.aboutPage-readmore a:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 10%;
  width: 80%;
  height: 1px;
  background-color: #83878C !important;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.aboutPage-readmore:hover a:after {
  left: 0;
  width: 100%;
}


.abt-btn-spacing{

margin: 45px 0 0 0;

}

.about-bnbMedia {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.about-bnbMedia:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(16% + 15px);
}

.about-view-more {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c ;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}

.about-page-section{
padding: 50px 0 ;
}

.about-view-more:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}
.about-view-more:hover:after, .about-view-more:focus:after {
    left: 0%;
    width: 100%;
    box-shadow: none;
}
.about-view-more:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .about-view-more, .about-view-more:focus {
  box-shadow: none;
}
</style>