<template>
	<div class="container">
		<div>
			<h2>File Progress</h2>
			<hr/>
			<label>File
				<input type="file" v-on:change="handleFileUpload( $event )"/>
			</label>
      <img :src="preview" alt="image" class="img-fluid"/>
			<br>
			<progress max="100" :value.prop="uploadPercentage"></progress>
			<br>
			<button v-on:click="submitFile()">Submit</button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';

	export default {
		data(){
			return {
				file: '',
				uploadPercentage: 0,
        preview: null,
        image: "",
			}
		},
		
		methods: {
			handleFileUpload: function( event ){
				var input = event.target;
				this.file = event.target;
				this.image = input.files[0];
        if(this.file.files) {
          	this.onUploadProgress();
						}
          let reader = new FileReader();
          reader.onload = (e) => {
            this.preview = e.target.result;
          }
        reader.readAsDataURL(this.file.files[0]);
        }
			},

onUploadProgress( progressEvent ) {
				let formData = new FormData();
				formData.append('file', this.file);

						
							this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
						}
		}
</script>