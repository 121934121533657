<template>
  <div v-show="show" class="overlay">


    <div class="dialog">

      <div class="dialog__content">
        <h2 class="dialog__title" v-text="title"></h2>
        <p class="dialog__description" v-text="description"></p>
      </div>

      <hr />

      <div class="dialog__footer">

        <button @click="ok" class="dialog__ok">OK</button>
      </div>

    </div>

  </div>
</template>

<script>
import "../../assets/css/dialog.css"
export default {
    props: ['show', 'title', 'description', 'cancel', 'ok']
}
</script>