<template>
   <footer>
                <div class="container">
                    <div class="row clearfix">
                        <div class="col-12">
                            <center><img src="/images/f-logo.png" class="f-logo" alt="Image goes here" /></center>
                            <hr />
                            <ul class="slinks clearfix">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            </ul>
                            <p class="label">
                                © BNBarts 2022<br />
                                <a href="#">Terms & Condition</a> | <a href="#">Privacy Policy</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>