<template>
<NavBar />

  <div class="inner-section clearfix">
            <section class="enquiry-section">
                <div>
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="enquiryTitle mb-3 clearfix">Enquiry</div>
                        </div>
                        <div class="col-12">
                            <div class="contact-box clearfix">
                                <form @submit.prevent="submitForm" method="post" action="" id="enquiry" role="form">
                                    <div class="row clearfix">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="text" name="firstName" id="firstName" class="form-control" placeholder="First Name*" 
                                                v-model.trim="state.firstName"  />
                                             <span v-if="v$.firstName.$error"> {{ v$.firstName.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="text" name="lastName" id="lastName" class="form-control" placeholder="Last Name*" 
                                                v-model.trim="state.lastName" />
                                                <span v-if="v$.lastName.$error"> {{ v$.lastName.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="email" name="email" id="email" class="form-control" placeholder="Email*" 
                                                v-model.trim="state.email" />
                                                <span v-if="v$.email.$error"> {{ v$.email.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="phone" name="phone" id="phone" class="form-control" placeholder="Phone*"  v-model.trim="state.phone"  />
                                                <span v-if="v$.phone.$error"> {{ v$.phone.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <select name="companyType" id="companyType" class="form-select" v-model.trim="state.companyType">
                                                    <option value="">- Select Company Type* -</option>
                                                    <option value="organization">Organization</option>
                                                    <option value="privatebussiness">Private Business</option>
                                                    <option value="magazine">Magazine</option>
                                                    <option value="newspaper">Newspaper</option>
                                                    <option value="tv">TV</option>
                                                    <option value="radio">Radio</option>
                                                    <option value="books">Books</option>
                                                    <option value="exhibitions">Exhibitions</option>
                                                    <option value="film">Film</option>
                                                    <option value="multimedia">Multimedia</option>
                                                    <option value="educational">Educational</option>
                                                    <option value="research">Research</option>
                                                    <option value="freelance/consultant">Freelance/Consultant</option>
                                                    <option value="others">Others</option>
                                                </select>
                                                 <span v-if="v$.companyType.$error"> {{ v$.companyType.$errors[0].$message}}</span>
                                            </div>                                            
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="text" name="companyName" id="companyName" placeholder="Company Name*" class="form-control"  v-model.trim="state.companyName" />
                                                <span v-if="v$.companyName.$error"> {{ v$.companyName.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <input type="text" name="subject" id="subject" class="form-control" placeholder="Subject*" v-model.trim="state.subject" />
                                                <span v-if="v$.subject.$error"> {{ v$.subject.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                            <div class="mb-3">
                                                <select class="form-select" name="country" id="country" v-model="state.country">
                                                    <option :value="item.country"
                                                     v-for="item in countryList"
                                                    :key="item">{{item.country}}</option>
                                                </select>
                                                <span v-if="v$.country.$error"> {{ v$.country.$errors[0].$message}}</span>
                                            </div>
                                        </div>                                        
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <textarea class="form-control" name="message" id="message" placeholder="Message" 
                                                v-model.trim="state.message" rows="4"></textarea>
                                                <span v-if="v$.message.$error"> {{ v$.message.$errors[0].$message}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <div class="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="declaration" id="terms"  /> 
                                                        Terms & Privacy Policy
                                                    </label>
                                                </div>
                                                    <span v-if="termsErrMsg" >Select to confirm</span>
                                                <div class="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="declaration" id="privacy"  /> 
                                                        By submitting this you agree with our Terms of usage and Privacy Policy
                                                    </label>
                                                </div>
                                                    <span v-if="privacyErrMsg" >Select to confirm</span>
                                            </div>
                                        </div>
                                        <div class="col-12 justify-content-center">
                                            <center><input type="submit" name="submit" id="submit" value="SUBMIT" /></center>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                
            </section>
             <Dialog :show="showDialog"
          :ok="ok"
          title="Sent"
          description="Your Enquiry Is Sent" />

          <!-- <Dialog :show="showFailedDialog"
          :ok="ok"
          title="Error"
          description="Error in the form" /> -->
  </div>
</template>

<script>
import "../../assets/css/dialog.css"
import NavBar from "../../components/DefaultComponents/NavBar.vue"
import Dialog from "../../components/DefaultComponents/Dialog.vue"
import useValidate from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
let countries = require('../../data/countries.json');

export default {
  
    setup() {
        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phone:'',
            companyType:'',
            companyName:'',
            country:'',
            subject: '',
            message: ''
        })
        const rules = computed(() => {
            return {
                firstName: { 
                    required: helpers.withMessage(
                        'First Name must not be empty', required
                        ),
                    },
                lastName: { required: helpers.withMessage(
                        'Last Name must not be empty', required
                        ), },
                email: { required: helpers.withMessage(
                        'Email must not be empty', required
                        ), email},
                phone: { required: helpers.withMessage(
                        'Phone must not be empty', required
                        ), },
                companyType: { required: helpers.withMessage(
                        'Company Type must not be empty', required
                        ), },
                companyName: { required: helpers.withMessage(
                        'Company Name must not be empty', required
                        ), },
                subject: { required: helpers.withMessage(
                        'Subject must not be empty', required
                        ), },
                country: { required: helpers.withMessage(
                        'Country must not be empty', required
                        ), },
                message: { required: helpers.withMessage(
                        'Message must not be empty', required
                        ), },
            };
        })
        const v$ = useValidate(rules, state)
        return {
            state, 
            v$,
        }
    },

    components: {
        NavBar,
        Dialog
    },
 data() {
        return {
            showDialog: false,
            countryList: countries,
            showSuccessDialog: false,
            showFailedDialog: false,
            termsErrMsg: false,
            privacyErrMsg: false,
}
 },
 methods: {
     submitForm(){
       this.v$.$validate();
       if(!this.v$.$error) {
             this.postdata();
            this.showDialog = true;
       } else {
           this.showFailedDialog = true;
                this.privacyErrMsg=true;
            this.termsErrMsg=true;
       }
     },

    postdata() {
        let data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            company_type: this.state.companyType,
            company_name: this.state.companyName,
            country: this.state.country,
            message: this.state.message,
            subject: this.state.subject,
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        fetch('http://flydigitally.in/api/contact/other_enquiry/add', options)
        .then(res => res.json())
        .then(res => console.log(res));
    },
    ok() {
      console.log('ok');
      this.showDialog = false;
      this.showFailedDialog = false;
    },

    },
       
 }

</script>

<style scoped>
span {
    color: red;
    font-family: inherit;
    font-size: 12px;
}

button {
  cursor: pointer;
  padding: 0;
  line-height: inherit;
  color: inherit;
}

.enquiry-section{
    display: block;
    padding: 20px 0 0 0 ;
    margin: 0;
    background-color: #fff;
    z-index: 999;
}

p, label, small, select, input {
    color: #83878c !important;
}   
.btn {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}


</style>