<template>
   <NavBar />

  <div class="inner-section clearfix">
            <section>
                <div class="container">
                    <div class="row clearfix">
                        <div class=" col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3"
                         v-for="item in photographerssubcat" :key="item.web_subcat_id">
                            <figure>
                                <router-link 
                                :to="{ name: 'PhotographerImages',
                                query: { 
                                  photographer_id: photographer_id,
                                  catid: photographer_catid,
                                  subcatid: item.web_subcat_id }}">
                                {{item.web_subcat_name}}
                                  <img :src="url+item.web_subcat_thumbails"  
                                  alt="Image goes here" />
                                  </router-link>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
  </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
components: {
    NavBar
  },
  data() {
    return {
      photographer_subcat: 0,
          url: 'https://bnbarts.com/dev/public/',
photographer_catid: 0,
photographer_id: 0,
subcatid: 0,
    }
  },
  computed: {
    photographerssubcat() {
      // console.log(this.$store.state.photographerssubcat);
      return this.$store.state.photographerssubcat.data;
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.photographer_id = urlParams.get("photographer_id");
    this.photographer_catid = urlParams.get("catid");
    this.$store.state.catidclicked=this.photographer_catid;
    this.$store.dispatch("getPhotographerssubcat");
  }
}
</script>

<style>

</style>