<template>
<NavBar />
                <!-- <a class="navbar-brand" href="#"><img src="images/bnb-logo.png" alt="Image goes here" /></a> -->

            <section >
<!-- v-if="post.id===parseInt(id)" v-for="post in posts" :key="post.id"-->
   <div class="inner-section  clearfix"
    v-for="post in filterPosts" :key="post.id"
     >
            <div class="container">
                <div class="clearix" > 
                 <img class="img-fluid w-100 post-image" :src="url1+post.image" alt=""/>
                    <div class="row clearfix">
                <div class="col-12">
                    <h1 class="title">{{post.title}}</h1>
                    </div>
                    </div>
                </div>
            
            
                 <div class="container">
                    <div class="row clearfix">
                        <div class="col-12">
                            <p class="content"  v-html="post.description"></p>
                        </div>
                    </div>
                </div>
                </div>
                </div>
               </section>

</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'
// import Media from '@/views/Media.vue'
import axios from 'axios'

export default {

  components: {
    NavBar,
    // Media
  },  
  data() {
      return {
          title:'',
          id: 0,
          newData: {
              id: '',
                title: '',
                description: '',
                image: ''
            },
            url1: 'https://bnbarts.digiinteracts.in/',

      }
  },
  computed: {
      posts(){
        //   console.log(this.$store.state.posts[]);
          return this.$store.state.posts;
      },
      filterPosts(){ 
                return this.posts.filter(post => post.id === parseInt(this.id));
            }
  },
        mounted() {
            this.id=this.$route.params.id;
            // console.log(this.id);
            this.$store.dispatch('getPosts')
        }
}
</script>


<style >
/* img {
    background-size: cover;
    width: 60%;
} */
h1{
    color: black;
    padding: 15px 0;
}

.box h3{
    min-height: 60px;
}

.title{
    color: black;
}

.post-image {
    max-height: 300px ;
      object-fit: cover;
    margin-bottom: 50px;
}
</style>