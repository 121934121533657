<template>
 <NavBar />
        <div class="inner-section clearfix" >
            <section>
                <div class="container" >
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="page-heading">Categories</div>
                        </div>
                    </div>
                    <Search />
                    <div class="row clearfix">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                        v-for="data in fetchImages" :key="data.id">
                          <router-link :to="{name: 'ImageDetail',
                             query: { id: catid.id, subcatid: subcatid, photgrapherid: photgrapherid, imageid: data.id }}"
                            >
                                <div class="category clearfix" >
                                    <div class="image-holder">
                                        <img :src="url+data.image" alt="image goes here" />
                                        <span></span>
                                    </div>
                                    <p></p>
                                </div>
                                 </router-link>
                        </div>
                        <div class="col-12">
                            <center><a href="#" class="btn btn-outline-black btn -xl">View all Categories</a></center>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'
import Search from '../components/DefaultComponents/Search.vue'

export default {
// props: [ 'pid'],
components: {
    NavBar,
    Search
  },
data() {
  return {
    photgrapherid: 0,
    catid:0,
    subcatid: 0,
    url: 'https://bnbarts.com/dev/public/',
    imagesdata: [],
    allimagesData: [],
    imageId: 0,
  }
},

computed: {
  photographersdata() {
    console.log(this.$store.state.photographersdata);
      return this.$store.state.photographersdata
  },
  fetchImages() {
        return this.photographersdata.filter(id=> id.web_cat_id === parseInt(this.catid) && id.web_subcat_id === parseInt(this.subcatid));
  }
  // fetchImages() {
  //  return  this.photographersdata.forEach((value) => {
  //     if(value.photographers_id === parseInt(this.photgrapherid)) {
  //       // console.log(value.image);
  //       this.imagesData.id = value.id;
  //       this.imagesData.image = value.image;
  //       this.allimagesData.push(this.imagesData);
  //       // console.log(this.imagesData.image);
  //       // console.log("cat from imgattribute table id:"+ value.web_cat_id);
  //       console.log(this.allimagesData);
  //       // console.log("chosen cat id:"+this.catid);
  //       //  console.log("subcat from imgattribute table id:"+ value.web_subcat_id);
  //       // console.log("chosen subcat id:"+this.subcatid);
  //       //  console.log("photragpher from imgattribute table id:"+ value.photographers_id);
  //       // console.log("chosen phographer id:"+this.photgrapherid);
  //       // this.imagesData.webcatid = value.web_cat_id;
  //       // this.imagesData.websubcatid = value.web_subcat_id;
  //     //  this.imagesData.image = value.image;
  //     //  this.allimagesData.push(this.imagesData);
  //       // this.imagesData.id= value.id;
  //       // this.imagesData.image = value.image
  //       // this.allimagesData.push(this.imagesData);
  //       // console.log(this.allimagesData);
  //       // this.allimagesData = this.selectivePhographerData
  //     }
  //   })
  // }
},
mounted() {
      this.$store.dispatch("getPhotographersData");
    this.$store.dispatch("getPhotographersCategories");
    this.$store.dispatch("getPhotographersSubCategories");
     this.subcatid = this.$route.params.subcatid; 
    // this.catid = this.$route.params.catid;
//   this.photgrapherid = this.$route.params.id;
this.photographersdata;
      this.fetchImages;
   const urlParams = new URLSearchParams(window.location.search);
    this.photgrapherid = urlParams.get("id");
    this.catid = urlParams.get("catid");
    console.log("photo"+ this.photgrapherid);
    console.log("subcat"+ this.subcatid);
    console.log("cat"+this.catid);
},
methods: {
  sendPhotoId(id) {
    this.imageId = id;
    // console.log(this.imageId);
    this.$router.push({name: 'ImageDetail', params: { imageDetail: this.imageId}});
  }
}
}
</script>

<style>

.search-box{
    justify-content: center;
}
</style>