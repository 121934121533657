<template>
 <NavBar />

<div class="inner-section clearfix">
            <section class="section-gallery">
                <div class="container">
                    <div class="row gy-2 clearfix " >
                        <div class="col-12">
                            <div class="stories clearfix">{{catName}}</div>
                                                    <Search />

                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3" 
                        v-for="data in PhotographersSubCategories" :key="data.id">
                         <router-link :to="{name: 'AllImages',
                    query: { subcatid: data.web_subcat_id,
                    catName: catName,
                    subcatName: data.web_subcat_name,
                    catid: this.catid}}">
                                 <div class="box cat-box clearfix">
                                    <img :src="url+data.image"  alt="" />
                                    <h3>{{data.web_subcat_name}}</h3>
                                 </div>
                                 </router-link>
                        </div>      

                    </div>
        </div>
        <center><a href="" class="gall-random-btn-outline-black p-0 mt-2">View More</a></center>
            </section>
            </div>

        <!-- <div class="inner-section clearfix" >
            <section>
                <div class="container" >
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="page-heading">Stories</div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                       v-for="data in PhotographersSubCategories" :key="data.id">
                  <router-link :to="{name: 'AllImages',
                    query: { subcatid: data.web_subcat_id,
                    catName: catName,
                    subcatName: data.web_subcat_name,}}"
                            >
                                <div class="category clearfix" >
                                    <div class="image-holder">
                                        <img :src="url+data.image" alt="image goes here" />
                                        <span>{{data.web_subcat_name}}</span>
                                    </div>
                                </div>
                  </router-link>
                        </div>
                        <div class="col-12">
                            <center><a href="#" class="btn btn-outline-black btn -xl">View all</a></center>
                        </div>
                    </div>
                </div>
            </section>
        </div> -->
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'
import Search from '../components/DefaultComponents/Search.vue'

export default {
components: {
    NavBar,
    Search
  },
data() {
  return {
    photgrapherid: 0,
    catid:0,
    subcatid: 0,
    // url: 'https://bnbarts.com/dev/public/',
    url: 'https://bnbarts.digiinteracts.in/public/',
    imagesdata: [],
    allimagesData: [],
    imageId: 0,
    catName: '',

  }
},

computed: {
  PhotographersSubCategories() {
    console.log(this.$store.state.photographersSubCategories)
    return this.$store.state.photographersSubCategories
  //  return this.$store.state.photographersSubCategories !== 1 ? 
  //  this.$store.state.photographersSubCategories :
  //  this.$router.push({ name: 'AllImages', query: { catIDforImage: this.catid}})

  },
 
  // fetchImages() {
  //   console.log(this.PhotographersSubCategories.filter(id=>id.web_cat_id === parseInt(this.catid)));
  //   return this.PhotographersSubCategories.filter(id=>id.web_cat_id === parseInt(this.catid));
  // }
  
},   


mounted() {
       const urlParams = new URLSearchParams(window.location.search);
    this.catid = urlParams.get("catid");
        this.catName = urlParams.get("catName");
        this.$store.dispatch("getPhotographersSubCategories");
//console.log(this.catid)
      this.PhotographersSubCategories;
  //this.redirectPageJudgment;
    //console.log(this.catName);
},

}
</script>

<style scoped>
.cat-box {
    width: 100%;
}

.cat-box img{
    width:100%;
    height: 200px !important;
    object-fit: cover !important;
}


.cat-box h3{
    display: block;
    text-align: left;
    margin: 0 !important;
    padding: 17px 10px;
 font-family: "OldStandardTT-Regular", Arial, Helvetica, sans-serif;
    font-size: 16px ;
    min-height: auto;
    text-transform: capitalize;
    color: #83878c;
}


.cat-box h6 {
    display: inline;
    color: #83878c;
    float: right;
    margin: 18px auto;
    padding-right: 10px;
    font-family: "OldStandardTT-Regular", Arial, Helvetica, sans-serif;
    font-size: 14px ;
}

.stories {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878C;
  display: block;
  margin: 10px 0 25px 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.stories:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 3px;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: 11%;
}

.section-gallery {
  display: block;
padding: 17px 0 25px 0;
  margin: 0;
  background-color: #fff;
  z-index: 999;
      background: #F8F9F9;
}


.gall-random-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c ;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}


.gall-random-btn-outline-black:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}
.gall-random-btn-outline-black:hover:after, .gall-random-btn-outline-black:focus:after {
    left: 0%;
    width: 100%;
    box-shadow: none;
}
.gall-random-btn-outline-black:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
      box-shadow: none;
}
.btn-check:focus + .gall-random-btn-outline-black, .gall-random-btn-outline-black:focus {
  box-shadow: none;
}


</style>