<template >
  <div>
    <div  @click="toggleDropdown" class="dropdown" >
      <div class="overselect"></div>
      <select class="c-form-input DefaultSelectionText" style="cursor:pointer">
        <option value="" class="optiontag" @click="toggleDropdown">Please Select One</option>
      </select>
    </div>
    <div class="multiselect" v-if="show"  @click="showDropdown">
      <ul>
        <li v-for="(item, index) in options" :key="index">
          <input type="checkbox" :id="index" :value="item.text" v-model="selected">
          <label class="dropdownLabel" :for="index">{{ item.text }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

  name: 'DropdownCheckbox',
  template: '#DropdownCheckbox',
  props: ['options', 'digitalOnline', ],
  data() {
    return {
      show: false,
      selected: [],
    }
  },
  methods: {
    showDropdown() {
      this.show = true
    },
    toggleDropdown() {
      this.show = !this.show;
    }
   
  },
  watch: {
    selected(val) {
      this.$emit('checked', val, this.show)
    }
  },

}

</script>

<style  scoped >
.col {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dropdownLabel{
  padding-left: 20px;
}

/*****
- MultiSelect 
*****/

.dropdown { 
  
  

}

.multiselect {
  position: relative;
}
  ul {
   
  }

.overselect {
  
}

.DefaultSelectionText{
  margin-left: 20px;
  margin-top: 10px;
}
</style>