<template>
  <div class="col-12 col-lg-9">
    <div class="ptb-30">
      <div class="card box-shadow mb-3 clearfix">
        <div class="card-header text-uppercase">Orders Information</div>
        <OrdersSaved v-if="showLicense"></OrdersSaved>
        <div class="card-body">
          <div class="row clearfix">
            <div class="col-12 col-lg-2"
            v-for="item in getOrders" :key="item.id">
              <div class="mb-3">
                <span
                  class="form-control bg-f8f9f9"
                />{{item.order_id}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("getOrders");
  },
  computed: {
    getOrders() {
      console.log(this.$store.state.ordersData);
      return this.$store.state.ordersData;
    },
  },
};
</script>

<style></style>
