<template>
   <Multiselect
      v-model="example5.value"
      v-bind="example5"
    ></Multiselect>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  props: ['options'],
components: {
    Multiselect,
  },
  data() {
    return {
      example5: {
        mode: "tags",
        value: [""],
        closeOnSelect: false,
        options: [
      {
        label: 'Afghan Local',
        value: 'Afghan Local'
      },
      {
        label: 'International',
        value: 'International'
      },
    ],
        searchable: true,
        createOption: true,
      },
    }
  },
}
</script>

<style src="../../assets/css/default.css">
.multiselect{
  width: 60% !important;
}
</style>