<template>
  <div>
    <NavBar />
    <Login
      v-show="clickToLogin"
      @close="toggleModal"
      :modalActive="modalActive"
    >
    </Login>
    <div class="inner-section clearfix">
      <section class="img-detail-section">
        <div class="container-fluid">
          <div class="row clearfix" v-for="(i, index) in imgdata" :key="index">
            <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 col-xxl-7">
              <div class="img-box">
                <img
                  :src="url + i.thumbnails"
                  class="img-detail"
                  alt="Image goes here"
                />
                <div class="d-flex mt-2">
                  <button
                    class="btn-border btn-sm prev-button"
                    @click="prevImageShow"
                  >
                    {{ prev }}
                  </button>
                  <button
                    class="btn-border btn-sm next-button"
                    @click="nextImageShow"
                  >
                    {{ next }}
                  </button>
                  <center>
                    <a
                      href=""
                      class="media-btn-outline-black p-0 mt-2"
                      data-bs-toggle="modal"
                      data-bs-target="#purchaseCart"
                      >Selection</a
                    >
                  </center>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
              <!-- <div class="img-Title  ">{{ i.title }}

            </div> -->
              <!-- <a
              href="#"
              class="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#purchaseCart"
            >
              <i class="fa fa-download"></i> Get Price</a 
            >-->
              <p class="content">
                {{ i.caption }}
              </p>
              <p class="user-info">
                Use Information: This content is intended for editorial use
                only. For other uses, additional clearances must be requested.
              </p>
              <ul class="details clearfix">
                <li v-if="i.image_id">
                  <span class="title">ID:</span
                  ><span class="info">{{ i.image_id_name + i.image_id }}</span>
                </li>
                <li v-if="i.head_line">
                  <span class="title">Head Line:</span
                  ><span class="info">{{ i.head_line }}</span>
                </li>
                <li v-if="i.image_date">
                  <span class="title">Creation Date:</span
                  ><span class="info">{{ i.image_date }}</span>
                </li>
                <li v-if="i.photographerName">
                  <span class="title">Submission Date:</span
                  ><span class="info">{{ i.creation_Date }}</span>
                </li>
                <li v-if="i.photographerName">
                  <span class="title">Photographer:</span
                  ><span class="info">{{ i.photographerName }}</span>
                </li>
                <li v-if="i.date_time_original">
                  <span class="title">Date:</span
                  ><span class="info">
                    {{ convertedDate(i.date_time_original) }}</span
                  >
                </li>
                <li v-if="i.subject">
                  <span class="title">Subject:</span
                  ><span class="info">{{ i.subject }}</span>
                </li>
                <li v-if="i.document_title">
                  <span class="title">Document Title:</span
                  ><span class="info">{{ i.document_title }}</span>
                </li>
                <li v-if="i.copyright">
                  <span class="title">Credit:</span
                  ><span class="info">{{ i.copyright }}</span>
                </li>
                <li v-if="i.country">
                  <span class="title">Country:</span
                  ><span class="info">{{ i.country }}</span>
                </li>
                <li v-if="i.state">
                  <span class="title">Province/State:</span
                  ><span class="info">{{ i.state }}</span>
                </li>
                <li v-if="i.city">
                  <span class="title">City:</span
                  ><span class="info">{{ i.city }}</span>
                </li>

                <li v-if="i.category">
                  <span class="title">Category:</span
                  ><span class="info">{{ i.category }}</span>
                </li>
                <li v-if="i.sub_categories">
                  <span class="title">Sub Categories:</span
                  ><span class="info">{{ i.sub_categories }}</span>
                </li>

                <li v-if="i.photo_source">
                  <span class="title">Photo Source:</span
                  ><span class="info">{{ i.photo_source }}</span>
                </li>
                <li v-if="i.source">
                  <span class="title">Source:</span
                  ><span class="info"> {{ i.source }}</span>
                </li>
                <!-- <li v-if="i.caption_writer">
                <span class="title">Caption Writer:</span
                ><span class="info">{{ i.caption }}</span>
              </li> -->
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="purchaseCart"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ title }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3 clearfix">
              <div
                class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5"
                v-for="i in getRandomSelectedImage"
                :key="i.id"
              >
                <div class="topheading">
                  <p>{{ image_id }}<br />Editorial</p>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="my-license"
                  data-bs-toggle="tab"
                  data-bs-target="#myLicense"
                  type="button"
                  role="tab"
                  aria-controls="myLicense"
                  aria-selected="true"
                >
                  Selections
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="custom-license"
                  data-bs-toggle="tab"
                  data-bs-target="#customLicense"
                  type="button"
                  role="tab"
                  aria-controls="custom-license"
                  aria-selected="false"
                >
                  Custom Selection
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="myLicense"
                role="tabpanel"
                aria-labelledby="my-license"
              >
                <small v-if="isSavedLicenseExist" class="text-start"
                  >You have no saved licenses. Access licenses in Custom
                  licenses Tab.</small
                >
                <div
                  class="form-check"
                  v-for="item in getSavedLicense"
                  :key="item.id"
                >
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          @click="showLicenseAmount(item.id)"
                          @change="getidselected"
                          :v-model="item.id"
                        />

                        <label
                          class="form-check-label defaultClr"
                          for="flexRadioDefault1"
                        >
                          {{ item.category_name1 + " " + item.category_name2 }}
                        </label>
                      </div>
                      <div
                        class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                      >
                        <a
                          class="saved-license-delete"
                          @click="editSavedLicense(item.id)"
                          >Edit</a
                        >
                      </div>
                      <div
                        class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                      >
                        <a
                          class="saved-license-delete"
                          @click="deleteSavedLicense(item.id)"
                          >Delete</a
                        >
                      </div>
                      <div
                        class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                      >
                        <p class="saved-license-amount">${{ item.amount }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="customLicense"
                role="tabpanel"
                aria-labelledby="custom-license"
              >
                <p class="user-info">
                  Use Information: This content is intended for editorial use
                  only. For other uses, additional clearances must be requested.
                </p>
                <div class="row">
                  <div class="col-md-2">
                    <div class="titlepage">
                      <label for="firstDropdown">Usage Period</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Choose duration</label>
                  </div>
                  <div class="col-md-3">
                    <label @click="FNUsageTypeDropdownEvent">Usage Types</label>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-check-label me-3" for="onetime">
                      <select
                        class="form-select usage-selection"
                        @change="onetimeOpen()"
                        v-model="OneDuration"
                      >
                        <option
                          v-for="usagePeriodItems in getUsagePeriodDataByStore"
                          :key="usagePeriodItems.id"
                          :id="usagePeriodItems.id"
                          
                           :value="usagePeriodItems.id"
                        >
                          {{ usagePeriodItems.usage_period }}
                        </option>
                      </select>
                    </label>
                  </div>
                  <div class="col-md-4 mb-4">
                    <select
                      class="form-select duration-selection"
                      id="autoSizingSelect"
                      :style="{ width: durationsize }"
                      :disabled="activateMultiDurationDropdown == 0"
                      v-model="multieUsageSelection"
                    >
                      <option
                        v-for="data in getMultipleDurationByStore"
                        :key="data.id"
                        :value="data.id"
                      >
                        {{ data.duration }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 input-next">
                    <div class="btn-holder">
                      <input
                        class="inputSelection"
                        @click="FNUsageTypeDropdownEvent"
                        placeholder="Choose usage types"

                      />
                      <ul
                        v-if="showUsageTypeDropdown"
                        class="multiCheckboxSelection"
                      >
                        <li
                          v-for="type in getUsageTypeByStore"
                          :key="type.category_id"
                        >
                          <input
                            @change="usageTypeClick(type.category_id)"
                            :id="type.name"
                            type="checkbox"
                            v-model="usagetypeselection"
                            :value="type.name"
                          />
                          <label class="multiLabel" :for="type.name">
                            {{ type.name }}</label
                          >
                        </li>
                      </ul>
                      <div v-show="isUsageTypeDropdownOpen" class="btn-inline">
                        <a
                          @click="UsageTypeNxtBtn"
                          type="submit"
                          class="next-buttonModal"
                          >Close</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="titlepage">
                      <label @click="FNLocationsDropdown" for="firstDropdown"
                        >Locations</label
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label @click="FNSizesDropdown">Image size</label>
                  </div>
                </div>

                <div class="row mb-3 clearfix">
                  <div class="col-md-6 input-next">
                    <div class="btn-holder">
                      <input
                        class="inputSelection"
                        @click="FNLocationsDropdown"
                        placeholder="Select Location"
                        :value="locationSelected.toString()"
                      />
                      <ul
                        v-show="showLocationsDropdown"
                        class="multiCheckboxSelection"
                      >
                        <li
                          v-for="location in getLocationByStore"
                          :key="location.id"
                        >
                          <input
                            type="checkbox"
                            :id="location.location"
                            @change="locationClick(location.id)"
                            v-model="locationSelected"
                            :value="location.location"
                          />
                          <label class="multiLabel" :for="location.location">
                            {{ location.location }}</label
                          >
                        </li>
                      </ul>
                      <div v-show="isLocationDropdownOpen" class="btn-inline">
                        <a
                          @click="LocationNxtBtn"
                          type="submit"
                          class="next-buttonModal"
                          >Close</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 input-next mb-4">
                    <div class="btn-holder">
                      <input
                        @click="FNSizesDropdown"
                        placeholder="Select Sizes"
                        class="inputSelection"
                        :value="sizesSelected.join(', ')"
                      />

                      <ul
                        v-show="showSizesDropdown"
                        class="multiCheckboxSelection"
                      >
                        <li v-for="size in getImageSizesByStore" :key="size.id">
                          <input
                            type="checkbox"
                            :id="size.image_size"
                            v-model="sizesSelected"
                            :value="size.image_size"
                            @change="sizeClick($event,size.id )"
                          />
                          <label class="multiLabel" :for="size.image_size">
                            {{ size.image_size }}
                          </label>
                        </li>
                      </ul>
                      <div v-show="isImageSizeDropdownOpen" class="btn-inline">
                        <a
                          @click="SizeNxtBtn"
                          type="submit"
                          class="next-buttonModal"
                          >Close</a
                        >
                      </div>
                    </div>
                    <p v-if="OriginalSizeSeletedBoolean">
                      For Original Size, send email to abc@domain.com
                    </p>
                  </div>
                </div>

                <hr class="mt-4" />
                <div class="row">
                  <div class="col-md-4 mt-3" v-show="showcat1.status">
                    <label
                      @click="FNDGOFirstDropDown"
                      for="firstPlaceholderName"
                      >{{ firstPlaceholderName }}</label
                    >
                  </div>
                  <div class="col-md-4 mt-3" v-show="showcat2.status">
                    <label for="secondPlaceholderName ">{{
                      secondPlaceholderName
                    }}</label>
                  </div>
                  <div class="col-md-4 mt-3" v-show="showcat3.status">
                    <label for="thirdPlaceholderName">{{
                      thirdPlaceholderName
                    }}</label>
                  </div>
                </div>

                <div class="row mb-3 clearfix">
                  <div v-show="showcat1.status" class="col-md-4 input-next mb-4">
                    <div class="btn-holder">
                      <label @click="FNDGOFirstDropDown"></label>
                      <input
                        class="inputSelection"
                        @click="FNDGOFirstDropDown"
                        :placeholder="firstPlaceholderName"
                        :value= FirstDropdownMutatedSelected
                         v-show="showFirstInputBox"
                      />
                      <ul 
                        v-show="showFirstDropdown"
                        class="multiCheckboxSelection"
                      >
                        <li v-for="first in FirstDropdownTypes" :key="first.category_id2">
                          <input
                            @change="
                              FirstDropdownClicked(
                                first.category_id2,
                                first.category_id
                              )
                            "
                            :id="first.category_id2"
                            type="checkbox"
                            v-model="FirstDropdownSelected"
                            :value="first.name2"
                          />
                          <label class="multiLabel" :for="first.category_id2">
                            {{ first.name2 }}</label
                          >
                        </li>
                      </ul>
                      <div v-show="isFirstDropdownOpen" class="btn-inline">
                        <a
                          @click="FirstDropdownNxtBtn"
                          type="submit"
                          class="next-buttonModal"
                          >Close</a
                        >
                      </div>
                    </div>

                    <div class="row">
                      <!--dynamic html -->
                      <div
                        v-for="names in FirstDropdownCatDropdownData"
                        :key="names.category_id2"
                      >
                        <div >
                          <label class="mt-3" for="firstDropdown">{{
                            names.name2
                          }}</label>
                          <MultiSelectCheckbox
                            :id="names.category_id2"
                            :id1="names.category_id"
                            :data="tempCatAdded"
                            :placeholderText="names.name2"
                            :selectedData= "selectedData"
                          />
                        </div>
                      </div>
                    </div>
                    <p class="emailmessage" v-if="DGOfflineExhibitions">
                      For Exhibitions, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOfflineDocumentary">
                      For Documentaries, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOfflineMultimedia">
                      For Multimedia, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOfflineResearch">
                      For Research, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOfflinePresentation">
                      For Presentation, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOfflineEducational">
                      For Presentation, send email to abc@domain.com
                    </p>
                  </div>

                  <div v-show="showcat2.status" class="col-md-4 input-next">
                    <div  class="btn-holder" >
                      <label @click="FNDGOnlineDropDown"></label>
                      <input
                        class="inputSelection"
                        @click="FNDigitalOnlineDropdownEvent"
                        :placeholder="secondPlaceholderName"
                        :value="DigitalOnlineMutatedSelected"
                         v-show="showSecondInputBox"
                      />
                      <ul
                        v-show="showOnlineDropdown"
                        class="multiCheckboxSelection"
                      >
                        <li
                          v-for="online in SecondDropdownTypes"
                          :key="online.category_id2"
                        >
                          <input
                            @change="
                              DigitalOnlineClicked(
                                online.category_id2,
                                online.category_id
                              )
                            "
                             :id="online.category_id2"
                            type="checkbox"
                            v-model="DGonlineSelected"
                            :value="online.name2"
                          />
                          <label class="multiLabel" :for="online.category_id2">
                            {{ online.name2 }}</label
                          >
                        </li>
                      </ul>

                      <div v-show="isDGonlineDropdownOpen" class="btn-inline">
                        <a
                          @click="DGOnlineNxtBtn"
                          type="submit"
                          class="next-buttonModal"
                          >Close</a
                        >
                      </div>
                    </div>

                    <div class="row">
                      <div v-for="names in SecondDropDownsecondCatDropdownData" :key="names.category_id2">
                        <div>
                          <label class="mt-3" for="firstDropdown">{{
                            names.name2
                          }}</label>
                          <MultiSelectCheckbox
                            :id="names.category_id2"
                            :id1="names.category_id"
                            :data="tempCatAdded"
                            :placeholderText="names.name2"
                          />
                        </div>
                      </div>
                    </div>

                    <p class="emailmessage" v-if="DGOnlineExhibitions">
                      For Exhibitions, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOnlineDocumentary">
                      For Documentaries, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOnlineMultimedia">
                      For Multimedia, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOnlineResearch">
                      For Research, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOnlinePresentation">
                      For Presentation, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGOnlineEducational">
                      For Presentation, send email to abc@domain.com
                    </p>
                  </div>

                  <div v-show="showcat3.status" class="col-md-4 input-next">
                    <div class="btn-holder" >
                      <label></label>
                      <input
                        class="inputSelection"
                        @click="PrintingDropdownEvent"
                        :value="PrintingMutatedSelected"
                        :placeholder="thirdPlaceholderName"
                          v-show="showThirdInputBox"
                      />
                      <ul
                        v-show="showPrintingDropdown"
                        class="multiCheckboxSelection"
                      >
                        <li
                          v-for="print in ThirdDropdownTypes"
                          :key="print.category_id2"
                        >
                          <input
                            :id="print.category_id2"
                            type="checkbox"
                            v-model="PrintingSelected"
                            :value="print.name2"
                            @change="
                              printingClicked(
                                print.category_id2,
                                print.category_id
                              )
                            "
                          />
                          <label class="multiLabel" :for="print.category_id2">
                            {{ print.name2 }}</label
                          >
                        </li>
                      </ul>
                      <div v-show="isPrintDropdownOpen" class="btn-inline">
                        <a
                          @click="PrintingNxtBtn"
                          type="submit"
                          class="next-buttonModal"
                          >Close</a
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div v-for="names in ThirdDropdownCatDropdownData" :key="names.category_id2">
                        <div>
                          <label class="mt-3" for="firstDropdown">{{
                            names.name2
                          }}</label>
                          <MultiSelectCheckbox
                            :id="names.category_id2"
                            :id1="names.category_id"
                            :data="tempCatAdded"
                            :placeholderText="names.name2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row"></div>
                    <p class="emailmessage" v-if="DGprintingExhibitions">
                      For Exhibitions, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGprintingDocumentary">
                      For Documentaries, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGprintingMultimedia">
                      For Multimedia, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGprintingResearch">
                      For Research, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGprintingPresentation">
                      For Presentation, send email to abc@domain.com
                    </p>
                    <p class="emailmessage" v-if="DGprintingEducational">
                      For Presentation, send email to abc@domain.com
                    </p>
                  </div>
                </div>
              </div>

              <div class="justify-content-end mt-3 mb-3 clearfix">
                <div class="row g-3">
                  <div class="col-12">
                    <p>
                      The usage of images will be started as soon the payment is
                      made. You are buying this image(s) for the below selected
                      options:
                    </p>
                    <p class="px-1">
                      <span v-if="oneTimeUsage.length > 0">
                        <strong> Usage Period:</strong>
                        {{ oneTimeUsage }},</span
                      >
                      <span v-if="multieUsageSelection.length > 0">
                        <strong> Usage Period:</strong>
                        {{ multieUsageSelection }},</span
                      >
                      <span v-if="UsageTypeSelection">
                        <strong> Usage Type:</strong>
                        {{
                          " " + usagetypeselection.join(", ").toString()
                        }},</span
                      >
                      <span v-if="locationSelected.length > 0">
                        <strong> Location Selected:</strong
                        >{{
                          " " + locationSelected.join(", ").toString()
                        }},</span
                      >
                      <span v-if="sizesSelected.length > 0">
                        <strong> Sizes Selected:</strong
                        >{{ " " + sizesSelected.join(", ").toString() }},</span
                      >
                      <span v-if="FirstDropdownSelected.length > 0">
                        <strong> {{firstPlaceholderName}}:</strong>
                        {{
                          " " + FirstDropdownSelected.join(", ").toString()
                        }},
                        </span
                      >
                      
                      <span v-if="DGonlineSelected.length > 0">
                        <strong> {{secondPlaceholderName}}:</strong
                        >{{
                          " " + DGonlineSelected.join(", ").toString()
                        }},</span
                      >
                      <span v-if="PrintingSelected.length > 0">
                        <strong> {{thirdPlaceholderName}}: </strong
                        >{{
                          " " + PrintingSelected.join(", ").toString()
                        }},</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="imagePrice mt-3 clearfix">
              <div class="row">
                <div class="col-8 text-center">
                  This price does not include Exhibitions, Research,
                  Documentary, Multimedia and Presentation
                </div>

                <div class="col-4">Total Price ${{ totalprice }}</div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="save-btn-outline-black"
              @click="savedALicense"
            >
              Save License
            </button>

            <button
              type="button"
              class="addtocart-btn-outline-black"
              data-bs-dismiss="modal"
              @click="addtocart"
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/DefaultComponents/NavBar.vue";
import axios from "axios";
import Login from "./Signup/Login.vue";
import { ref } from "vue";
import UsageType from "../components/UI/UsageType.vue";
import Sizes from "@/components/UI/Sizes.vue";
import Location from "@/components/UI/Location.vue";
import DigitalOnline from "@/components/UI/DigitalOnline.vue";
import DigitalOffline from "@/components/UI/DigitalOffline.vue";
import Printing from "@/components/UI/Printing.vue";
import ImageSelectionText from "../Mixins/ImageSelectionText";
import MultiSelectCheckbox from "../components/DefaultComponents/MultiSelectCheckbox";
import Modal from "../components/UI/Modal";

export default {
  props: ["false"],
  components: {
    NavBar,
    Login,
    UsageType,
    Sizes,
    Location,
    DigitalOnline,
    DigitalOffline,
    Printing,
    MultiSelectCheckbox,
    Modal,
  },
  mixins: [ImageSelectionText],
  setup() {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    return { modalActive, toggleModal };
  },
  data() {
    return {
      token: '',
      usageTypeId: [],
      userId: 0,
      OneDuration: "",
      SecondDropDownsecondCatDropdownData:[],
      sizeID:[],
      locationId:0,
      ThirdDropdownCatDropdownData:[],
      showFirstInputBox: false,
      showSecondInputBox: false,
      showThirdInputBox: false,
      firstPlaceholderName: "",
      secondPlaceholderName: "",
      thirdPlaceholderName: "",
      showcat1: [],
      showcat2: [],
      showcat3: [],
      usagetypeSelectedNames: [],
      thirdCatDropdownData: [],
      FirstDropdownCatDropdownData: [],
      tempCatAdded: [],
      selectedData:[],
      namesall: [],
      filteredDataone: [],
      firstCategoryName: "",
      secondCategoryName: "",
      thirdCategoryName: "",
      OnlineChildData: [],
      offlineSubCategory: [],
      activateInputBackgroundClr: "rgb(254, 255, 253)",
      deactivateInputBackgroundClr: "#E9ECEF",
      myStyle: {
        backgroundColor: "#16a085",
      },
      showPrintPrinting: false,
      usagetypeselection: [],
      isUsageTypeDropdownOpen: false,
      DGprintingExhibitions: false,
      DGprintingDocumentary: false,
      DGprintingMultimedia: false,
      DGprintingResearch: false,
      DGprintingPresentation: false,
      DGprintingEducational: false,
      usageTypeSelectedOnlineID: 0,
      usageTypeSelectedOfflineID: 0,
      usageTypeSelectedPrintID: 0,

      DGOnlineExhibitions: false,
      DGOnlineDocumentary: false,
      DGOnlineMultimedia: false,
      DGOnlineResearch: false,
      DGOnlinePresentation: false,
      DGOnlineEducational: false,

      DGOfflineExhibitions: false,
      DGOfflineDocumentary: false,
      DGOfflineMultimedia: false,
      DGOfflineResearch: false,
      DGOfflinePresentation: false,
      DGOfflineEducational: false,

      isLocationDropdownOpen: false,
      isImageSizeDropdownOpen: false,
      isDGOfflineDropdownOpen: false,
      isFirstDropdownOpen: false,
      isDGonlineDropdownOpen: false,
      isPrintDropdownOpen: false,

      OpenInquiryModal: false,
      usageTypeMutatedSelected: [],
      DigitalOfflineMutatedSelected: [],
      FirstDropdownMutatedSelected: [],
      DigitalOnlineMutatedSelected: [],
      activateMultiDurationDropdown: 0,
      activateMultiOfflineDropdown: 0,
      activateMultiOnlineDropdown: 0,
      activateMultiPrintDropdown: 0,
      showPrintingType: false,
      showPrintingDropdown: false,
      showNonProfitDropdown: false,
      showAnnualReport: false,
      PrintingSelected: [],
      PrintingMutatedSelected: [],
      locationsize: "90%",
      durationsize: "90%",
      usagetypeSize: "100%",
      showOnlineAnnualReport: false,
      showPrintAnnualReport: false,
      locationSelected: [],
      usageOptionShow: false,
      showDigitalOnlineOption: false,
      showDigitalOfflineOption: false,
      showPrintingOption: false,
      showMultiUsageOptions: false,
      multieUsageSelection: 0,
      oneTimeUsage: "",
      initialImgId: 0,
      clickToLogin: false,
      imgdata: [],
      imgid: 0,
      url: "https://bnbarts.digiinteracts.in/public/",
      firstDropdownValue: "",
      thirdDropdownValue: "",
      secondDropdownValue: "",
      filteredCategoryTwoData: [],
      filteredCategoryThreeData: [],
      showSecondDropdown: false,
      showThirdDropdown: false,
      print: [],
      printPrice: [],
      imagesize: [],
      duration: [],
      electronicRights: [],
      imagesizePrice: [],
      electronicRightsPrice: [],
      durationPrices: [],
      methodDistribution: [],
      methodDistributionPrice: [],
      printingprice: 0,
      printArr: [],
      printrunvalue: "",
      durationvalue: "",
      distributionvalue: "",
      distributionprice: 0,
      electrightsvalue: "",
      imgsizevalue: "",
      showotherdropdown: false,
      priceArr: [],
      totalprice: 0,
      imgsizeprice: 0,
      electprice: 0,
      durationprice: 0,
      isLoading: false,
      guestcartcount: 0,
      loaded: false,
      isSavedLicenseExist: false,
      image_date: null,
      nonProfitSelection: [],
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dateOriginal: "",
      image_id: "",
      photographerName: "",
      file_size: "",
      mime_type: "",
      software: "",
      event: "",
      subject: "",
      location: "",
      source: "",
      make: "",
      model: "",
      orientation: "",
      resolution: "",
      img_made_software: "",
      exposureTime: "",
      shutter_speed_value: "",
      aperture_value: "",
      exposure_bias_value: "",
      max_aperture_value: "",
      metering_mode: "",
      flash: "",
      focal_length: "",
      sub_sec_time: "",
      sub_sec_time_original: "",
      sub_sec_time_digitized: "",
      color_space: "",
      img_width: "",
      img_length: "",
      focal_plane_x_resolution: "",
      focal_plane_resolution_unit: "",
      sensing_method: "",
      custom_rendered: "",
      exposure_mode: "",
      white_balance: "",
      focal_lengthIn_35mmFilm: "",
      scene_capture_type: "",
      gain_control: "",
      contrast: "",
      saturation: "",
      sharpness: "",
      subject_distance_range: "",
      undefined_tag_0xA431: "",
      undefined_tag_0xA432: "",
      undefined_tag_0xA434: "",
      document_title: "",
      urgency: "",
      category: "",
      sub_categories: "",
      keywords: "",
      release_date: "",
      release_time: "",
      special_instructions: "",
      author_byline: "",
      author_title: "",
      city: "",
      head_line: "",
      photo_source: "",
      copyright: "",
      caption_writer: "",
      light_source: "",
      exif_image_width: "",
      exif_image_length: "",
      creation_Date: "",
      iso_speed_ratings: "",
      exposure_program: "",
      f_number: "",
      exposure_time: "",
      otr: "",
      showImagePriceBox: false,
      showDistributionBox: false,
      showDurationBox: false,
      showElectRightBox: false,
      showPrintRunBox: false,
      photographer_id: 0,
      imageattributeid: 0,
      categoryid1: 0,
      categoryid2: 0,
      categoryid3: 0,
      webcatid: 0,
      websubcatid: 0,
      thumbails: "",
      userData: [],
      userinfoId: 0,
      imageattrid: 0,
      title: "",
      usagePeriodDropdownData: [],
      licenseAmount: 0,
      savedlicenseIDSelected: 0,
      guestcartitems: [],
      imgCat1: 0,
      imgCat2: 0,
      imgCat3: null,
      Filteredcategory1Names: [],
      imgManageTable_id: 0,
      selectedImageData: [],
      web_subcat_name: "",
      catName: "",
      web_subcat_id: 0,
      prev: "<",
      next: ">",
      nextVal: "",
      prevVal: "",
      showUsageTypeLabel: false,
      usageTypeCheckbox: false,
      showLocationLabel: false,
      showUsageTypeDropdown: false,
      nonProfitOfflineData: [],
      nonProfitOnlineData: [],
      nonProfitPrintData: [],
      annualReportOfflineData: [],
      annualReportOnlineData: [],
      annualReportPrintData: [],
      magazineOfflineData: [],
      magazineOnlineData: [],
      magazinePrintData: [],
      newspaperOfflineData: [],
      newspaperOnlineData: [],
      newspaperPrintData: [],
      TVOfflineData: [],
      TVOnlineData: [],
      TVOPrintData: [],
      radioOfflineData: [],
      bookOfflineData: [],
      bookOnlineData: [],
      bookPrintData: [],
      printRunOfflineData: [],
      usageTypeOptions: [],

      showDGOnlineType: false,
      showDGOfflineType: false,
      showOfflineDropdown: false,
      showFirstDropdown: false,
      showOnlineDropdown: false,
      DGonlineSelected: [],
      DGofflineSelected: [],
      FirstDropdownSelected: [],
       shownonProfit: "",
      FirstDropdownTypes: [],
      OfflineTypesNames: [],

      showOfflinenonProfit: false,
      showOfflineAnnualReport: false,
      OriginalSizeSeletedBoolean: false,
      showOfflineMagazine: false,
      showOfflineNewspaper: false,
      showOfflineTV: false,
      showOfflineRadio: false,
      showOfflineBook: false,
      showOfflineExhibitions: false,
      showOfflineDocumentaries: false,
      showOfflineMultimedia: false,
      showOfflineEducational: false,
      showOfflineResearch: false,
      showOfflinePresentation: false,
      showOfflinePrinting: false,
      showOfflineOther: false,

      showOnlinenonProfit: false,
      showOnlineMagazine: false,
      showOnlineNewspaper: false,
      showOnlineTV: false,
      showOnlineRadio: false,
      showOnlineBook: false,
      showOnlineExhibitions: false,
      showOnlineDocumentaries: false,
      showOnlineMultimedia: false,
      showOnlineEducational: false,
      showOnlineResearch: false,
      showOnlinePresentation: false,
      showOnlinePrinting: false,
      showOnlineOther: false,

      showPrintnonProfit: false,
      showPrintAnnualReport: false,
      showPrintMagazine: false,
      showPrintNewspaper: false,
      showPrintTV: false,
      showPrintRadio: false,
      showPrintBook: false,
      showPrintExhibitions: false,
      showPrintDocumentaries: false,
      showPrintMultimedia: false,
      showPrintEducational: false,
      showPrintResearch: false,
      showPrintPresentation: false,
      showPrintPrinting: false,
      showPrintOther: false,
      multipleUsageData: [],
      showPrintMagazine: false,
      showOnlineMagazine: false,
      showOfflineMagzine: false,
      FirstDropdownTypes: [],
      SecondDropdownTypes: [],
      ThirdDropdownTypes: [],
      bookPrintPrintSizeData: [
        { id: "21 x 30 cm", value: "21 x 30 cm" },
        { id: "30 x 42 cm", value: "30 x 42 cm" },
        { id: "42 x 60 cm", value: "42 x 60 cm" },
        { id: "60 x 84 cm", value: "60 x 84 cm" },
      ],
      bookPrintMergingData: [
        { id: "2.5 cm", value: "2.5 cm" },
        { id: "5 cm", value: "5 cm" },
        { id: "7 cm", value: "7 cm" },
        { id: "10 cm", value: "10 cm" },
        { id: "15 cm", value: "15 cm" },
        { id: "20 cm", value: "20 cm" },
        { id: "25 cm", value: "25 cm" },
        { id: "30 cm", value: "30 cm" },
      ],
      bookPrintPaperTypeData: [
        { id: "Commended Level glossy", value: "Commended Level glossy" },
        {
          id: "Commended Level Simi glossy",
          value: "Commended Level Simi glossy",
        },
        { id: "Commended Level Mat", value: "Commended Level Mat" },
        { id: "Archiving Level glossy", value: "Archiving Level glossy" },
        {
          id: "Archiving Level Semi glossy",
          value: "Archiving Level Semi glossy",
        },
        { id: "Archiving Level Mat", value: "Archiving Level Mat" },
      ],

      sizesSelected: [],
      showSizesDropdown: false,
      showLocationsDropdown: false,
    };
  },
  mounted() {
     let userId = JSON.parse(localStorage.getItem("user-info"));
     userId ? this.userId= userId.data.id : null;
    userId ? this.token= userId.token : null;

// fetch('https://bnbarts.digiinteracts.in/api/add-to-card/view', {
//   headers: {Authentication: 'Bearer ' + this.token,
//   'Content-Type': 'application/json'
//   }
// })
//    .then(resp => resp.json())
//    .then(data => console.log(data))


    this.$store.state.firstDropdownChildData=[];
        this.$store.state.secondDropdownChildData=[];
                this.$store.state.thirdDropdownChildData=[];
                 this.$store.state.firstDropdownChildNames=[];
        this.$store.state.secondDropdownChildNames=[];
                this.$store.state.thirdDropdownChildNames=[];
    const urlParams = new URLSearchParams(window.location.search);
    this.imageattrid = parseInt(urlParams.get("imageid"));
    this.initialImgId = this.imageattrid;
    this.subcatid = urlParams.get("subcatid");
    this.catid = urlParams.get("catid");
    this.web_subcat_name = urlParams.get("subcatName");
    this.catName = urlParams.get("catName");
    this.getImage;
    this.getImageDetail();
    // this.$store.state.photographersSubCategories.forEach((item) => {
    //   if (item.web_subcat_name === this.web_subcat_name) {
    //     this.web_subcat_id = item.web_subcat_id;
    //   }
    // });
    // this.$store.state.gallerydata.forEach((info) => {
    //   if (info.web_cat_name === this.catName) {
    //     this.web_cat_id = info.web_cat_id;
    //     // console.log(this.web_cat_id)
    //   }
    // });
    // this.$store.state.photographersdata.forEach((photographer_id) => {
    //   if (photographer_id.id === this.imageattrid) {
    //     this.photographer_id = photographer_id.photographers_id;
    //   }
    // });
    this.$store.dispatch("getUsagePeriodData");
    this.$store.dispatch("getMultipleDurationData");

    this.$store.dispatch("getLocationData");
    this.$store.dispatch("getImageSizesData");
    this.$store.dispatch("getUsageTypeData");
    // this.$store.dispatch("getSubCategoryData");
    this.$store.dispatch("getUsageTypeData");
    this.$store.dispatch("getCartCount")
    this.getUsagePeriodDataByStore;
    this.getcartnumbers;
    this.getMultipleDurationByStore;
    this.getLocationByStore;
    this.getImageSizesByStore;
    this.getUsageTypeByStore;
  },

  computed: {
     
    async getOfflineData() {
      const response = await fetch(
        "https://bnbarts.digiinteracts.in/api/sub-category/index/" +
          this.usageTypeSelectedOfflineID
      );
      const data = await response.json();
      console.log(data);
      this.FirstDropdownTypes = data.data;
      this.FirstDropdownTypes.map((item) => {
        this.OfflineTypesNames.push(item.name2);
      });
      // console.log(this.OfflineTypesNames);
    },
    // async getOfflineData() {
    // const response = await  fetch("https://bnbarts.digiinteracts.in//api/sub-category/index/"+this.usageTypeSelectedOfflineID)
    // const data = await response.json();
    // this.OfflineTypes = data.data;
    // this.OfflineTypes.map((item) => {
    //   this.OfflineTypesNames.push(item.name2)
    // })
    // console.log(this.OfflineTypesNames);
    // },

    photographersdata() {
      //console.log(this.$store.state.photographersdata.data[0].id);
      return this.$store.state.photographersdata.data[0].id;
    },
    getCategoryOne() {
      //console.log(this.$store.state.ImgCategoryOne);
      return this.$store.state.ImgCategoryOne;
    },
    // getCategoryTwo() {
    //   console.log(this.$store.state.ImgCategoryTwo)
    //   return this.$store.state.ImgCategoryTwo;
    // },
    getCategoryThree() {
      return this.$store.state.ImgCategoryThree;
    },
    imageattributedata() {
      //console.log(this.$store.state.photographersdata);
      return this.$store.state.photographersdata;
    },
    // selectedImageData() {
    //   this.imageattributedata.forEach((item) => {
    //     if(item.id === parseInt(this.imageattrid)) {
    //     // this.imgCat1 = item.category_id1;
    //     // this.imgCat2 = item.category_id2;
    //     // this.imgCat3 = item.category_id3;
    //     }
    //       // console.log(item.category_id1,item.category_id2, item.category_id3);
    //   })
    // },

    cartitems() {
      // this.cartcount = this.$store.state.cartitems.length;
      // console.log(this.cartcount);
      return this.$store.state.cartitems;
    },
    getcartnumbers() {
      this.cartcount = this.$store.state.cartitems.length;
      return this.$store.state.quantity;
    },
    getSavedLicense() {
      this.$store.state.savedlicenses.length !== 0
        ? (this.isSavedLicenseExist = false)
        : (this.isSavedLicenseExist = true);
      //this.$store.dispatch("getSavedLicense");

      return this.$store.state.savedlicenses;
    },
    // getSavedLicenseAmount() {
    //   this.$store.state.savedlicenses.forEach((item) => {
    //     if (item.id === this.savedlicenseIDSelected)
    //       this.licenseAmount = parseInt(item.amount);
    //     // console.log(item.id);
    //   });
    // },
    photographersdata() {
      // console.log(this.$store.state.photographersdata);
      return this.$store.state.photographersdata;
    },
    getImage() {
      return this.photographersdata.filter(
        (id) => id.id === parseInt(this.imageattrid)
      );
    },
    getcounting() {
      return this.$store.state.randomselectedimage;
    },
    getRandomSelectedImage() {
      //   this.nextVal = this.$store.state.randomselectedimage.next;
      //         this.preVal = this.$store.state.randomselectedimage.previous;
      // console.log(this.nextVal)
      //     console.log(this.preVal)
      // console.log(this.$store.state.randomselectedimage.data)
      // this.thumbails = this.$store.state.randomselectedimage[0].image;
      return this.$store.state.randomselectedimage.data;
    },
    getCategoriesDetail() {
      return this.$store.state.categoryDetails;
    },
    getSecondCategoriesDetail() {
      //console.log(this.$store.state.secondcategoryDetails);
      return this.$store.state.secondcategoryDetails;
    },
    getThirdCategoriesDetail() {
      return this.$store.state.thirdcategoriesDetails;
    },
    getImageDetails() {
      this.showPrintRunBox = true;
      axios
        .post(
          "https://bnbarts.digiinteracts.in/api/image_data_show/" +
            this.imgManageTable_id
        )
        .then((res) => {
          this.selectedImageData = res.data.data;
          //console.log(JSON.parse(this.selectedImageData.print));
          this.print = JSON.parse(this.selectedImageData.print);
          this.printPrice = JSON.parse(this.selectedImageData.print_price);
          this.imagesize = JSON.parse(this.selectedImageData.image_size);
          this.electronicRights = JSON.parse(
            this.selectedImageData.electronic_rights
          );
          this.imagesizePrice = JSON.parse(
            this.selectedImageData.image_size_price
          );
          this.electronicRightsPrice = JSON.parse(
            this.selectedImageData.electronic_rights_price
          );
          this.durationPrices = JSON.parse(
            this.selectedImageData.duration_price
          );
          this.duration = JSON.parse(this.selectedImageData.duration);
          this.methodDistribution = JSON.parse(
            this.selectedImageData.method_distribution
          );
          this.methodDistributionPrice = JSON.parse(
            this.selectedImageData.method_distribution_price
          );
          this.calcPrintPrice;
          //console.log(this.print);
        });
    },
    price() {
      this.totalprice =
        this.printingprice +
        this.imgsizeprice +
        this.electprice +
        this.durationprice +
        this.distributionprice +
        this.licenseAmount;
      return this.totalprice;
    },
    getimage_date() {
      // console.log(this.dateOriginal);
      if (this.dateOriginal) {
        let name = this.month[this.dateOriginal.slice(5, 7)];
        let day = this.dateOriginal.slice(8, 10);
        let year = this.dateOriginal.slice(0, 4);
        let hour = this.dateOriginal.slice(11, 13);
        let minutes = this.dateOriginal.slice(14, 16);
        let seconds = this.dateOriginal.slice(17, 19);
        let ampm = hour <= 12 ? "PM" : "AM";
        // function addZero(i) {
        //   if (i < 10) {i = "0" + i}
        //   return i;
        this.image_date =
          name +
          " " +
          day +
          ", " +
          year +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds +
          " " +
          ampm;
      }
      // console.log(this.image_date);
    },
    savedlicense() {
      // this.cartcount = this.$store.state.savedlicenses;
      return this.$store.state.savedlicenses;
    },
    getcartcount() {
      return this.$store.dispatch("getCartCount");
    },

    category1DropdownData() {
      this.isLoading = true;
      this.filteredCategoryTwoData = [];
      this.filteredCategoryThreeData = [];
      this.showSecondDropdown = false;
      this.showThirdDropdown = false;
      this.selectedImageData;
      this.getCategoriesDetail.forEach((value) => {
        // console.log(value.category_id)
        //         console.log(typeof(value.category_id))
        // console.log(this.imgCat1)
        // console.log(typeof(this.imgCat1))
        this.Filteredcategory1Names.push(value.name);
        // console.log(this.Filteredcategory1Names);
        //       if(value.category_id === this.imgCat1) {
        //         this.Filteredcategory1Names.push(value.name);
        // console.log(this.Filteredcategory1Names);
        //       }
      });
    },
    toggleAllDropDownEvaluation() {
      if (this.imagesize !== null) {
        this.showImagePriceBox = true;
      } else if (this.electronicRights !== null) {
        this.showDurationBox = true;
      } else if (this.duration !== null) {
        this.showDistributionBox = true;
      }
    },
    getUsagePeriodDataByStore() {
      // console.log(this.$store.state.usagePeriodDropdownData)
      return this.$store.state.usagePeriodDropdownData;
    },
    getMultipleDurationByStore() {
      // console.log(this.$store.state.multipleDuration)
      return this.$store.state.multipleDuration;
    },
    getLocationByStore() {
      // console.log(this.$store.state.locationsForImages[0].location)
      return this.$store.state.locationsForImages;
    },
    getImageSizesByStore() {
      // console.log(this.$store.state.imageSizesForSale)
      return this.$store.state.imageSizesForSale;
    },
    getUsageTypeByStore(id) {

      return this.$store.state.usageType;
    },
    changesInUsageTypeSelection() {
      let count = this.usagetypeselection.length - 1;
      let str =
        this.usagetypeselection.length > 5
          ? this.usagetypeselection.slice(1, 6).join(", ") + " ..."
          : this.usagetypeselection.join(", ");
      return str;
    },
  },
  methods: {
  
    addtocart() {
     
let digitalOffline = this.$store.state.firstDropdownChildData.reduce((acc, current) => {
  if (!acc[current.id2]) {
    acc[current.id2] = []
  }
  acc[current.id2].push(current.id3)
  return acc
}, {})
let digitalOnline = this.$store.state.secondDropdownChildData.reduce((acc, current) => {
  if (!acc[current.id2]) {
    acc[current.id2] = []
  }
  acc[current.id2].push(current.id3)
  return acc
}, {})
let print = this.$store.state.thirdDropdownChildData.reduce((acc, current) => {
  if (!acc[current.id2]) {
    acc[current.id2] = []
  }
  acc[current.id2].push(current.id3)
  return acc
}, {})
console.log(digitalOffline)
console.log(digitalOnline)
console.log(print)

let firstCatID =[]
 this.$store.state.firstDropdownChildData.filter((item) => {
  firstCatID.push(item.id1)
 })

  this.$store.state.secondDropdownChildData.filter((item) => {
  firstCatID.push(item.id1)
 })

  this.$store.state.thirdDropdownChildData.filter((item) => {
  firstCatID.push(item.id1)
 })
this.usageTypeId = firstCatID.filter((item, i, ar) => ar.indexOf(item) === i)

console.log( JSON.parse(JSON.stringify(this.usageTypeId)))
// console.log(...new Set(firstCatID));

      let apidata = ({
        // user_id: this.userId,
        img_attr_id: this.imageattrid,
        web_cat_id: this.webcatid,
      //   web_subcat_id: this.websubcatid,
      //  photographers_id:this.photographers_id,
      //  usage_period_id: this.OneDuration,
      //  duration_id: this.multieUsageSelection >0 ?
      //  this.multieUsageSelection : null,
      //  location_id: this.locationId,
      //   image_size_id: this.sizeID,
      //  usage_type_id: JSON.parse(JSON.stringify(this.usageTypeId)),
      //   digital_online_id: digitalOnline,
      //   digital_offline_id:digitalOffline,
      //   print_id: print
      })
      console.log(JSON.stringify(apidata))

axios.post("https://bnbarts.digiinteracts.in/api/add-to-card/add", {
  img_attr_id: this.imageattrid,
  web_cat_id: this.webcatid,
  web_subcat_id: this.websubcatid,
  photographers_id:this.photographers_id,
       usage_period_id: this.OneDuration,
       duration_id: this.multieUsageSelection >0 ?
       this.multieUsageSelection : null,
       location_id: this.locationId,
        image_size_id: this.sizeID,
          usage_type_id: JSON.parse(JSON.stringify(this.usageTypeId)),
           digital_online_id: digitalOnline,
        digital_offline_id:digitalOffline,
        print_id: print
}, {
  headers: {
    'Authorization': 'Bearer '+ this.token,
  }
})
.then((res) => {
  console.log(res.data)
})
.catch((error) => {
  console.error(error)
})




//  axios.create({
  
//         baseURL:  "https://bnbarts.digiinteracts.in/api/",
//         headers: {
//               'Content-Type': 'application/json',
//             'Authorization': 'Bearer '+ this.token,
//         },
//         data:  {
//           img_attr_id: this.imageattrid,
//         }
//     })
//     .post("add-to-card/add")
//     .then(res => {
//             console.log(res);
//     });

      //  axios
      //   .post(
      //     "https://bnbarts.digiinteracts.in/api/add-to-card/add",
      // apidata, config).then(response => console.log(response))
    },
getUsageTypeSelectedNames(id) { 
//   let checkDuplicateStatus= false;
// let notFoundStatus = false;

if(id === 10)
{
  this.$store.state.usageType.forEach((item) => {
      if (item.category_id === id) {
                this.usagetypeSelectedNames.push(item);
                  this.firstPlaceholderName = 
                  item.name;
             this.showcat1.status=  !this.showcat1.status
             this.showFirstInputBox = !this.showFirstInputBox;
      } })
}
if(id === 11)
{
  this.$store.state.usageType.forEach((item) => {
      if (item.category_id === id) {
                this.usagetypeSelectedNames.push(item);
                  this.secondPlaceholderName = 
                 item.name;
             this.showcat2.status=  !this.showcat2.status
             this.showSecondInputBox = !this.showSecondInputBox;
      } })
}
if(id === 12)
{
  this.$store.state.usageType.forEach((item) => {
      if (item.category_id === id) {
                this.usagetypeSelectedNames.push(item);
                  this.thirdPlaceholderName = 
                  item.name;
             this.showcat3.status=  !this.showcat3.status
             this.showThirdInputBox = !this.showThirdInputBox;
      } })
}


},


    async firstCategoryChildrenData(catid2, catid1) {
      await axios
        .get(
          "https://bnbarts.digiinteracts.in/api/sub-category/index/" + catid1
        )
        .then((response) => {
          // console.log(response)
          let data = response.data.data.filter(
            (i) => 
            i.category_id2 === catid2
          );
          
          this.namesall[i].push(data);
                    //console.log(this.namesall)

        });
    },
    async FirstDropDownsecondCategoryChildrenData(catid2, catid1) {
      //console.log(catid1)
      let indexvalue=0
       
      //console.log(catid2)
      let checkDuplicateStatus = false;
      await axios
        .get(
          "https://bnbarts.digiinteracts.in/api/sub-category/index/" + catid1
        )
        .then((response) => {
          //console.log(response)
          let data = response.data.data.filter(
            (i) => i.category_id2 === catid2 
          );
         // console.log(data[0])
          if(this.FirstDropdownCatDropdownData.length === 0) {
             this.FirstDropdownCatDropdownData.push({... data[0]})
          }
        else {
          this.FirstDropdownCatDropdownData.filter(item => 
          {
          if(item.category_id2 !== catid2) {
              checkDuplicateStatus = true;
          } else {
            //console.log(catid2)
            checkDuplicateStatus = false;
            //console.log(item.category_id2)
            indexvalue = this.FirstDropdownCatDropdownData.findIndex(x=>
              x.category_id2 === catid2)
              this.FirstDropdownCatDropdownData.splice(indexvalue,1)
          }
        })
        }
        if(checkDuplicateStatus) { this.FirstDropdownCatDropdownData.push({... data[0]})}
// console.log(indexvalue)
 console.log(this.FirstDropdownCatDropdownData)
        });
    },
    async FirstDropdownthirdCategoryChildrenData(catid2, catid1) {
      await axios
        .get(
          "https://bnbarts.digiinteracts.in/api/sub-category/index/" + catid1
        )
        .then((response) => {
          // console.log(response)
          let data = response.data.data.filter(
            (i) => i.category_id2 === catid2
          );
          //console.log(data)
          this.thirdCatDropdownData.push(data);
        });
    },

//second categories functions

 async SecondDropDownsecondCategoryChildrenData(catid2, catid1) {
      //console.log(catid1) 
      let indexvalue=0
     
      //console.log(catid2)
      let checkDuplicateStatus = false;
      await axios
        .get(
          "https://bnbarts.digiinteracts.in/api/sub-category/index/" + catid1
        )
        .then((response) => {
          console.log(response)
          let data = response.data.data.filter(
            (i) => i.category_id2 === catid2 
          );
         // console.log(data[0])
          if(this.SecondDropDownsecondCatDropdownData.length === 0) {
             this.SecondDropDownsecondCatDropdownData.push({... data[0]})
          }
        else {
          this.SecondDropDownsecondCatDropdownData.filter(item => 
          {
          if(item.category_id2 !== catid2) {
              checkDuplicateStatus = true;
          } else {
            //console.log(catid2)
            checkDuplicateStatus = false;
            //console.log(item.category_id2)
            indexvalue = this.SecondDropDownsecondCatDropdownData.findIndex(x=>
              x.category_id2 === catid2)
              this.SecondDropDownsecondCatDropdownData.splice(indexvalue,1)
          }
        })
        }
        if(checkDuplicateStatus) 
        { this.SecondDropDownsecondCatDropdownData.push({... data[0]})}
// console.log(indexvalue)
        });
//console.log(this.SecondDropDownsecondCatDropdownData)
    },
    async SecondDropdownthirdCategoryChildrenData(catid2, catid1) {
      await axios
        .get(
          "https://bnbarts.digiinteracts.in/api/sub-category/index/" + catid1
        )
        .then((response) => {
          // console.log(response)
          let data = response.data.data.filter(
            (i) => i.category_id2 === catid2
          );
          //console.log(data)
          this.thirdCatDropdownData.push(data);
        });
    },


//third categories functions

async ThirdDownsecondCategoryChildrenData(catid2, catid1) {
      //console.log(catid1)
      let indexvalue=0
      //console.log(catid2)
      let checkDuplicateStatus = false;
      await axios
        .get(
          "https://bnbarts.digiinteracts.in/api/sub-category/index/" + catid1
        )
        .then((response) => {
         console.log(response)
          let data = response.data.data.filter(
            (i) => i.category_id2 === catid2 
          );
         // console.log(data[0])
          if(this.ThirdDropdownCatDropdownData.length === 0) {
             this.ThirdDropdownCatDropdownData.push({... data[0]})
          }
        else {
          this.ThirdDropdownCatDropdownData.filter(item => 
          {
          if(item.category_id2 !== catid2) {
              checkDuplicateStatus = true;
          } else {
            //console.log(catid2)
            checkDuplicateStatus = false;
            //console.log(item.category_id2)
            indexvalue = this.ThirdDropdownCatDropdownData.findIndex(x=>
              x.category_id2 === catid2)
              this.ThirdDropdownCatDropdownData.splice(indexvalue,1)
          }
        })
        }
        if(checkDuplicateStatus) { 
          this.ThirdDropdownCatDropdownData.push({... data[0]})}
// console.log(indexvalue)
 //console.log(this.secondCatDropdownData)
        });
    },

    sizeClick($event,id) {
      let selectionValue = $event.target.value;
      console.log(id)
this.sizeID.push(id)
      selectionValue.toLowerCase() === "original"
        ? (this.OriginalSizeSeletedBoolean = !this.OriginalSizeSeletedBoolean)
        : null;
      this.showLocationsDropdown = false;
      // this.isImageSizeDropdownOpen = true;
    },
    FNDGOnlineDropDown() { 
      this.showOnlineDropdown = !this.showOnlineDropdown;
      this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      //this.showOnlineDropdown = false;
      this.isDGonlineDropdownOpen = true;
      this.isPrintDropdownOpen = false
      this.isLocationDropdownOpen = false;
      this.isImageSizeDropdownOpen = false;
    },
    PrintingDropDown() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
    },
    FNDGOFirstDropDown() {   
      this.showFirstDropdown = !this.showFirstDropdown;
      this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showOnlineDropdown = false;
      this.showPrintingDropdown = false;
      this.isFirstDropdownOpen = !this.isFirstDropdownOpen;      
      this.isUsageTypeDropdownOpen = false;
      this.isPrintDropdownOpen = false
      this.isDGonlineDropdownOpen = false
      this.isLocationDropdownOpen = false;
      this.isImageSizeDropdownOpen = false;
      this.showSizesDropdown = false;
    },
    FNSizesDropdown() {
      this.showSizesDropdown = !this.showSizesDropdown;
      this.showLocationsDropdown = false;
      this.visibilityOFUsageTypeCheckbox = true;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
      this.isImageSizeDropdownOpen = true;
            this.isUsageTypeDropdownOpen = false;
            this.isLocationDropdownOpen = false;
             this.showPrintingDropdown = false;
       this.isPrintDropdownOpen = false;
    },
    DigitalOnlineClicked(catid2, catid1) {
      //alert("second clicked")
      //  console.log(catid2)
      //  console.log(catid1)
      // this.firstCategoryChildrenData(catid2, catid1);
      // console.log(this.OnlineChildData)
      this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.isDGonlineDropdownOpen = true
      this.SecondDropDownsecondCategoryChildrenData(catid2, catid1);
      // this.SecondDropdownthirdCategoryChildrenData(catid2, catid1);
      this.showSizesDropdown = false;
          this.isUsageTypeDropdownOpen = false;
      this.showFirstDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.isFirstDropdownOpen = false;
            this.isPrintDropdownOpen = false
        this.MultipledropdownValuesMutation(this.DGonlineSelected);
       this.DigitalOnlineMutatedSelected = this.changesInDropDownSelection
    },
    FirstDropdownClicked(catid2, catid1) {
      this.showUsageTypeDropdown = true;
      this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.isFirstDropdownOpen = true;
    this.isUsageTypeDropdownOpen = false;
      // console.log($e.target.id)
      // console.log(this.DGofflineSelected)
      this.FirstDropDownsecondCategoryChildrenData(catid2, catid1);
      // this.FirstDropdownthirdCategoryChildrenData(catid2, catid1);
            this.isPrintDropdownOpen = false
      this.isDGonlineDropdownOpen = false
      this.showSizesDropdown = false;
      this.MultipledropdownValuesMutation(this.FirstDropdownSelected);
      this.FirstDropdownMutatedSelected = this.changesInDropDownSelection;
//console.log(this.DigitalOfflineMutatedSelected)
      //  console.log("final val: " + this.changesInDropDownSelection)
    },
    printingClicked(catid2, catid1) {
 
      this.showUsageTypeDropdown = false;
            this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.showSizesDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
          this.isUsageTypeDropdownOpen = false;
      this.isPrintDropdownOpen = true
      this.isFirstDropdownOpen = false;
      this.isDGonlineDropdownOpen = false
      // this.isPrintDropdownOpen = true;
      //this.thirdCategoryChildrenData(catid2, catid1);
      this.ThirdDownsecondCategoryChildrenData(catid2, catid1);
      this.showDGOnlineType = !this.showDGOnlineType;
      this.MultipledropdownValuesMutation(this.PrintingSelected);
      this.PrintingMutatedSelected = this.changesInDropDownSelection;
   
    },
    nonProfitClicked($e) {
      $e.preventDefault();
      this.showUsageTypeDropdown = false;
      this.showLocationsDropdown = false;
      this.showSizesDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
      //console.log(this.PrintingSelected);
      this.showDGOnlineType = !this.showDGOnlineType;
      this.MultipledropdownValuesMutation(this.PrintingSelected);
      this.PrintingMutatedSelected = this.changesInDropDownSelection;
      //  console.log("test: "+  this.PrintingMutatedSelected)
      //  console.log("final val: " + this.PrintingSelected)
      if (this.PrintingSelected) {
        this.nonProfit = true;
      }
    },
    FNUsageTypeDropdownEvent() { 
      this.showUsageTypeDropdown = !this.showUsageTypeDropdown;
      console.log(this.showUsageTypeDropdown)
      this.showLocationsDropdown = false;
      this.showOnlineDropdown = false;
      this.showFirstDropdown = false;
      this.showPrintingDropdown = false;
       this.isLocationDropdownOpen = false;
      this.isUsageTypeDropdownOpen = !this.isUsageTypeDropdownOpen;
      this.isImageSizeDropdownOpen = false;
      this.showSizesDropdown = false;
    },
    PrintingNxtBtn() {
      this.showLocationLabel = true;
      this.showSizesDropdown = false;
      this.showOnlineDropdown = false;
      this.showFirstDropdown = false;
      this.showLocationsDropdown = false;
      this.isPrintDropdownOpen = false;
      this.showPrintingDropdown = false;
      
    },
    nonProfitNxtBtn() {
      this.showLocationLabel = true;
      this.showSizesDropdown = false;
      this.showOnlineDropdown = false;
      this.showFirstDropdown = false;
      this.showLocationsDropdown = false;
    },
    FNLocationsDropdown() {
      this.showLocationsDropdown = !this.showLocationsDropdown;
      this.showSizesDropdown = false;
      this.visibilityOFUsageTypeCheckbox = true;
      this.showUsageTypeDropdown = false;
      this.showOnlineDropdown = false;
      this.isLocationDropdownOpen = true;
       this.isUsageTypeDropdownOpen = false;
       this.isImageSizeDropdownOpen = false;
       this.showFirstDropdown = false;
       this.isFirstDropdownOpen = false;
       this.showOnlineDropdown = false;
       this.isDGonlineDropdownOpen = false;
       this.showPrintingDropdown = false;
       this.isPrintDropdownOpen = false;
    },
    FNDigitalOnlineDropdownEvent() { 
      this.showOnlineDropdown = !this.showOnlineDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showPrintingDropdown = false;
      this.showLocationsDropdown = false
      this.isDGonlineDropdownOpen = false;
      this.isUsageTypeDropdownOpen = false;
       this.isFirstDropdownOpen = false;
       this.isImageSizeDropdownOpen = false;
      this.isDGonlineDropdownOpen = true;
      this.isPrintDropdownOpen = false
      this.isLocationDropdownOpen = false
    },
    PrintingDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
      this.isPrintDropdownOpen = true;
      this.showLocationsDropdown = false
      this.isUsageTypeDropdownOpen = false;
this.isFirstDropdownOpen = false;
      this.isDGonlineDropdownOpen = false
       this.isLocationDropdownOpen = false;
      this.isImageSizeDropdownOpen = false;
    },
    AnnualDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
    },
    magzineDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
    },
    newsPaperDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
    },
    TVDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
    },
    radioDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
    },
    bookDropdownEvent() {
      this.showPrintingDropdown = !this.showPrintingDropdown;
      this.showSizesDropdown = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
    },
    locationClick(id) { 
this.locationId= id
      this.isLocationDropdownOpen = true;
      // this.showUsageType = !this.showUsageType;
      // this.visibilityOFUsageTypeCheckbox = !this.visibilityOFUsageTypeCheckbox;
    },

    showUsageTypeDropdown() {
      this.usageTypeCheckbox = !this.usageTypeCheckbox;
    },
    async firstUsageTypeValue(id) {
      // if(this.OnlineTypes.length !==0){alert("testing")}
      this.activateMultiOnlineDropdown = 0;
      await axios
        .get("https://bnbarts.digiinteracts.in/api/sub-category/index/" + id)
        .then((response) => {
          // console.log(response.data)
          // if (this.FirstDropdownTypes.length === 0) {
            if (id === 10) {
            this.FirstDropdownTypes = response.data.data;

            //this.activateMultiOnlineDropdown=1
          } else if (id === 11) {
            //alert(this.FirstDropdownTypes.length)
            this.SecondDropdownTypes = response.data.data;
            // this.activateMultiOfflineDropdown=1;
          } else if (id === 12) {
            this.ThirdDropdownTypes = response.data.data;
            //this.activateMultiPrintDropdown=1;
          }
          //console.log(this.OnlineTypes)
        });
                  // console.log(this.FirstDropdownTypes)

    },
    usageTypeClick(id, ) { 
      //console.log(id)
      // console.log(this.usageTypeCheckbox)
      // this.activateMultiOnlineDropdown=1;
       console.log(this.multieUsageSelection)
      console.log(this.locationSelected)
      console.log(this.sizesSelected)
      this.getUsageTypeSelectedNames(id);
      this.firstUsageTypeValue(id);
      this.usageTypeCheckbox = !this.usageTypeCheckbox;
      this.showLocationsDropdown = false;
      this.showSizesDropdown = false;
      // this.showUsageTypeDropdown = !this.showUsageTypeDropdown
      this.isUsageTypeDropdownOpen = true;

      // this.MultipledropdownValuesMutation(this.usagetypeselection);
      // this.usageTypeMutatedSelected = this.changesInDropDownSelection;
      // this.changesInUsageTypeSelection;
    },

    SizeNxtBtn() {
      this.showLocationLabel = true;
      this.showSizesDropdown = false;
      this.showPrintingDropdown = false;
      this.showOnlineDropdown = false;
      this.showFirstDropdown = false;
      this.isImageSizeDropdownOpen = false;
            this.isUsageTypeDropdownOpen = false;

    },
    LocationNxtBtn() {
      this.showUsageTypeLabel = true;
      this.showLocationsDropdown = false;
      this.showPrintingDropdown = false;
      this.showOnlineDropdown = false;
      this.showFirstDropdown = false;
      this.isUsageTypeDropdownOpen = false;
      this.isLocationDropdownOpen = false;
    },
    UsageTypeNxtBtn() { 
      this.showDGOfflineType = false;
      this.showUsageTypeDropdown = false;
      this.showFirstDropdown = false;
      this.showPrintingDropdown = false;
      this.isUsageTypeDropdownOpen = false;
    },
    FirstDropdownNxtBtn() {
      this.showSizesDropdown = false;
      this.showOnlineDropdown = false;
      this.showFirstDropdown = false;
      this.showLocationsDropdown = false;
      this.showPrintingDropdown = false;
      this.isFirstDropdownOpen = false;
            this.isUsageTypeDropdownOpen = false;
    },
    DGOnlineNxtBtn() {
      this.showFirstDropdown = false;
      this.showOnlineDropdown = false;
      this.showSizesDropdown = false;
      this.showLocationsDropdown = false;
      this.showPrintingDropdown = false;
      this.isDGonlineDropdownOpen = false;
    },
    digitalOnlineclick() {
      this.showDigitalOfflineOption = true;
    },
    digitalOfflineclick() {
      this.showPrintingOption = true;
    },
    multipleUsage() {
      this.showMultiUsageOptions = true;
      this.sizeOptionShow = false;
      this.activateMultiDurationDropdown = 1;
    },
    onetimeOpen() {  
      // console.log(this.OneDuration)
      // console.log($event.target.id)
      // this.showMultiUsageOptions = false;
      this.sizeOptionShow = true;
      //this.multieUsageSelection = "";
      if (this.OneDuration === 2) {
        this.activateMultiDurationDropdown = 1;
      } else {
        this.activateMultiDurationDropdown = 0;
      }
    },
  
    async getImageDetail() {
      if (!this.imageattrid) {
        this.imageattrid = this.initialImgId;
      }
      await axios
        .post(
          "https://bnbarts.digiinteracts.in/api/gallery/single_view/" +
            this.imageattrid
        )
        .then((response) => {
         // console.log(response.data.data)
          this.webcatid=response.data.data[0].web_cat_id
          this.websubcatid=response.data.data[0].web_subcat_id
          this.photographers_id = response.data.data[0].photographers_id
          this.imgdata = response.data.data;
          // console.log(this.imgdata)
          this.nextVal = response.data.next;
          this.prevVal = response.data.previous;
        });
    },
    async deleteSavedLicense(id) {
      await axios
        .post("https://bnbarts.digiinteracts.in/api/my-licenses/update/" + id)
        .catch((error) => {
          this.state.error = error.response.status;
        });
      this.$store.dispatch("getSavedLicense");
    },
    async editSavedLicense(id) {
      await axios
        .post("https://bnbarts.digiinteracts.in/api/my-licenses/delete/" + id)
        .catch((error) => {
          this.state.error = error.response.status;
        });
      this.$store.dispatch("getSavedLicense");
    },
    resetAllDropdown() {
      this.showSecondDropdown = false;
      this.showThirdDropdown = false;
      this.showPrintRunBox = false;
      this.showImagePriceBox = false;
      this.showElectRightBox = false;
      this.showDurationBox = false;
      this.showDistributionBox = false;
    },
    convertedDate(dt) {
      let year = dt.slice(0, 4);
      let month = dt.slice(5, 7);
      let day = dt.slice(8, 10);
      return month + "/" + day + "/" + year;
    },
    showLicenseAmount(id) {
      this.savedlicenseIDSelected = id;
      this.price;
    },

    nextImageShow() {
      this.imageattrid = this.nextVal;
      //console.log(this.nextVal);
      this.$router.replace({
        path: "ImageSelection",
        query: {
          imageid: this.nextVal,
          catName: this.catName,
          subcatName: this.web_subcat_name,
        },
      });
      this.getImageDetail();
    },
    prevImageShow() {
      this.imageattrid = this.prevVal;
      this.$router.replace({
        path: "ImageSelection",
        query: {
          imageid: this.prevVal,
          catName: this.catName,
          subcatName: this.web_subcat_name,
        },
      });
      this.getImageDetail();
    },
    callcartsummary() {
      this.$router.push({ name: "CartSummary" });
    },
  },
};
</script>

<style scoped>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1200px !important;
  }
}
.exclusionMessage {
  font-size: 13px;
}
.btn-holder {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 2%;
}
.btn-inline {
  display: inline-block;
}
.emailmessage {
  margin-bottom: 0;
}

* {
  font-family: "OldStandardTT-Regular";
  font-size: 14px;
}
.multiLabel {
  margin-left: 10px;
  width: 100%;
}
.inputSelection {
  background: rgb(254, 255, 253);
  border: 1px solid #d7dbdf;
  height: 30px;
  width: 87%;
  display: inline-block;
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 0 10px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  width: 85%;
  text-align: justify;
}
.multiCheckboxSelection {
  display: block;
  height: auto;
  max-height: 100px;
  overflow: scroll;
  padding: 0;
  /* width: 350px; */
  width: 87%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #d7dbdf;
  z-index: 99;
}
.multiCheckboxSelection > li:hover {
  background-color: #f2f2f2;
}

.multiCheckboxSelection > li {
  padding: 3px 12px;
  display: flex;
}

.usage-type {
  display: block;
  border: 1px solid #83878c;
  padding: 5px;
  margin-top: 5px;
  width: 200px;
  height: 150px;
  overflow: scroll;
}
.usageSelection-row {
  float: left;
  margin: 5px 10px;
}
.duration {
  width: 20%;
}
a {
  color: #83878c !important;
}

.defaultClr {
  border-color: #83878c !important;
  color: #83878c !important;
}

.defaultClr:focus {
  border-color: #83878c !important;
  color: #83878c !important;
}
/* 
.text-start {
  color: #83878c;
} */

.form-check-input:checked {
  background-color: #83878c !important;
  border-color: #83878c !important;
}
a {
  color: #83878c;
}

.save-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  left: -15px;
}

.save-btn-outline-black:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 1%;
  width: 96%;
  height: 1px;
  background-color: #83878c;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.addtocart-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}

.addtocart-btn-outline-black:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 1%;
  width: 96%;
  height: 1px;
  background-color: #83878c;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.checkout-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
}

.media-btn-outline-black {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  right: -580px;
}
.media-btn-outline-black:after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 15%;
  width: 70%;
  height: 1px;
  background-color: #83878c;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.media-btn-outline-black:hover:after,
.media-btn-outline-black:focus:after {
  left: 0%;
  width: 100%;
  box-shadow: none;
}
.media-btn-outline-black:hover {
  color: #83878c;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.btn-check:focus + .media-btn-outline-black,
.media-btn-outline-black:focus {
  box-shadow: none;
}
.img-detail {
  width: 100%;
  height: auto;
  display: block;
}

.short-form {
  width: 100% !important;
}

.cart-icon {
  position: fixed;
  right: 13px;
  top: 20px;
  z-index: 99999;
  /* background-color: white !important;
    border: 0px !important; */
}

.cart-count {
  position: fixed;
  top: 11px;
  right: 29px;
  color: #83878c;
}

.cart-count:hover {
  color: white !important;
}
.cart {
  color: #83878c;
}
.paypal-window {
  z-index: 99999;
}

.methoddistribution {
  width: 30px;
}

.saved-license-delete {
  float: right;
  cursor: pointer;
}
.saved-license-amount {
  float: right;
  color: #83878c;
}

.next-btn {
  float: right;
  width: 30%;
}

.img-box {
  width: 97%;
  margin: 0;
  padding: 0;
}

.btn-border {
  position: relative;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 14px;
  color: #83878c;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  background-color: transparent;
  outline: none;
}
/* .btn-border:after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 19%;
    width: 65%;
    height: 1px;
    background-color: #83878c;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
} */
.btn-border:hover:after,
.btn-border:focus:after {
  left: 15%;
  width: 70%;
  box-shadow: none;
}
.btn-border:hover {
  color: #303030;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.btn-check:focus + .btn-border,
.btn-border:focus {
  box-shadow: none;
}
.btn-check:checked + .btn-border,
.btn-check:active + .btn-border,
.btn-border:active,
.btn-border.active,
.btn-border.dropdown-toggle.show {
  color: #303030;
  background-color: transparent;
  border-color: transparent;
}
.btn-check:checked + .btn-border:focus,
.btn-check:active + .btn-border:focus,
.btn-border:active:focus,
.btn-border.active:focus,
.btn-border.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-border:disabled,
.btn-border.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.img-Title {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-align: left;

  position: relative;
  border-bottom: 1px solid #83878c;
}

.prev-button {
  margin-left: 15px;
}

.next-button {
  margin-right: 15px;
}
.img-detail-section {
  display: block;
  padding: 0;
  margin: 25px 0 0 0;
  background-color: #fff;
  z-index: 999;
}

.usage-container {
  float: left;
}

.selection-row {
  background-color: red;
}

.duration-selection {
  width: 80% !important;
}

.usage-selection {
  width: 132px;
}
</style>
