<template>
<NavBar />

  <div class="inner-section clearfix">
    <section class="contactus-section">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="ContactusTitle contactus-offer">Contact US</div>
                        </div>
                    </div>
                    <div class="row mt-3  clearfix ">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <figure class="ContactusimgHover">
                                <img src="images/img-1.jpg" alt="sample72" />
                                <span class="contact-page-span"> Pitch a Photo Story</span>
                                <figcaption>
                                    <!-- <h3>Fletch Skinner</h3> -->
                                    <p>
                                       Learn how to share confidential information securely with us<br />
                                        <center class="mt-3">
                                        <router-link to="/pitchphotostory" class=" contactus-readmore ">Click</router-link>
                                        </center>
                                    </p>
                                </figcaption>                            
                            </figure>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <figure class="ContactusimgHover">
                                <img src="images/img-2.jpg" alt="sample72" />
                                <span class="contact-page-span"> Any Other Enquiry</span>
                                <figcaption>
                                    <!-- <h3>Fletch Skinner</h3> -->
                                    <p>
                                        Submit a comment or correction to an BNB story<br />
                                        <center class="mt-3">
                                        <router-link to="/enquiry" class="contactus-readmore">Click</router-link>
                                        </center>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <figure class="ContactusimgHover">
                                <img src="images/img-3.jpg" alt="sample72" />
                                <span class="contact-page-span"> Sales</span>
                                <figcaption>
                                    <!-- <h3>Fletch Skinner</h3> -->
                                    <p>
                                        Our Photography services and buying a print please contact us<br />
                                        <center class="mt-3">
                                        <router-link to="/sales"  class="contactus-readmore ">Click</router-link>
                                        </center>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <!-- <section class="contactus-section">
                <div class="container">
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="contactTitle clearfix">Contact Us</div>
                        </div>                        
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-12">
                        <div class="contact-grid clearfix">
                            <router-link to="/sales" class="grid">
                                <h4>Sales</h4>
                                <p>Our Photography services and buying a print please contact us</p>
                            </router-link>
                            <router-link to="/pitchphotostory" class="grid">
                                <h4>Pitch a Photo Story</h4>
                                <p>Learn how to share confidential information securely with us</p>
                            </router-link>
                            <router-link to="/enquiry" class="grid">
                                <h4>Any Other Enquiry</h4>
                                <p>Submit a comment or correction to an BNB story</p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </section> -->
  </div>


</template>

<script>
// import Footer from '../components/DefaultComponents/Footer.vue'
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
        components: {
        NavBar
        }
}
</script>

<style scoped>
.contactus-section{
        display: block;
    padding: 60px 0 10px 0;
    margin: 0;
    background-color: #fff;
    z-index: 999;
}

.contact-us-Title {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: -50px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.contact-us-Title:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(16% + 4px);
}


.contactus-section {
  padding: 45px 0 55px 0;
}

.ContactusTitle {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.ContactusTitle:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(26% + 15px);
}


.contactus-offer:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(24% + 8px);
}

.ContactusTitle-About:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #999;
  transform: translateX(-50%);
  width: calc(27% + 19px);
}
.contactus-offer{
  margin-bottom: 10px;
}

.ContactusimgHover {
  position: relative;
  margin: 0;
  width: 100%;
  color: #ffffff;
  text-align: left;
  background-color: #000000;
  font-size: 16px;
}
.ContactusimgHover span {
  position: absolute;
  bottom: 536px;
  left: 5%;
  display: block;
  text-align: center;
  background-color: #fff;
  padding: 12px;
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  text-transform: uppercase;
  width: 90%;
  transition: all ease-out 3s;
  animation: fadeIn 3s;
}

.contact-page-span{
  color: #83878c;
}

.contactus-readmore {
  font-family: "OldStandardTT-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #83878c;  
  text-decoration: none;
  position: relative;
  cursor: pointer;
  padding-top: 15px;
}
.contactus-readmore:after{
  content: '';
  position: absolute;
  bottom: -8px;
  left: 13%;
  width: 70%;
  height: 1px;
  background-color: #83878c;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.contactus-readmore:hover:after, .contactus-readmore:focus:after {
  left: 0%;
  width: 100%;
  box-shadow: none;
}
.contactus-readmore:hover {
color: #83878c;
background-color: transparent;
border-color: transparent;
    box-shadow: none;
}
.btn-check:focus + .contactus-readmore, .contactus-readmore:focus {
box-shadow: none;
}
.contactus-readmore:hover { 
  color: #83878c;
}

.ContactusimgHover:hover span {
  display: none;
}
.ContactusimgHover * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.ContactusimgHover img {
  position: relative;
  width: 100%;
  vertical-align: top;
  height: 584px;
  object-fit:cover;
}
.ContactusimgHover figcaption {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ContactusimgHover figcaption:before,
.ContactusimgHover figcaption:after {
  width: 1px;
  height: 0;
}
.ContactusimgHover figcaption:before {
  right: 0;
  top: 0;
}
.ContactusimgHover figcaption:after {
  left: 0;
  bottom: 0;
}
.ContactusimgHover h3,
.ContactusimgHover p {
  line-height: 30px;
}
.ContactusimgHover h3 {
  margin: 0 0 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.ContactusimgHover p {
  font-family: "OldStandardTT-Regular", Arial;
  font-size: 16px;
  color: #83878c;
  margin: 0 0 15px;
  text-align: center;
}
.ContactusimgHover a {
  z-index: 1;
}
.ContactusimgHover:before,
.ContactusimgHover:after,
.ContactusimgHover figcaption:before,
.ContactusimgHover figcaption:after {
  position: absolute;
  content: '';
  background-color: #ffffff;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  opacity: 0.8;
}
.ContactusimgHover:before,
.imgHContactusimgHoverover:after {
  height: 1px;
  width: 0%;
}
.ContactusimgHover:before {
  top: 0;
  left: 0;
}
.ContactusimgHover:after {
  bottom: 0;
  right: 0;
}
.ContactusimgHover:hover img,
.ContactusimgHover.hover img {
  opacity: 0.4;
}
.ContactusimgHover:hover figcaption,
.ContactusimgHover.hover figcaption {
  opacity: 1;
}
.ContactusimgHover:hover figcaption:before,
.ContactusimgHover.hover figcaption:before,
.ContactusimgHover:hover figcaption:after,
.ContactusimgHover.hover figcaption:after {
  height: 100%;
}
.ContactusimgHover:hover:before,
.ContactusimgHover.hover:before,
.ContactusimgHover:hover:after,
.ContactusimgHover.hover:after {
  width: 100%;
}
.ContactusimgHover:hover:before,
.ContactusimgHover.hover:before,
.ContactusimgHover:hover:after,
.ContactusimgHover.hover:after,
.ContactusimgHover:hover figcaption:before,
.ContactusimgHover.hover figcaption:before,
.ContactusimgHover:hover figcaption:after,
.ContactusimgHover.hover figcaption:after {
  opacity: 0.1;
}

</style>