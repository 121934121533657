<template>

<NavBar v-if="newFlag" />

<main>
<router-view></router-view>


</main>
<div class="inner-section clearfix">

  <Footer />
</div>
</template>

<script> 

import NavBar from "./components/DefaultComponents/NavBar.vue"

import Footer from './components/DefaultComponents/Footer.vue'

export default {
  components: {

    NavBar,
    Footer
  },
  data() {
    return {
      newFlag: false
    }
  }

}
</script>
<style>

</style>
