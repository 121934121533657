<template>
  <NavBar />
        <div class="inner-section clearfix" >
            <section>
                <div class="container" >
                    <div class="row clearfix">
                        <div class="col-12">
                            <div class="page-heading">Categories</div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                        v-for="data in Photographerimages" :key="data.id">
                       <router-link
                       :to="{ name: 'ImageDetail',
                       query: {
                        imageid: data.id }}">
                                <div class="category clearfix" >
                                    <div class="image-holder">
                                        <img :src="url+data.thumbnails" 
                                        class="images"
                                        alt="image goes here" />
                                        <span>{{data.title}}</span>
                                    </div>
                                </div>
                       </router-link>
                        </div>
                        <div class="col-12">
                            <center><a href="#" 
                            class="btn btn-outline-black btn -xl">
                            View all Categories</a></center>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import NavBar from '../components/DefaultComponents/NavBar.vue'

export default {
components: {
    NavBar
  },
  data() {
    return {
      url: 'https://bnbarts.com/dev/public/',
      imageid: 0,
    }
  },
  computed: {
    Photographerimages() {
      // console.log(this.url+this.$store.state.photographerimages[2].thumbnails);
      return this.$store.state.photographerimages;
    }
  },
   mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.photographer_id = urlParams.get("photographer_id");
    this.photographer_catid = urlParams.get("catid");
    this.photographer_subcatid = urlParams.get("subcatid");
    console.log(this.photographer_id,this.photographer_catid,this.photographer_subcatid);
this.$store.state.photographeridclicked=this.photographer_id;
this.$store.state.catidclicked=this.photographer_catid;
this.$store.state.subcatclicked=this.photographer_subcatid;
    this.$store.dispatch("getPhotographerimages");
    this.Photographerimages;
  }
}
</script>

<style scoped>
.images {
  height: 100px !important;
}

</style>