<template>
<NavBar> </NavBar>
  <div class="customSelection-box">
<form>
  
    <div class="col-12">
  <div class="customSelectionTitle mb-3 clearfix">Custom Selection</div>
    </div>


<div class="grid-container">
   <div class="row mb-3">
    <div class="col-sm-6">
    <legend class="col-form-label col-sm-2 pt-0">Usage Period</legend>

 <div class=" form-check-inline">
  <input class="form-check-input" @click="onetimeclicked" v-model="oneTimeUsage" 
  type="radio" name="usage" id="usage" value="usage">
  <label class="form-check-label" for="onetime">One time Use</label>
  </div>
   <div class=" form-check-inline">
  <input class="form-check-input" @click="multipleUsage" type="radio" name="usage" 
  id="usage" value="usage">
  <label class="form-check-label" for="multiple">Multiple times usage</label>
  </div>
</div>

 <div v-show="showMultiUsageOptions" class="col-12 col-lg-4" >

      <select class="form-select" id="autoSizingSelect" v-model="multieUsageSelection">
    <option value=""> Choose duration</option>
      <option value="1">6 to 12 Months</option>
      <option value="2">1 to 2 Years</option>
      <option value="3">2 to 3 Years</option>
      <option value="3">3 to 5 Years</option>
      <option value="3">5 to 10 Years</option>
    </select>
    </div>
 </div>
  </div>
<div class="grid-container">
  <div v-show="sizeOptionShow" class="sizesDropdown " >
    <UsageType />
    </div>
    </div>
<Sizes />
</form>
  </div>
  
</template>

<script>
import NavBar from "../components/DefaultComponents/NavBar.vue";

import UsageType from "../components/UI/UsageType.vue";
import Sizes from "../components/UI/Sizes.vue";


export default {
components: {
    UsageType,
    NavBar,
    Sizes,
  },
  data() {
    return {
       showMultiUsageOptions: false,
      sizeSelected: [],
      multieUsageSelection: '',
      oneTimeUsage:'',
      locations: [],
      sizeOptionShow: false,
       usageType: [
      {
        label: 'Digital Online',
        value: 'Digital Online'
      },
      {
        label: 'Digital Offline',
        value: 'Digital Offline'
      },
      {
        label: 'Print',
        value: 'Print'
      }
    ],
selected: [],
annual: false,
    magzine: false,
    newspaper: false,
    tv: false,
    radio: false,
    books: false,
    annualOptions: '',
magzineOptions: '',
newspaperOptions: '',
tvOptions: '',
radioOptions: '',
bookOptions: '',
    }
    
  },
  methods: {
    sizesSelection() {
      console.log(this.sizeSelected)
    },
    multipleUsage() {
      this.showMultiUsageOptions = true;
            this.sizeOptionShow = true;

    },
    onetimeclicked() {
      this.showMultiUsageOptions = false;
      this.sizeOptionShow = true;
    },
    onCheck(val) {
      this.selected = val;
      // console.log(this.selected[0])
      this.selected.forEach((item => {
        if(item == 'Nonprofit NGO') {
          this.ngo = true;
        } else if(item == 'Annual Report') {
          this.annual = true;
        }
        else if(item == 'Magazine') {
            this.magzine = true;
        }
        else if(item == 'Newspaper') {
          this.newspaper = true;
        }
        else if(item == 'TV') {
          this.tv = true;
        }
        else if(item == 'Radio') {
          this.radio = true;
        }
        else if(item == 'Book') {
          this.book = true;
        }
    })) 
    }
  }
}
</script>

<style scoped>
.grid-container{
  display: grid;
  gap: 10px;
}
.usageType{
  float: left;
  grid-column: 3;
  grid-row: 1 / span 2;
}
.digitalOnlineSelection{
  margin-top: 30px;
  padding-top: 100px;
}
.sizes{
  grid-column: 1 / span 2;
  grid-row: 1;
}
.customSelectionTitle{
font-family: "OldStandardTT-Regular", Arial;
  font-size: 30px;
  color: #83878c;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.customSelection-box{
  background-color: #f8f9f9;
  display: block;
  margin: 0;
  padding: 30px;
  box-shadow: 1px 0 6px rgba(0, 0, 0, 0.03);
}
</style>