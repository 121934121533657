import { createStore } from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";



export default createStore({
  state: {
    token: '',
    firstDropdownChildData:[],
    secondDropdownChildData:[],
    thirdDropdownChildData:[],
    firstDropdownChildNames:[],
    secondDropdownChildNames:[],
    thirdDropdownChildNames:[],
    posts: [],
    post: null,
    video: [],
    photographers: [],
    photographersdata: [],
    photographersCategories: [],
    photographersSubCategories: [],
    categoryDetails: [],
    secondcategoryDetails: [],
    thirdcategoriesDetails: [],
    cartitems: [],
    gallerydata: [],
    savedlicenses: [],
    updateprofiledata: [],
    ordersData: [],
    quantity: 0,
    addtocartitems: [],
    profileimage: '',
    guestcartquantity: 0,
    photographerswebcat: [],
    photographeridclicked: 0,
    catidclicked: 0,
    photographerssubcat: [],
    photographer_subcatid: 0,
    subcatclicked: 0,
    photographerimages: [],
    guestcartitems: [],
    grandtotal: 0,
    catid: 0,
    allrandomimages: [],
    nosubcatallrandomimages: [],
    subcatid: 0,
    catIDforImage: 0,
    img_attribtes_id: 0,
    randomselectedimage: [],
    imageId: 0,
    ImgCategoryOne: [],
    ImgCategoryTwo: [],
    ImgCategoryThree: [],
    searchedDataImages: [],
    nextImgVal: '',
    prevImgVal: '',
    searchedData: [],
    cartCount: 0,
    grandTotal: 0,
    usagePeriodDropdownData: [],
    multipleDuration: [],
    locationsForImages: [],
    imageSizesForSale: [],
    usageType: [],
    subcategoryData: [],
  },  
  plugins: [createPersistedState()],

    // profileimage (state) {
    //   return state.profileimage;
    // },
    // stategrandTotal(state) {
    //   state.grandtotal=0;
    //   // console.log(state.cartitems)
    //   for(let i=0; i< state.cartitems.amount.length; i++)
    //   {
    //     state.grandtotal += state.cartitems.amount[i].amount;
    //     // console.log(state.grandtotal);
    //     // console.log(state.cartitems.amount[i]);
    //   }
    //       return state.grandtotal;
    // }

  mutations:{
    SET_GRANDTOTAL(state, payload){
      state.grandTotal=0;
      payload.forEach((item) => {
        state.grandTotal += item.amount;
        // console.log(state.grandTotal);
      })
   },
    SET_NEXTIMGVAL(state, payload){
      state.nextImgVal = payload
      //console.log("mut:"+state.nextImgVal);
    },
    SET_PREVIMGVAL(state, payload){
      state.prevImgVal = payload
    },
     SET_POSTS (state, posts){
      state.posts = posts;
    },
    SET_POST  (state, post) {
      state.post = post;
    },
    SET_VIDEO  (state, video) {
      state.video = video;
    },
    SET_PHOTOGRAPHERS  (state, photographers) {
      state.photographers = photographers;
    },
    SET_PHOTOGRAPHERSDATA (state, photographersdata) {
      state.photographersdata = photographersdata;
    },
    SET_PHOTOGRAPHERSCATEGORIES (state, photographersCategories) {
     state.photographersCategories = photographersCategories;
     },
    SET_PHOTOGRAPHERSSUBCATEGORIES (state, photographersSubCategories) {
      state.photographersSubCategories = photographersSubCategories;
    },
    SET_CATEGORIESDETAILS (state, categoryDetails) {
      state.categoryDetails = categoryDetails;
    },
    SET_SECONDCATEGORIESDETAIL (state, secondcategoryDetails){
      state.secondcategoryDetails = secondcategoryDetails;
    },
    SET_THIRDCATEGORIESDETAIL (state, thirdcategoriesDetails) {
      state.thirdcategoriesDetails = thirdcategoriesDetails;
    },
    SET_CARTITEMS (state, payload ){
      state.cartitems = payload
    },
    SET_SAVEDLICENSES( state, savedlicenses) {
      state.savedlicenses = savedlicenses
    },
    SET_GALLERYDATA (state, gallerydata){
      state.gallerydata = gallerydata;
    },
    SET_UPDATEPROFILE ( state, updateprofiledata){
      state.updateprofiledata = updateprofiledata;
    },
    SET_ORDERSDATA ( state, ordersData) {
      state.ordersData = ordersData;
    },
    SET_CARTCOUNT (state, payload) {
      state.quantity = payload;
    },
    SET_PHOTOGRAPHERSWEBCAT (state, payload) {
      state.photographerswebcat = payload;
    },
    SET_PHOTOGRAPHERSSUBCAT ( state, payload) {
      state.photographerssubcat = payload;
    },
    SET_PHOTOGRAPHERIMAGES (state, payload) {
      state.photographerimages = payload;
    },
    // SET_GUESTITEMS ( state, guestcartitem) {
    //   state.guestcartitems.push(guestcartitem)
    // },
    SET_PROFILEIMAGE (state, payload) {
          state.profileimage = payload;
          // console.log("coming vuex"+ state.profileimage);
          // console.log(state.profileimage);
      },
      SET_ALLRANDOMIMAGES (state,  allrandomimages) {
        state.allrandomimages = allrandomimages;
      },
      SET_NOSUBCATALLRANDOMIMAGES(state,  nosubcatallrandomimages) {
        state.nosubcatallrandomimages = nosubcatallrandomimages;
        // console.log(state.nosubcatallrandomimages);
      },
      SET_RANDOMSELECTEDIMAGE (state, randomselectedimage) {
        state.randomselectedimage = randomselectedimage;
      },
      SET_GETCATEGORYONE (state, ImgCategoryOne) {
        state.ImgCategoryOne = ImgCategoryOne;
      },
      SET_SEARCHDATA (state, payload){
        state.searchedData = payload
        console.log(payload);
      },
      SET_GETCATEGORYTWO (state, ImgCategoryTwo) {
        state.ImgCategoryTwo = ImgCategoryTwo;
      },
      SET_GETCATEGORYTHREE (state, ImgCategoryThree) {
        state.ImgCategoryThree = ImgCategoryThree;
      },
      SET_USAGEPERIODDATA ( state, usagePeriodDropdownData ) {
        state.usagePeriodDropdownData = usagePeriodDropdownData
        // console.log(state.usagePeriodDropdownData)

      },
      SET_MULTIPLEDURATIONDATA (state, multipleDuration) {
        state.multipleDuration = multipleDuration
      },
      SET_LOCATIONDATA (state, locationsForImages) {
        state.locationsForImages = locationsForImages
      },
      SET_IMAGESIZESDATA (state, imageSizesForSale) {
        state.imageSizesForSale = imageSizesForSale
      },
      SET_USAGETYPENDATA (state, usageType) {
        state.usageType = usageType
      },
      SET_SUBCATEGORYNDATA (state, subcategoryData) {
        state.subcategoryData = subcategoryData
      },
      SET_firstDropdownChildData (state, firstDropdownChildData)
      {
        state.firstDropdownChildData = firstDropdownChildData
      },
      SET_secondDropdownChildData (state, secondDropdownChildData)
      {
        state.secondDropdownChildData = secondDropdownChildData
      },
      SET_thirdDropdownChildData (state, thirdDropdownChildData)
      {
        state.thirdDropdownChildData = thirdDropdownChildData
      },
      SET_firstDropdownChildNames (state, payload)
      {
        state.firstDropdownChildNames = payload
      },
      SET_secondDropdownChildNames (state, payload)
      {
        state.secondDropdownChildNames = payload
      },
      SET_thirdDropdownChildNames (state, payload)
      {
        state.thirdDropdownChildNames = payload
      }
  },
  actions: { 

    getPosts  ({ commit }) {
    axios.post('media/view')
    .then(response => {
      commit('SET_POSTS', response.data);
      });
  },
  getVideo  ({ commit }) {
    axios.post('manage_video/view')
    .then(response => {
      commit('SET_VIDEO', response.data);
      });
  },
  getPhotographers  ({ commit }) {
    axios.post('photo_management/view')
    .then(response => {
      commit('SET_PHOTOGRAPHERS', response.data);
      });
  },
  getPhotographersData  ({ commit }) {
    axios.post('image_attributes/view')
    .then(response => {
      commit('SET_PHOTOGRAPHERSDATA', response.data);
      });
  },

  getPhotographersCategories ({ commit }) {
    axios.post('web_cat/view')
    .then(response => {
      commit('SET_PHOTOGRAPHERSCATEGORIES', response.data);
    });
  },
  // getPhotographersSubCategories ({ commit }) {
  //   axios.post('web_subcat/view')
  //   .then(response => {
  //     commit('SET_PHOTOGRAPHERSSUBCATEGORIES', response.data);
  //   });
  // },
  getPhotographersSubCategories ({ commit }) {
    const urlParams = new URLSearchParams(window.location.search);
    this.subcatid = urlParams.get("catid");
    axios.post('gallery/web_sub_cat_one/'+this.subcatid)
    .then(response => {
      commit('SET_PHOTOGRAPHERSSUBCATEGORIES', response.data);
    });
  },
  allrandomimages ({ commit }) {
    const urlParams = new URLSearchParams(window.location.search);
    this.subcatid = urlParams.get("subcatid");
    axios.post('gallery/category_wise/'+this.subcatid+'/sub')
    .then(response => {
      commit('SET_ALLRANDOMIMAGES', response.data);
    });
  },
  noSubCatallrandomimages ({ commit }) {
    const urlParams = new URLSearchParams(window.location.search);
    this.catIDforImage = urlParams.get("catIDforImage");
    axios.post('gallery/category_wise/'+this.catIDforImage+'/main')
    .then(response => {
      commit('SET_NOSUBCATALLRANDOMIMAGES', response.data);
    });
  },
  async getRandomSelectedImage ({ commit }) {
   
    await axios.post('gallery/single_view/'+this.img_attribtes_id)
    .then(response => {
      commit('SET_RANDOMSELECTEDIMAGE', response.data);
       commit('SET_NEXTIMGVAL', response.data.next);
      commit('SET_PREVIMGVAL',  response.data.previous);
    });
  },
  getCategoriesDetail ({ commit }) {
    axios.post('categories_one/view')
    .then(response => {
      commit('SET_CATEGORIESDETAILS', response.data);
    });
  },
  getSecondCategoriesDetail ({ commit }) {
    axios.post('categories_two/view')
    .then(response=> {
      commit('SET_SECONDCATEGORIESDETAIL', response.data);
    })
  },
  getThirdCategoriesDetail ({ commit }) {
    axios.post('categories_three/view')
    .then(response => {
      commit('SET_THIRDCATEGORIESDETAIL', response.data);
    })
  },
  
  // async getCartItems ({ commit }) {
  //   if(localStorage.getItem('user-info')) {
  //     this.userData = JSON.parse(localStorage.getItem("user-info"));
  //     this.userinfoId = this.userData.data.id;
  //  await axios.post('add-to-card/view/'+this.userinfoId)
  //   .then(response => {
  //     console.log(response.data);
  //     commit('SET_CARTITEMS', response.data);
  //     commit('SET_GRANDTOTAL', response.data.amount)
  //   })
  // }
  // },https://bnbarts.digiinteracts.in/api/add-to-card/view
  async getCartCount ({ commit }) { 
    if(localStorage.getItem('user-info')) {
      this.userData = JSON.parse(localStorage.getItem("user-info"));
      this.token = this.userData.token;
      console.log(this.token)
   await axios.post("https://bnbarts.digiinteracts.in/api/add-to-card/view",{
      headers: {
        'Authorization': 'Bearer '+ this.token,
      }
    })
    .then(response => {
      console.log(response)
      commit('SET_CARTCOUNT', response.data);
    })
  }
  },
  getSavedLicense ({ commit }) {
    axios.post('my-licenses/view/'+this.userinfoId)
    .then(response => {
      console.log(response.data)
      commit('SET_SAVEDLICENSES', response.data);
    })
  },
  getGalleryData ({ commit }) {
    axios.post('gallery/view')
    .then(response => {
      commit('SET_GALLERYDATA', response.data);
    })
  },
  getOrders ({ commit }) {
    axios.post('order/view/'+this.userinfoId)
    .then(response => {
      commit ('SET_ORDERSDATA', response.data);
    })
  },
  addToCart ({ commit }, usershoppingdata) {
    axios.post('add-to-card/add', {usershoppingdata})
    .then(response => {
      commit('ADD_TOCART', response.data);
    })
  },
  getPhotographerswebcat ({ commit }) {
    axios.post('web_cat_name/'+this.state.photographeridclicked)
    .then(response => {
      commit ('SET_PHOTOGRAPHERSWEBCAT', response.data);
    })
    // console.log(this.state.photographeridclicked);
  },
  getPhotographerssubcat ({ commit }) {
    axios.post('web_sub_cat_name/'+this.state.catidclicked)
    .then(response => {
      commit ('SET_PHOTOGRAPHERSSUBCAT', response.data);
      // console.log(this.state.catidclicked);
    })
  },
  getPhotographerimages ({ commit }) {
    axios.post('img_data/'+this.state.photographeridclicked+'/'+
    this.state.catidclicked+'/'+this.state.subcatclicked)
    .then(response => {
      commit ('SET_PHOTOGRAPHERIMAGES', response.data);
      // console.log(this.state.catidclicked);
    })
  },
  getProfileImage ({ commit }) {
    if(localStorage.getItem('user-info')) {
      this.userData = JSON.parse(localStorage.getItem("user-info"));
      this.userinfoId = this.userData.data.id;
      // console.log("action "+ this.userData.data.image);
      commit('SET_PROFILEIMAGE', this.userData.data.image);
    }
  },
  getCategoryOne ({ commit }) {
    const urlParams = new URLSearchParams(window.location.search);
    this.imageId = urlParams.get("imageid");
    if(localStorage.getItem('imageId')) {
      // this.imageId = JSON.parse(localStorage.getItem('imageId'));
      //console.log(this.imageId)
      axios.post('img_dynamic/cat_one/'+this.imageId).
      then(response => {
        commit ('SET_GETCATEGORYONE', response.data);
        //console.log(response.data)
      })
    }
  },
  getCategoryTwo ({ commit }) {
    const urlParams = new URLSearchParams(window.location.search);
    this.imageId = urlParams.get("imageid");
    if(localStorage.getItem('imageId')) {
      // this.imageId = JSON.parse(localStorage.getItem('imageId'));
      axios.post('img_dynamic/cat_two/'+this.imageId).
      then(response => {
        commit ('SET_GETCATEGORYTWO', response.data);
        //console.log(response.data)
      })
    }
  },
  getCategoryThree ({ commit }) {
    if(localStorage.getItem('imageId')) {
      this.imageId = JSON.parse(localStorage.getItem('imageId'));
      axios.post('img_dynamic/cat_three/'+this.imageId).
      then(response => {
        commit ('SET_GETCATEGORYTHREE', response.data);
        //console.log(response.data)
      })
    }
  },

  getUsagePeriodData({ commit }) {
    axios.get(process.env.VUE_APP_API_BASE_URL+"usage-period/index")
    .then(response => {
      commit ('SET_USAGEPERIODDATA', response.data.data);
    })
  },

  getMultipleDurationData({ commit }) {
    axios.get("duration/index/2")
    .then(response => {
      commit ('SET_MULTIPLEDURATIONDATA', response.data.data);
    })
  },

  getLocationData({ commit }) {
    axios.get(process.env.VUE_APP_API_BASE_URL+"location/index")
    .then(response => {
      commit ('SET_LOCATIONDATA', response.data.data);
    })
  },

  getImageSizesData({ commit }) {
    axios.get(process.env.VUE_APP_API_BASE_URL+"image-size/index")
    .then(response => {
      commit ('SET_IMAGESIZESDATA', response.data.data);
    })
  },

  getUsageTypeData({ commit }) {
    axios.get(process.env.VUE_APP_API_BASE_URL+"usage-type/index")
    .then(response => {
      commit ('SET_USAGETYPENDATA', response.data.data);
    })
  },

  // getSubCategoryData({ commit }) {
  //   axios.get(process.env.VUE_APP_API_BASE_URL+"sub-category/index/")
  //   .then(response => {
  //     commit ('SET_SUBCATEGORYNDATA', response.data.data);
  //   })
  // },

  getUsageTypeData({ commit }) {
    axios.get(process.env.VUE_APP_API_BASE_URL+"usage-type/index")
    .then(response => {
      console.log(response.data.data[0].name)
      commit ('SET_USAGETYPENDATA', response.data.data);
    })
  },

  // getGuestItems ({ commit }) {
  //   if(localStorage.getItem('guestcartitems')){
  //     this.guestcartitem = JSON.parse(localStorage.getItem('guestcartitems'));
  //     commit ('SET_GUESTITEMS', this.guestcartitem)
  //   }
  // }
  }
})
      